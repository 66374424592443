import React, { useMemo, useState } from "react";
import {
  Container,
  Row,
  Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import TreatmentPlansCard from '../../components/TreatmentPlansCard'; 


const TreatmentPlans = () => {
  const { t } = useTranslation();
  document.title = "Treatment Plans";

  const renderDetailPanel = (rowData) => {
    // Custom detail panel renderer
    return <div>{/* Render your detail panel content here */}</div>;
  };
  const handleRowSelection = (row) => {
    setSelectedRow(row);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <TreatmentPlansCard handleRowSelection={handleRowSelection} renderDetailPanel={renderDetailPanel} />
          </Row>
                {/* <Row>
        <Col className="text-end">
          <Button style={{backgroundColor:ColorPalette.blue}}>Add Treatment Plans</Button>
        </Col>
      </Row> */}
                   <Row>
          <div className="text-end">
            <ul className="list-inline">
              <li className="list-inline-item">
                <Button color="primary">
                  {t("Add Treatment Data")}
                </Button>
              </li>
            </ul>
          </div>
        </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TreatmentPlans;
