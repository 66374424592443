import React, { useMemo, useState } from "react";
import {
  CardTitle,
  Button,
  Container,
  Row,
  Col,
  CardHeader,
  CardBody,
  Card,
} from "reactstrap";
import { CreateTitleLabel } from "../../../components/TextInputTemplates";
import { useTranslation } from "react-i18next";
import { ColorPalette } from "../../../components/ColorPalette";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import PublicIcon from "@mui/icons-material/Public";
import getDeviceValue from "../getDeviceValue";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AddchartIcon from "@mui/icons-material/Addchart";
import TuneIcon from "@mui/icons-material/Tune";
import LineChart from "../../../components/Charts/LineChart";
import dateTimeUtils from "../../../components/Charts/DateTimeUtils";

const Tab6 = () => {
  const { t } = useTranslation();
  const patient = JSON.parse(localStorage.getItem("patient"));
  const [expandedRows, setExpandedRows] = useState([]); // State to track expanded rows

  const series = [
    {
      name: "Episodes",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2017 GMT").getTime(),
        100,
        {
          min: 0,
          max: 20,
        }
      ),
    },
    {
      name: "AT/AF Burden/day",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2017 GMT").getTime(),
        100,
        {
          min: 0,
          max: 10,
        }
      ),
    },
    {
      name: "Number of ATP",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2017 GMT").getTime(),
        100,
        {
          min: 0,
          max: 11,
        }
      ),
    },
  ];

  const columns = useMemo(
    () => [
      { accessorKey: "ID", header: "ID", size: "50" }, //MDC_IDC_EPISODE_ID
      { accessorKey: "egm", header: "EGM", size: "50" },
      { accessorKey: "type", header: "Type", size: "50" }, //MDC_IDC_EPISODE_TYPE
      { accessorKey: "reviewer", header: "Reviewer", size: "50" },
      {
        accessorKey: "devicetype",
        header: "Device Type",
        size: "50", //MDC_IDC_EPISODE_VENDOR_TYPE
      },
      { accessorKey: "date", header: "Date", size: "50" }, //MDC_IDC_EPISODE_DTM
      { accessorKey: "duration", header: "Duration", size: "50" }, //MDC_IDC_EPISODE_DURATION
      { accessorKey: "Therapie", header: "Therapie", size: "100" }, //MDC_IDC_EPISODE_DETECTION_THERAPY_DETAILS
      { accessorKey: "result", header: "Result", size: "50" }, //MDC_IDC_EPISODE_THERAPY_RESULT
    ],
    []
  );

  const [data, setData] = useState([
    {
      egm: "EGM 1",
      ID: 1,
      type: "Epis-VT",
      reviewer: "Dr. A. Nowik",
      devicetype: "VT1",
      date: "2023-05-13",
      duration: "2 hours",
      Therapie: "2 ATPs applied, 5 x 30J",
      result: "Successful",
    },
    {
      egm: "EGM 2",
      ID: 2,
      type: "Epis-VT",
      reviewer: "Dr. E. Siutsi",
      devicetype: "VT2",
      date: "2023-05-28",
      duration: "1.5 hours",
      Therapie: "3 x 30J",
      result: "Successful",
    },
    {
      egm: "EGM 3",
      ID: 4,
      type: "Epis-VT",
      reviewer: "Dr. F. Scheubel",
      devicetype: "VT1",
      date: "2023-06-02",
      duration: "1.5 hours",
      Therapie: "3 x 30J",
      result: "Successful",
    },
    {
      egm: "EGM 4",
      ID: 3,
      type: "Epis-VT",
      reviewer: "Dr. E. Ricardo",
      devicetype: "VT1",
      date: "2023-06-18",
      duration: "1.5 hours",
      Therapie: "3 x 30J",
      result: "Successful",
    },
    // Add more objects as needed
  ]);
  const renderDetailPanel = (rowData) => {
    // Custom detail panel renderer
    return <div>{/* Render your detail panel content here */}</div>;
  };
  const handleRowSelection = (row) => {
    setSelectedRow(row);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card style={{ marginTop: "-80px" }}>
                <CardHeader>
                  <CardTitle>
                    <Button style={{ backgroundColor: ColorPalette.blue }}>
                      <TuneIcon sx={{ fontSize: 20, color: "white" }} />
                    </Button>
                    <b className="px-2">
                      {t("Tachyarrhythmia Zone Settings:")}
                    </b>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                      <CreateTitleLabel
                        name={t("Mode Switch Mode:")} //MDC_IDC_SET_BRADY_AT_MODE_SWITCH_MODE
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_BRADY_AT_MODE_SWITCH_MODE")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Ventricular Therapy:")} //MDC_IDC_SET_TACHYTHERAPY_VSTAT
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_TACHYTHERAPY_VSTAT")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Zone (Type):")} //MDC_IDC_SET_ZONE_TYPE
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_ZONE_TYPE")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Zone Statu s:")} //MDC_IDC_SET_ZONE_STATUS
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_ZONE_STATUS")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Max ModeSw-Epis Dur.:")} //MDC_IDC_STAT_AT_MODE_SW_MAX_DURATION
                        width="180px"
                        value={getDeviceValue(
                          "MDC_IDC_STAT_AT_MODE_SW_MAX_DURATION"
                        )}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Number of ModeSw:")} //MDC_IDC_STAT_AT_MODE_SW_COUNT
                        width="180px"
                        value={getDeviceValue("MDC_IDC_STAT_AT_MODE_SW_COUNT")}
                      ></CreateTitleLabel>
                    </Col>
                    <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                      {" "}
                      <CreateTitleLabel
                        name={t("Mode Switch Rate:")} //MDC_IDC_SET_BRADY_AT_MODE_SWITCH_RATE
                        width="180px"
                        value={getDeviceValue(
                          "MDC_IDC_SET_BRADY_AT_MODE_SWITCH_RATE"
                        )}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Atrial Therapy:")} //MDC_IDC_SET_TACHYTHERAPY_ASTAT
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_TACHYTHERAPY_ASTAT")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Zone (Vendor):")} //MDC_IDC_SET_ZONE_VENDOR_TYPE
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_ZONE_VENDOR_TYPE")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Upper Limit BPM:")} //MDC_IDC_SET_ZONE_DETECTION_INTERVAL
                        width="180px"
                        value={getDeviceValue(
                          "MDC_IDC_SET_ZONE_DETECTION_INTERVAL"
                        )}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("AT/AF Burden/day:")} //MDC_IDC_STAT_AT_BURDEN_PERCENT
                        width="180px"
                        value={getDeviceValue("MDC_IDC_STAT_AT_BURDEN_PERCENT")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Nr. of ModeSw/day:")} //MDC_IDC_STAT_AT_MODE_SW_COUNT_PER_DAY
                        width="180px"
                        value={getDeviceValue(
                          "MDC_IDC_STAT_AT_MODE_SW_COUNT_PER_DAY"
                        )}
                      ></CreateTitleLabel>
                    </Col>
                    <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                      {" "}
                      <CreateTitleLabel
                        name={t("Detection X of Y:")} //MDC_IDC_SET_ZONE_DETECTION_BEATS_NUMERATOR
                        width="180px"
                        value={getDeviceValue(
                          "MDC_IDC_SET_ZONE_DETECTION_BEATS_NUMERATOR"
                        )}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("ARR Det. Details:")} //MDC_IDC_SET_ZONE_DETECTION_DETAILS
                        width="180px"
                        value={getDeviceValue(
                          "MDC_IDC_SET_ZONE_DETECTION_DETAILS"
                        )}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Type of ATP:")} //MDC_IDC_SET_ZONE_TYPE_ATP_[1..10]
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_ZONE_TYPE_ATP_[1..10]"
                        )}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Energy of Shocks:")} //MDC_IDC_SET_ZONE_SHOCK_ENERGY_[1..10]
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_ZONE_SHOCK_ENERGY_[1..10]")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Time in ModeSw:")} //MDC_IDC_STAT_AT_MODE_SW_PERCENT_TIME
                        width="180px"
                        value={getDeviceValue(
                          "MDC_IDC_STAT_AT_MODE_SW_PERCENT_TIME"
                        )}
                      ></CreateTitleLabel>
                    </Col>
                    <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                      <CreateTitleLabel
                        name={t("Detection X of Y:")} //MDC_IDC_SET_ZONE_DETECTION_BEATS_DENOMINATOR
                        width="180px"
                        value={getDeviceValue(
                          "MDC_IDC_SET_ZONE_DETECTION_BEATS_DENOMINATOR"
                        )}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("AtrialTachy Date:")} //MDC_IDC_STAT_AT_DTM_[STRTEND]
                        width="180px"
                        value={getDeviceValue("MDC_IDC_STAT_AT_DTM_[STRTEND]")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Number of ATP:")} //MDC_IDC_SET_ZONE_NUM_ATP_SEQS_[1..10]
                        width="180px"
                        value={getDeviceValue(
                          "MDC_IDC_SET_ZONE_NUM_ATP_SEQS_[1..10]"
                        )}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Number of Shocks:")} //MDC_IDC_SET_ZONE_NUM_SHOCKS_[1..10]
                        width="180px"
                        value={getDeviceValue(
                          "MDC_IDC_SET_ZONE_NUM_SHOCKS_[1..10]"
                        )}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Time in ModeSw/day:")} //MDC_IDC_STAT_AT_MODE_SW_PERCENT_TIME_PER_DAY
                        width="180px"
                        value={getDeviceValue("MDC_IDC_STAT_AT_MODE_SW_PERCENT_TIME_PER_DAY")}
                      ></CreateTitleLabel>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Card>
              <CardHeader>
                <CardTitle>
                  <Button style={{ backgroundColor: ColorPalette.blue }}>
                    <AddchartIcon sx={{ fontSize: 20, color: "white" }} />
                  </Button>
                  <b className="px-2">{t("Statistics")}</b>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <LineChart
                    inputData={series}
                    colors={["#000033", "#0000FF", "#b2b2FF"]}
                    yAxisMax={50}
                    yLabel="Number"
                  />
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Row>
            <Card>
              <CardHeader>
                <CardTitle>
                  <Button style={{ backgroundColor: ColorPalette.blue }}>
                    <EventNoteIcon sx={{ fontSize: 20, color: "white" }} />
                  </Button>
                  <b className="px-2">{t("Episode List")}</b>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <MaterialReactTable
                    columns={columns}
                    data={data}
                    onRowSelection={handleRowSelection}
                    renderDetailPanel={renderDetailPanel}
                    isRowSelectable={() => true} // Enable row selection for all rows
                    enableColumnDragging={false}
                    isRowSelected={(rowData) => rowData === selectedRow} // Check if a row is selected
                    enableColumnVirtualization
                    enableRowActions
                    renderRowActions={({ row, table }) => (
                      <Box
                        sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}
                      >
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            table.setEditingRow(row);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => {
                            data.splice(row.index, 1); //assuming simple data table
                            setData([...data]);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    )}
                  />
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Tab6;
