import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardHeader,
} from "reactstrap";

import PatientInfoCard from "./PatientInfoCard";
import RadialChart from "../../components/Charts/RadialChart";
import ChartDataChecker from "../../components/Charts/ChartDataChecker";

import img from "../../assets/images/ECG.png";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "react-modal-video/scss/modal-video.scss";
import { useTranslation } from "react-i18next";
import { ColorPalette } from "../../components/ColorPalette";
import { useQuery, useQueryClient } from "react-query";
import patientService from "../../services/patients";

const getCounts = (patient, isToggled) => {
  const currentDate = new Date();

  const highWarningCount = patient.warnings?.filter(
    (warning) =>
      warning.level === "high" &&
      (isToggled
        ? new Date(warning.date).toDateString() === currentDate.toDateString()
        : true)
  ).length || 0;

  const intermediateWarningCount = patient.warnings?.filter(
    (warning) =>
      warning.level === "intermediate" &&
      (isToggled
        ? new Date(warning.date).toDateString() === currentDate.toDateString()
        : true)
  ).length || 0;

  const controlWarningCount = patient.warnings?.filter(
    (warning) =>
      warning.level === "control" &&
      (isToggled
        ? new Date(warning.date).toDateString() === currentDate.toDateString()
        : true)
  ).length || 0;

  const resolvedWarningCount = patient.warnings?.filter(
    (warning) =>
      warning.level === "resolved" &&
      (isToggled
        ? new Date(warning.date).toDateString() === currentDate.toDateString()
        : true)
  ).length || 0;

  const warningCounts = [
    resolvedWarningCount,
    controlWarningCount,
    intermediateWarningCount,
    highWarningCount,
  ];

  const highDeviceCount = patient.device_alerts?.filter(
    (alert) =>
      alert.level === "high" &&
      (isToggled
        ? new Date(alert.date).toDateString() === currentDate.toDateString()
        : true)
  ).length || 0;

  const intermediateDeviceCount = patient.device_alerts?.filter(
    (alert) =>
      alert.level === "intermediate" &&
      (isToggled
        ? new Date(alert.date).toDateString() === currentDate.toDateString()
        : true)
  ).length || 0;

  const controlDeviceCount = patient.device_alerts?.filter(
    (alert) =>
      alert.level === "control" &&
      (isToggled
        ? new Date(alert.date).toDateString() === currentDate.toDateString()
        : true)
  ).length || 0;

  const resolvedDeviceCount = patient.device_alerts?.filter(
    (alert) =>
      alert.level === "resolved" &&
      (isToggled
        ? new Date(alert.date).toDateString() === currentDate.toDateString()
        : true)
  ).length || 0;

  const deviceAlertsCounts = [
    resolvedDeviceCount,
    controlDeviceCount,
    intermediateDeviceCount,
    highDeviceCount,
  ];

  return { warningCounts, deviceAlertsCounts };
};


const PatientDashboard = () => {
  const { t } = useTranslation();
  document.title = t("PatientDashboard");
  const [isGallery, setisGallery] = useState(false);
  const [isGalleryZoom, setisGalleryZoom] = useState(false);
  const [isToggled, setToggled] = useState(false);

  const queryClient = useQueryClient()
  const query = useQuery(["patients"], () => patientService.getAll(() => queryClient.invalidateQueries(["patients"])))

  if (query.isLoading) return

  const patient = query.data?.find(p => p.uuid == JSON.parse(localStorage.getItem("patient")).uuid)

  const showEcg = patient?.id !== "98247";

  const handleToggle = () => {
    setToggled(!isToggled);
  };

  if (
    patient.mean_pressure != null &&
    patient.pulmonary_arterial_pressure != null
  ) {
    const measurements1 = patient.pulmonary_arterial_pressure.measurements;
    const measurements2 = patient.mean_pressure.measurements;

    const mergedMeasurements = measurements1.map((measurement1) => {
      const matchingMeasurement = measurements2.find(
        (measurement2) => measurement2.date === measurement1.date
      );
      return {
        date: measurement1.date,
        systolic: measurement1.systolic,
        diastolic: measurement1.diastolic,
        value: matchingMeasurement ? matchingMeasurement.value : null,
      };
    });
    patient.pulmonary_arterial_pressure.measurements = mergedMeasurements;
  }

  const { warningCounts, deviceAlertsCounts } = getCounts(patient, isToggled);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xxl={4} xl={6} lg={12} md={12} sm={12}>
              <div
                className="d-flex mx-auto align-items-center"
                style={{ width: "100%", height: "100%" }}
              >
                <PatientInfoCard
                  patient={patient}
                  warnings={
                    (warningCounts[1] + warningCounts[2] + warningCounts[3]) || 0
                  }
                  deviceAlerts={
                    (deviceAlertsCounts[1] + deviceAlertsCounts[2] + deviceAlertsCounts[3]) || 0
                  }
                  resolved={warningCounts[0] + deviceAlertsCounts[0] || 0}
                />
              </div>
            </Col>
            <Col xxl={4} xl={6} lg={12} md={12} sm={12}>
              <div className="d-flex justify-content-center">
                <Card style={{ width: "100%" }}>
                  <CardHeader>
                    <Row>
                      <Col xl={6}>
                        <h4
                          className="card-title mb-0"
                          style={{ color: "black" }}
                        >
                          Device Alerts
                        </h4>
                      </Col>

                      <Col
                        xl={6}
                        style={{
                          marginTop: "-5px",
                          marginBottom: "-10px",
                          display: "grid",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          className="float-right"
                          style={{
                            fontSize: "12px",
                            marginLeft: "auto",
                            backgroundColor: isToggled
                              ? ColorPalette.red
                              : ColorPalette.blue,
                            outline: "none",
                            border: "none",
                            width: "110px",
                          }}
                          onClick={handleToggle}
                        >
                          {isToggled ? "Today" : "All"}
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <RadialChart
                      id="multiple_radialbar"
                      dir="ltr"
                      series={deviceAlertsCounts}
                    />
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col xxl={4} xl={6} lg={12} md={12} sm={12}>
              <div className="d-flex justify-content-center">
                <Card style={{ width: "100%" }}>
                  <CardHeader>
                    <Row>
                      <Col xl={6}>
                        <h4
                          className="card-title mb-0"
                          style={{ color: "black" }}
                        >
                          General Alerts
                        </h4>
                      </Col>
                      <Col
                        xl={6}
                        style={{
                          marginTop: "-5px",
                          marginBottom: "-10px",
                          display: "grid",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          className="float-right"
                          style={{
                            fontSize: "12px",
                            marginLeft: "auto",
                            backgroundColor: isToggled
                              ? ColorPalette.red
                              : ColorPalette.blue,
                            outline: "none",
                            border: "none",
                            width: "110px",
                          }}
                          onClick={handleToggle}
                        >
                          {isToggled ? "Today" : "All"}
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <RadialChart
                      id="multiple_radialbar"
                      dir="ltr"
                      series={warningCounts}
                    />
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xxl={4} xl={6} lg={12} md={12} sm={12}>
              <ChartDataChecker
                patientData={patient.blood_pressure ?? null}
                name={t("ArterialBloodPressure")}
              />
            </Col>
            <Col xxl={4} xl={6} lg={12} md={12} sm={12}>
              <ChartDataChecker
                patientData={patient.pulmonary_arterial_pressure ?? null}
                name={t("pulmonary_arterial_pressure")}
                apex3={true}
              />
            </Col>
            <Col xxl={4} xl={6} lg={12} md={12} sm={12}>
              <ChartDataChecker
                patientData={patient.weight ?? null}
                name={t("weight")}
                apex1={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xxl={4} xl={6} lg={12} md={12} sm={12}>
              <ChartDataChecker
                patientData={patient.body_fat ?? null}
                name={t("bodyFat")}
                apex1={true}
              />
            </Col>
            <Col xxl={4} xl={6} lg={12} md={12} sm={12}>
              <ChartDataChecker
                // patientData={patient.physical_activity ?? null}
                patientData={patient.step_count ?? null}
                name={t("PhysicalActivity")}
                apex1={true}
              />
            </Col>
            <Col xxl={4} xl={6} lg={12} md={12} sm={12}>
              <ChartDataChecker
                // patientData={patient.resting_pulse ?? null}
                patientData={patient.heart_rate ?? null}
                name={t("resting_pulse")}
                apex1={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xxl={4} xl={6} lg={12} md={12} sm={12}>
              <ChartDataChecker
                patientData={patient.temperature ?? null}
                name={t("Temperature")}
                apex1={true}
              />
            </Col>
            <Col xxl={4} xl={6} lg={12} md={12} sm={12}>
              <ChartDataChecker
                patientData={patient.blood_oxygen_saturation ?? null}
                name={t("Oxygenation")}
                apex1={true}
              />
            </Col>
            <Col xxl={4} xl={6} lg={12} md={12} sm={12}>
              <ChartDataChecker
                patientData={patient.blood_glucose ?? null}
                name={t("bloodGlucose")}
                apex1={true}
              />
            </Col>
          </Row>
          {isGallery ? (
            <Lightbox
              mainSrc={img}
              enableZoom={true}
              onCloseRequest={() => {
                setisGallery(false);
              }}
            />
          ) : null}

          {isGalleryZoom ? (
            <Lightbox
              mainSrc={img}
              onCloseRequest={() => {
                setisGalleryZoom(false);
              }}
            />
          ) : null}
          {showEcg && (
            <Row>
              <Col
                xxl={6}
                xl={6}
                lg={12}
                md={12}
                sm={12}
                className="d-flex mx-auto align-items-center"
              >
                <Card>
                  <CardHeader>
                    <h6>ECG</h6>
                  </CardHeader>
                  <CardBody>
                    <div className="popup-gallery img-fluid">
                      <img
                        src={img}
                        alt=""
                        className="img-fluid d-block"
                        onClick={() => {
                          setisGallery(true);
                        }}
                      />
                    </div>
                    <h6>
                      This is the last ECG of the patient, taken at 11.5.2023
                    </h6>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
export default PatientDashboard;
