import React, { useEffect, useState } from "react";
import { ColorPalette } from "../../components/ColorPalette";

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardHeader,
  Button,
} from "reactstrap";

import DoctorInfoCard from "./DoctorInfoCard";
import RadialChart from "../../components/Charts/RadialChart";
import "react-image-lightbox/style.css";
import "react-modal-video/scss/modal-video.scss";
import { useTranslation } from "react-i18next";
import patientService from "../../services/patients";
import FollowUpTable from "../../components/FollowUpModal/FollowUpTable";
import { useQuery, useQueryClient } from "react-query";


const normalizeNumber=(value, min, max)=> {
  // Ensure the value is within the original range
  const clampedValue = Math.max(Math.min(value, max), min);

  // Calculate the normalized value in the 0 to 10 range
  const normalizedValue = ((clampedValue - min) / (max - min)) * 10;

  return normalizedValue;
}

const calculateTotalCounts = (patients, isToday) => {
  if (!patients || !Array.isArray(patients)) {
    return {
      DeviceHighCount: 0,
      DeviceIntermediateCount: 0,
      DeviceControlCount: 0,
      DeviceResolvedCount: 0,
      WarningHighCount: 0,
      WarningIntermediateCount: 0,
      WarningControlCount: 0,
      WarningResolvedCount: 0,
    };
  }

  let DeviceHighCount = 0;
  let DeviceIntermediateCount = 0;
  let DeviceControlCount = 0;
  let DeviceResolvedCount = 0;
  let WarningHighCount = 0;
  let WarningIntermediateCount = 0;
  let WarningControlCount = 0;
  let WarningResolvedCount = 0;

  patients.forEach((patient) => {
    if (patient.device_alerts && Array.isArray(patient.device_alerts)) {
      patient.device_alerts.forEach((alert) => {
        if (isToday && isTodayWarning(alert.date)) {
          switch (alert.level) {
            case "high":
              DeviceHighCount++;
              break;
            case "intermediate":
              DeviceIntermediateCount++;
              break;
            case "control":
              DeviceControlCount++;
              break;
            case "resolved":
              DeviceResolvedCount++;
              break;
          }
        } else if (!isToday) {
          switch (alert.level) {
            case "high":
              DeviceHighCount++;
              break;
            case "intermediate":
              DeviceIntermediateCount++;
              break;
            case "control":
              DeviceControlCount++;
              break;
            case "resolved":
              DeviceResolvedCount++;
              break;
          }
        }
      });
    }
    if (patient.warnings && Array.isArray(patient.warnings)) {
      patient.warnings.forEach((warning) => {
        if (isToday && isTodayWarning(warning.date)) {
          switch (warning.level) {
            case "high":
              WarningHighCount++;
              break;
            case "intermediate":
              WarningIntermediateCount++;
              break;
            case "control":
              WarningControlCount++;
              break;
            case "resolved":
              WarningResolvedCount++;
              break;
          }
        } else if (!isToday) {
          switch (warning.level) {
            case "high":
              WarningHighCount++;
              break;
            case "intermediate":
              WarningIntermediateCount++;
              break;
            case "control":
              WarningControlCount++;
              break;
            case "resolved":
              WarningResolvedCount++;
              break;
          }
        }
      });
    }
  });

  return {
    DeviceHighCount,
    DeviceIntermediateCount,
    DeviceControlCount,
    DeviceResolvedCount,
    WarningHighCount,
    WarningIntermediateCount,
    WarningControlCount,
    WarningResolvedCount,
  };
};

const isTodayWarning = (date) => {
  const today = new Date().toDateString();
  const warningDate = new Date(date).toDateString();
  return today === warningDate;
};

const TeamDashboard = () => {
  const { t } = useTranslation();
  document.title = t("DoctorDashboard");
  const [isToggled, setToggled] = useState(false);
  const[currentWidth, setWidth] = useState(window.innerWidth)

  const handleToggle = () => {
    setToggled(!isToggled);
  };

  const handleResize = () => {
    const displayWidth = window.innerWidth;
    setWidth(displayWidth);
  };
  useEffect(() => {
    // Initial log
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    console.log(normalizeNumber(currentWidth, 0, 10))
  }, [currentWidth])





  const queryClient = useQueryClient()
  const query = useQuery(["patients"], () => patientService.getAll(() => queryClient.invalidateQueries(["patients"])))
  if (query.isLoading) return

  let totalCounts = calculateTotalCounts(query.data, isToggled);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true} >
          <Row>
            <Col xxl={4} xl={6} lg={12} md={12} sm={12}>
              <div
                className="d-flex mx-auto align-items-center"
                style={{ width: "100%", height: "100%" }}
              >
                <DoctorInfoCard
                  warningsCount={
                    totalCounts.WarningHighCount +
                    totalCounts.WarningIntermediateCount +
                    totalCounts.WarningControlCount
                  }
                  deviceAlertsCount={
                    totalCounts.DeviceHighCount +
                    totalCounts.DeviceIntermediateCount +
                    totalCounts.DeviceControlCount
                  }
                  resolvedCount={
                    totalCounts.DeviceResolvedCount +
                    totalCounts.WarningResolvedCount
                  }
                />
              </div>
            </Col>
            <Col xxl={4} xl={6} lg={12} md={12} sm={12}>
              <div className="d-flex justify-content-center">
                <Card style={{ width: "100%" }}>
                  <CardHeader>
                    <Row>
                      <Col xl={6}>
                        <h4
                          className="card-title mb-0"
                          style={{ color: "black" }}
                        >
                          Device Alerts
                        </h4>
                      </Col>

                      <Col
                        xl={6}
                        style={{
                          marginTop: "-5px",
                          marginBottom: "-10px",
                          display: "grid",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          className="float-right"
                          style={{
                            fontSize: "12px",
                            marginLeft: "auto",
                            backgroundColor: isToggled
                              ? ColorPalette.red
                              : ColorPalette.blue,
                            outline: "none",
                            border: "none",
                            width: "110px",
                          }}
                          onClick={handleToggle}
                        >
                          {isToggled ? "Today" : "All"}
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {query.data && (
                      <RadialChart
                        id="multiple_radialbar"
                        dir="ltr"
                        series={[
                          totalCounts.DeviceResolvedCount,
                          totalCounts.DeviceControlCount,
                          totalCounts.DeviceIntermediateCount,
                          totalCounts.DeviceHighCount,
                        ]}
                        // xOffset={normalizeNumber(currentWidth, 0, 10)}
                        xOffset={currentWidth>1000? 10 :5}
                      />
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col xxl={4} xl={6} lg={12} md={12} sm={12}>
              <div className="d-flex justify-content-center">
                <Card style={{ width: "100%" }}>
                  <CardHeader>
                    <Row>
                      <Col xl={6}>
                        <h4
                          className="card-title mb-0"
                          style={{ color: "black" }}
                        >
                          General Alerts
                        </h4>
                      </Col>
                      <Col
                        xl={6}
                        style={{
                          marginTop: "-5px",
                          marginBottom: "-10px",
                          display: "grid",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          className="float-right"
                          style={{
                            fontSize: "12px",
                            marginLeft: "auto",
                            backgroundColor: isToggled
                              ? ColorPalette.red
                              : ColorPalette.blue,
                            outline: "none",
                            border: "none",
                            width: "110px",
                          }}
                          onClick={handleToggle}
                        >
                          {isToggled ? "Today" : "All"}
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {query.data && (
                      <RadialChart
                        id="multiple_radialbar"
                        dir="ltr"
                        series={[
                          totalCounts.WarningResolvedCount,
                          totalCounts.WarningControlCount,
                          totalCounts.WarningIntermediateCount,
                          totalCounts.WarningHighCount,
                        ]}
                        xOffset={currentWidth>1000? 10 :5}
                      />
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
          <Row style={{ display: "flex" }}>
            <Col xl={6} lg={12}>
              <Card style={{ display: "flex", height: "98%" }}>
                <CardHeader>
                  <h4 className="card-title mb-0" style={{ color: "black" }}>
                    Device Alerts
                  </h4>
                </CardHeader>
                <CardBody>
                  <FollowUpTable nav={false} patients={query.data} tab={"1"} />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6} lg={12}>
              <Card style={{ display: "flex", height: "98%" }}>
                <CardHeader>
                  <h4 className="card-title mb-0" style={{ color: "black" }}>
                    General Warnings
                  </h4>
                </CardHeader>
                <CardBody>
                  <FollowUpTable nav={false} patients={query.data} tab={"2"} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container >
      </div >
    </React.Fragment >
  );
};
export default TeamDashboard;
