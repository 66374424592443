import React from "react";
import ReactApexChart from "react-apexcharts";

const ChartApex = ({ inputData, height, colors,yLabel }) => {
  const series = inputData;
  const options = {
    chart: {
      type: "area",
      height: 350,
      stacked: true,
      zoom: { enabled: true },
      toolbar: { show: true, floating: true, offsetY: -20, offsetX: -10 },
      events: {
        selection: function (chart, e) {
          console.log(new Date(e.xaxis.min));
        },
      },
    },
    colors: colors ?? ["#008FFB", "#00E396", "#CED4DC"],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      show: true,
      title: {text: yLabel ?? "Y Axis"},
      min: 0,
      max: 100,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "opacity",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      offsetY: 0,
      offsetX: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: { toolbar: { show: false } },
          legend: { show: false },
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={height ? height : 300}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default ChartApex;
