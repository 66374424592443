import React, { useState, useEffect } from "react";
import { Container, Row, Col, Label, Button } from "reactstrap";
import PatientInfoCard from "./PatientInfoCard";
import ChartTabs from "./ChartTabs";
import { useTranslation } from "react-i18next";
import { ColorPalette } from "../../components/ColorPalette";
import ActionModal from "./ActionModal";
import { useQuery, useMutation } from "react-query";
import {
  getDeviceObservations,
  getDevicePdf,
  getDataByEnding,
  getDevicePdfUrls,
} from "../../services/devices";
import patientService from "../../services/patients";
import ModalTemplate from "./ModalTemplate";

const convertBinaryToPDF = (binaryData) => {
  const pdfBinary = window.atob(binaryData);
  const byteArray = new Uint8Array(pdfBinary.length);

  for (let i = 0; i < pdfBinary.length; i++) {
    byteArray[i] = pdfBinary.charCodeAt(i);
  }

  const blob = new Blob([byteArray], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);

  return <embed src={url} type="application/pdf" width="100%" height="600px" />;
};

const DeviceDashboard = () => {
  const { t } = useTranslation();
  document.title = "Device Dashboard ";
  const patient = JSON.parse(localStorage.getItem("patient"));
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [data, setData] = useState([]);

  const user = authUser.title + " " + authUser.username;

  // const query = useQuery(["devices", patient], () =>
  //   getDeviceObservations(patient.device)
  // );

  const deviceQuery = useQuery(["devices", patient], () =>
    getDeviceObservations(patient.device)
  );
  // console.log("DEVICE", deviceQuery.data)

  const pdfUrlsQuery = useQuery(["pdfUrls", patient], () =>
    getDevicePdfUrls(patient.device)
  );
  // console.log("PDF urls", pdfUrlsQuery.data);

  const pdfQuery = useQuery(
    ["pdf", pdfUrlsQuery.data],
    () => getDevicePdf(pdfUrlsQuery.data.at(-1)),
    { enabled: pdfUrlsQuery.data?.at(-1) !== undefined }
  );

  // console.log("PDF", pdfQuery.data)

  const fetchPdfMutation = useMutation((device) =>
    getDataByEnding(patient.device)
  );

  const toggleTempModal = () => {
    setModalOpen(!modalOpen);
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const saveModal = (note, category, status) => {
    const newData = [...data];
    newData.push({
      description: note,
      category: category,
      registered: user,
      status: status,
      initiated_date: new Date().toLocaleString(),
      last_update: new Date().toLocaleString(),
    });
    setPatient(newData);
    setIsOpen(false);
  };

  const setPatient = (notes) => {
    const updatedPatient = { ...patient };
    updatedPatient.actions = notes;
    patientService
      .update(updatedPatient.uuid, updatedPatient)
      .then((returnedPatient) => {
        localStorage.setItem("patient", JSON.stringify(updatedPatient));
        console.log("Patient updated:", returnedPatient);
        setData(notes);
      })
      .catch((error) => {
        console.log("Updating patient failed:", error);
      });
  };

  const handleSaveForm = ({ inputValue, selectedCategory, selectedStatus }) => {
    saveModal(inputValue, selectedCategory, selectedStatus);
  };

  useEffect(() => {
    if (data !== patient.actions) {
      setData(patient.actions || []);
    }
  }, []);

  const handleFtechPdf = () => {
    toggleTempModal();
  };
  const handleAction = () => {
    console.log("handleAction");
    toggleModal();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row style={{ marginBottom: "-95px", marginTop: "-20px" }}>
            <Col>
            <Label
                className="rounded"
                style={{ margin: "10px", backgroundColor: ColorPalette.blue }}
              >
                <b
                  style={{ fontSize: "20px", padding: "10px", color: "white" }}
                >
                  Herzzentrum Würzburg.  Deutsches Zentrum für Herzinsuffizienz (DZHI)
                  {/* Sana Kliniken AG */}
                </b>
              </Label>
              {/* <Label
                className="rounded"
                style={{
                  margin: "10px",
                  backgroundColor: "DarkGray",
                  whiteSpace: "nowrap",
                }}
              >
                <b
                  style={{
                    fontSize: "20px",
                    padding: "10px",
                    color: ColorPalette.red,
                  }}
                >
                  Herzzentrum Dresden
                </b>
              </Label> */}
            </Col>
          </Row>
          <PatientInfoCard
            patient={patient}
            handleFtechPdf={handleFtechPdf}
            handleAction={handleAction}
          />
          <ChartTabs style={{ marginTop: "-20px" }} />
          <ModalTemplate
            isOpen={modalOpen}
            onClose={toggleTempModal}
            toggleModal={toggleTempModal} //{() => fetchPdfMutation.isLoading(false)}
            title="Fetching PDF"
            body="Please wait while we are fetching the PDF"
          >
            {pdfQuery.isLoading ? (
              <p>Loading...</p>
            ) : pdfQuery.error ? (
              <p>Error: {pdfQuery.error.message}</p>
            ) : pdfQuery.data ? (
              <div>
                <div>{convertBinaryToPDF(pdfQuery.data.data)}</div>
              </div>
            ) : null}
          </ModalTemplate>
          <ActionModal
            isOpen={isOpen}
            toggleModal={toggleModal}
            saveModal={handleSaveForm}
            onCloseModal={() => isOpen(false)}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DeviceDashboard;
