import React from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../../components/Common/TopbarDropdown/VerticalNotificationDropdown";

//redux
import { useDispatch } from "react-redux";
import { changeTheme, changeSidebarType } from "../../store/actions";
import { themeModeTypes, leftSidebarTypes } from "../../constants/layout";

// import Img
// import logo from "../../assets/images/logo-sm.svg";
import logo from "../../assets/images/D4DLogo.png";
import ProfileMenu from "../../components/Common/TopbarDropdown/ProfileMenu";
import ToggleThemeButton from "../../components/Common/ToggleThemeButton";
import Clock from "../../components/Clock";
import { CardTitle } from "reactstrap";

const Header = (props) => {
  const dispatch = useDispatch();
  const patient = JSON.parse(localStorage.getItem("patient"));

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("sidebar-enable");
      body.classList.toggle("vertical-collpsed");
      dispatch(changeSidebarType(leftSidebarTypes.DEFAULT));
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar" className="isvertical-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="22" />{" "}
                  <span className="logo-txt">Docs4D</span>
                </span>
              </Link>
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="22" />{" "}
                  <span className="logo-txt">Docs4D</span>
                </span>
              </Link>
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
              style={{ marginTop: "10px" }}
              onClick={() => {
                tToggle();
              }}
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
            {patient && (
              <CardTitle
                className="btn btn-sm px-2 font-size-16 header-item"
                style={{marginTop: "25px" }}
              >
                <h4>
                  {patient.first_name +
                    " " +
                    patient.last_name +
                    " | " +
                    patient.birthday}
                </h4>
              </CardTitle>
            )}
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none">
              <button
                type="button"
                className="btn header-item"
                aria-haspopup={true}
                aria-expanded={false}
              >
                <i className="icon-sm" data-feather="search"></i>
                <FeatherIcon icon="search" />
              </button>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
                <form className="p-2">
                  <div className="search-box">
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control rounded bg-light border-0"
                        placeholder="Search..."
                      />
                      <i className="mdi mdi-magnify search-icon"></i>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <LanguageDropdown />
            <NotificationDropdown />
            <ToggleThemeButton />
            <ProfileMenu />
            <Clock />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
