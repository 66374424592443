import { useEffect, useState } from "react"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"

import patientService from "../../services/patients"


const SelectPatientDropDown = ({ patient, onSelect }) => {
  const [patients, setPatients] = useState([])

  useEffect(() => {
    patientService.getAll().then((patients) => {
      setPatients(patients.filter(p => p.uuid !== patient.uuid));
    });
  }, [patient])

  return (
    <UncontrolledDropdown>
      <DropdownToggle caret color="primary">
        {patient.first_name} {patient.last_name}, {patient.birthday}
      </DropdownToggle>
      <DropdownMenu>
        {patients.map(p =>
        (<DropdownItem key={p.uuid} onClick={() => onSelect(p)}>
          {p.first_name} {p.last_name}, {p.birthday}
        </DropdownItem>)
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  )

}

export default SelectPatientDropDown