import React from 'react';

import { Card, Container, Row, Col, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
// Import Images
// import logosm from "../../assets/images/logo-sm.svg";
import logosm from "../../assets/images/D4DLogo.png";
import maintenance from "../../assets/images/maintenance.png";
import bgImage from "../../assets/images/auth-bg.jpg";
import { useTranslation } from "react-i18next";

const MaintenancePage = () => {
    document.title = "Maintenance | Sana";
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className="authentication-bg min-vh-100" style={{ background : `url(${bgImage}) bottom`}}>
                <div className="bg-overlay bg-light"></div>
                <Container>
                    <Row>
                        <Col className="col-12">
                            <div className="home-wrapper text-center">
                                <Link to="/" className="auth-logo">
                                    <span className="logo-lg">
                                        <img src={logosm} alt="" height="22" /> 
                                        <span className="logo-txt">Docs4D</span>
                                    </span>
                                </Link>
                                <h3 className="mt-3">{t("maintenanceTitle")}</h3>
                                <p>{t("maintenanceDescription")}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-5 pt-5 align-items-center">
                        <Col lg={5}>
                            <div className="mt-4">
                                <img src={maintenance} className="img-fluid" alt="" />
                            </div>
                        </Col>
                        <Col lg={6} className="ms-lg-auto">
                            <Card className="mt-4 maintenance-box">
                                <CardBody>
                                    <div className="d-flex">
                                        <h5 className="text-primary">01.</h5>
                                        <div className="flex-1 ms-3">
                                            <h5 className="font-size-16">{t("maintenanceQ1")}</h5>
                                            <p className="text-muted mb-0">{t("maintenanceA1")}</p>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                            {/* <Card className="mt-4 maintenance-box">
                                <CardBody>
                                    <div className="d-flex">
                                        <h5 className="text-primary">02.</h5>
                                        <div className="flex-1 ms-3">
                                            <h5 className="font-size-16">
                                                What is the Downtime?</h5>
                                            <p className="text-muted mb-0">Contrary to popular belief, Lorem Ipsum is not
                                                simply random text. It has roots in a piece of classical.</p>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card> */}

                            <Card className="mt-4 maintenance-box">
                                <CardBody>
                                    <div className="d-flex">
                                        <h5 className="text-primary">02.</h5>
                                        <div className="flex-1 ms-3">
                                            <h5 className="font-size-16">
                                            {t("maintenanceQ2")}</h5>
                                            <p className="text-muted mb-0">{t("maintenanceA2")} <Link
                                                    to="mailto:info@docs4d.com"
                                                    className="text-decoration-underline">info@docs4d.com</Link></p>
                                        </div>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default MaintenancePage;