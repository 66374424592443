import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";

const ActionModal = ({ isOpen, toggleModal,saveModal }) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (isOpen) {
      resetModal();
    }
  }, [isOpen]);

  const resetModal = () => {
    setInputValue("");
    setSelectedCategory("");
    setSelectedStatus("");
    setShowWarning(false);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setShowWarning(false);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setShowWarning(false);
  };
  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setShowWarning(false);
  };

  const handleSaveChanges = () => {
    if (
      inputValue.trim() === "" ||
      selectedCategory === "" ||
      selectedStatus === ""
    ) {
      setShowWarning(true);
      return;
    }
    // toggleModal();
    saveModal({inputValue, selectedCategory, selectedStatus});
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size="lg">
      <ModalHeader toggle={toggleModal}>Action</ModalHeader>
      <ModalBody>
        <Label for="categorySelect">Category</Label>
        <Input
          type="select"
          name="category"
          onChange={handleCategoryChange}
          value={selectedCategory}
          style={{ marginBottom: "10px" }}
        >
          <option value="">--Select--</option>
          <option value="Status">Message to MFA</option>
          <option value="Initiated">Message to Patient</option>
          <option value="In Progress">Message to the assigning Doctor</option>
          <option value="Complete">Plan for Intervention</option>
        </Input>
        {showWarning && selectedCategory === "" && (
          <div style={{ color: "red" }}>Please select a category.</div>
        )}
        <hr />
        <Label for="text">Please enter the action to be done bellow</Label>
        <Input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Input"
          style={{ height: "100px", marginBottom: "10px" }}
        />
        {showWarning && inputValue.trim() === "" && (
          <div style={{ color: "red" }}>
            Please enter a value for the input field.
          </div>
        )}
        <hr />
        <Label for="categorySelect">Status</Label>
        <Input
          type="select"
          name="Status"
          onChange={handleStatusChange}
          value={selectedStatus}
          style={{ marginBottom: "10px" }}
        >
          <option value="">--Select--</option>
          <option value="Initiated">Status Initiated</option>
          <option value="In Progress">In Progress</option>
          <option value="Complete">Complete</option>
        </Input>
        {showWarning && selectedStatus === "" && (
          <div style={{ color: "red" }}>Please select a category.</div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveChanges}>
          Save Changes
        </Button>
        <Button color="secondary" onClick={toggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ActionModal;
