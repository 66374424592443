import React from "react";
import ReactApexChart from "react-apexcharts";

const ChartApex = ({ inputData, height, colors,yLabel,yAxisMax }) => {
  const series = inputData;
  const options = {
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false, floating: true, offsetY: -20, offsetX: -10 }
    },
    colors: colors ?? ["#008FFB", "#00E396", "#CED4DC"],
    dataLabels: {
      enabled: false,
    },
    grid:{
      show: true,
      position: 'back'
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      show: true,
      title: {text: yLabel ?? "Y Axis"},
      min: 0,
      max: yAxisMax?? 100,
    },
    stroke: {
      width: [3, 3,3],
      curve: [
        "straight",
        "straight",
        "straight"
      ],
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      offsetY: 0,
      offsetX: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: { toolbar: { show: false } },
          legend: { show: false },
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={height ? height : 300}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default ChartApex;
