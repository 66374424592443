import React, { useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import FollowUpModal, {
  FollowUpModalState,
} from "../../components/FollowUpModal";
import { ColorPalette } from "../../components/ColorPalette";
import { useQuery, useQueryClient } from "react-query";
import patientService from "../../services/patients"

const PatientInfoCard = ({ patient, warnings, deviceAlerts, resolved }) => {
  const [followUpModal, setFollowUpModal] = useState(FollowUpModalState.Closed);

  const queryClient = useQueryClient()
  const query = useQuery(["patients"], () => patientService.getAll(() => queryClient.invalidateQueries(["patients"])))

  const patientInfo = {
    name: patient.first_name + " " + patient.last_name,
    birthday: patient.birthday,
    sex: patient.sex,
  };

  const { t } = useTranslation();
  const toggleFollowUpModal = () => {
    setFollowUpModal(FollowUpModalState.Closed);
  };

  const currentPatient = query.data?.find(p => p.uuid == JSON.parse(localStorage.getItem("patient")).uuid)

  if (query.isLoading) return

  return (
    <React.Fragment>
      <FollowUpModal
        state={followUpModal}
        toggle={toggleFollowUpModal}
        patients={[currentPatient]}
        invalidatePatients={() => queryClient.invalidateQueries(["patients"])}
      />
      <Card
        style={{
          width: "100%",
          height: "95%",
          marginTop: "20px",
          marginBottom: "40px",
        }}
      >
        <Card
          className="card border-dark  text-light text-center "
          style={{
            margin: "20px",
            backgroundColor: ColorPalette.blue,
            height: "100%",
          }}
        >
          <CardBody>
            <Row>
              <h1 className="text-white mb-1"> {patientInfo.name}</h1>
              <h6 className="text-white mb-1">
                {" "}
                {patientInfo.birthday} | {t(patientInfo.sex)}
              </h6>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <h5 className="mb-1 text-light">
                <i className="mdi mdi-alert-circle-outline me-1"></i>
                {t("CAVE")}
              </h5>
              {patient.cave && (
                <React.Fragment>
                  {patient.cave.allergies &&
                    patient.cave.allergies.length > 0 ? (
                    <p className="card-text" style={{ marginTop: "-5px" }}>
                      <strong>Allergies:</strong>{" "}
                      {patient.cave.allergies.join(", ")}
                    </p>
                  ) : (
                    <p className="card-text" style={{ marginTop: "-5px" }}>
                      <em>No allergy information available</em>
                    </p>
                  )}
                  {patient.cave.current_medications &&
                    patient.cave.current_medications.length > 0 ? (
                    <p className="card-text" style={{ marginTop: "-15px" }}>
                      <strong>Current Medications:</strong>{" "}
                      {patient.cave.current_medications.join(", ")}
                    </p>
                  ) : (
                    <p className="card-text" style={{ marginTop: "-15px" }}>
                      <em>No current medication information available</em>
                    </p>
                  )}
                  {patient.cave.medical_history &&
                    patient.cave.medical_history.length > 0 ? (
                    <p className="card-text" style={{ marginTop: "-15px" }}>
                      <strong>Medical History:</strong>{" "}
                      {patient.cave.medical_history.join(", ")}
                    </p>
                  ) : (
                    <p className="card-text" style={{ marginTop: "-15px" }}>
                      <em>No medical history information available</em>
                    </p>
                  )}
                </React.Fragment>
              )}
              {!patient.cave && (
                <React.Fragment>
                  <p className="card-text" style={{ marginTop: "30px" }}>
                    <em>No CAVE information available</em>
                  </p>

                  <br />
                </React.Fragment>
              )}
            </Row>
            <Row>
              <div style={{ marginTop: "10px" }}>
                <h5 className="mb-1 text-light">
                  <i className="mdi mdi-alert-circle-outline me-1"></i>
                  {t("Diagnosed")}
                </h5>
                <p className="card-text">{patient.diagnose}</p>
              </div>
            </Row>
            <Row style={{ marginTop: "20px", marginBottom: "-40px" }}>
              <div className="d-flex justify-content-center">
                <Col xxl={4} xs={4} md={4}>
                  <button
                    style={{ backgroundColor: ColorPalette.orange }}
                    type="button"
                    className="btn btn-light position-relative p-4 avatar-sm rounded-rectangle mx-5"
                    onClick={() => setFollowUpModal(FollowUpModalState.DeviceAlerts)}
                  >
                    <span className="avatar-title bg-transparent text-reset">
                      <i
                        className="bx bxs-ev-station bx-rotate-90"
                        style={{ fontSize: "2rem", color: "white" }}
                      ></i>
                    </span>
                    <span
                      className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-rectangle  p-1"
                      style={{ backgroundColor: "orange" }}
                    >
                      <span className="noti-dot rounded-pill">
                        {deviceAlerts}
                      </span>
                    </span>
                  </button>
                </Col>
                <Col xxl={4} xs={4} md={4}>
                  <button
                    style={{ backgroundColor: ColorPalette.red }}
                    type="button"
                    className="btn btn-light position-relative p-4 avatar-sm rounded-rectangle mx-5"
                    onClick={() => setFollowUpModal(FollowUpModalState.GeneralWarnings)}
                  >
                    <span className="avatar-title bg-transparent text-reset">
                      <i
                        className="bx bx-pulse bx-flashing "
                        style={{ fontSize: "2rem", color: "white" }}
                      ></i>
                    </span>
                    <span
                      className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-rectangle p-1"
                      style={{ backgroundColor: "red" }}
                    >
                      <span className="noti-dot rounded-pill">{warnings}</span>
                    </span>
                  </button>
                </Col>
                <Col xxl={4} xs={4} md={4}>
                  <button
                    style={{ backgroundColor: ColorPalette.green }}
                    type="button"
                    className="btn btn-light position-relative p-4 avatar-sm rounded-rectangle mx-5"
                    onClick={() =>
                      setFollowUpModal(FollowUpModalState.Resolved)
                    }
                  >
                    <span className="avatar-title bg-transparent text-reset">
                      <i
                        className="bx bx-calendar-check"
                        style={{ fontSize: "2rem", color: "white" }}
                      ></i>
                    </span>
                    <span
                      className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-rectangle  p-1"
                      style={{ backgroundColor: "green" }}
                    >
                      <span className="noti-dot rounded-pill">{resolved}</span>
                    </span>
                  </button>
                </Col>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Card>
    </React.Fragment>
  );
};

export default PatientInfoCard;
