import React, { useState, useEffect } from "react";
import PieChart from "../../../components/Charts/PieChart";
import BarChart from "../../../components/Charts/BarChart";
import AreaChart from "../../../components/Charts/AreaChart";
import LineChart from "../../../components/Charts/LineChart";
import {
  CardTitle,
  Button,
  Row,
  CardHeader,
  Container,
  Card,
  CardBody,
  Col,
} from "reactstrap";
import { CreateTitleLabel } from "../../../components/TextInputTemplates";
import { useTranslation } from "react-i18next";
import { ColorPalette } from "../../../components/ColorPalette";
import dateTimeUtils from "../../../components/Charts/DateTimeUtils";
import PublicIcon from "@mui/icons-material/Public";
import TuneIcon from "@mui/icons-material/Tune";
import SyncIcon from "@mui/icons-material/Sync";
import getDeviceValue from "../getDeviceValue";

const Tab4 = () => {
  const { t } = useTranslation();
  const [series, setSeries] = useState([
    {
      name: "RA Pacing",
      data: [[new Date("2023-06-01").getTime(), 44]],
    },
    {
      name: "RV Pacing",
      data: [[new Date("2023-06-01").getTime(), 76]],
    },
  ]);

  const seriesArea = [
    {
      name: "AP-VS",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2017 GMT").getTime(),
        100,
        {
          min: 5,
          max: 10,
        }
      ),
    },
    {
      name: "AS-VS",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2017 GMT").getTime(),
        100,
        {
          min: 10,
          max: 15,
        }
      ),
    },
    {
      name: "AP-VP",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2017 GMT").getTime(),
        100,
        {
          min: 40,
          max: 70,
        }
      ),
    },
    {
      name: "AS-VP",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2017 GMT").getTime(),
        100,
        {
          min: 100,
          max: 120,
        }
      ),
    },
  ];

  const seriesBrady = [
    {
      name: "RA pacing",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2017 GMT").getTime(),
        300,
        {
          min: 90,
          max: 20,
        }
      ),
    },
    {
      name: "RV pacing",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2017 GMT").getTime(),
        300,
        {
          min: 98,
          max: 100,
        }
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col lg={6}>
              <Card style={{ marginTop: "-80px" }}>
                <CardHeader>
                  <CardTitle>
                    <Button style={{ backgroundColor: ColorPalette.blue }}>
                      <TuneIcon sx={{ fontSize: 20, color: "white" }} />
                    </Button>
                    <b className="px-2">{t("BRADY SETTINGS")}</b>
                  </CardTitle>
                </CardHeader>
                <CardBody style={{ height: "400px" }}>
                  <Row>
                    <Col xl={6} md={6}>
                      <CreateTitleLabel
                        name={t("Brady Mode (NBG)")} //MDC_IDC_SET_BRADY_MODE
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_BRADY_MODE")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Brady Mode (Vendor)")}  //MDC_IDC_SET_BRADY_VENDOR_MODE
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_BRADY_VENDOR_MODE")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Lower Rate")}  //MDC_IDC_SET_BRADY_LOWRATE
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_BRADY_LOWRATE")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Hysteresis Rate")}  //MDC_IDC_SET_BRADY_HYSTRATE
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_BRADY_HYSTRATE")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Night Rate")}   //MDC_IDC_SET_BRADY_NIGHT_RATE
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_BRADY_NIGHT_RATE")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Sensor Type")}  //MDC_IDC_SET_BRADY_SENSOR_TYPE
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_BRADY_SENSOR_TYPE")}
                      ></CreateTitleLabel>
                    </Col>
                    <Col xl={6} md={6}>
                      <CreateTitleLabel
                        name={t("Max Tracking Rate")}  //MDC_IDC_SET_BRADY_MAX_TRACKING_RATE
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_BRADY_MAX_TRACKING_RATE")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Max Sensor Rate")}   //MDC_IDC_SET_BRADY_MAX_SENSOR_RATE
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_BRADY_MAX_SENSOR_RATE")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("SAV Delay")}   //MDC_IDC_SET_BRADY_SAV_DELAY_[HIGHLOW]
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_BRADY_SAV_DELAY_[HIGHLOW]")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("RAV Delay")}   //MDC_IDC_SET_BRADY_PAV_DELAY_[HIGHLOW]
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_BRADY_PAV_DELAY_[HIGHLOW]")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Mode Switch Mode:")}   //MDC_IDC_SET_BRADY_AT_MODE_SWITCH_MODE
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_BRADY_AT_MODE_SWITCH_MODE")}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Mode Switch Rate:")}   //MDC_IDC_SET_BRADY_AT_MODE_SWITCH_RATE
                        width="180px"
                        value={getDeviceValue("MDC_IDC_SET_BRADY_AT_MODE_SWITCH_RATE")}
                      ></CreateTitleLabel>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card style={{ marginTop: "-80px" }}>
                <CardHeader>
                  <CardTitle>
                    <Button style={{ backgroundColor: ColorPalette.blue }}>
                      <SyncIcon sx={{ fontSize: 20, color: "white" }} />
                    </Button>
                    <b className="px-2">{t("CRT")}</b>
                  </CardTitle>
                </CardHeader>
                <CardBody style={{ height: "400px" }}>
                  <Row>
                    <CardBody style={{ height: "175px", marginTop: "-20px" }}>
                      <Row>
                        <Col xl={6} md={6}>
                          <CreateTitleLabel
                            name={t("CRT LV-RV Delay:")}   //MDC_IDC_SET_CRT_LVRV_DELAY
                            width="170px"
                            value={getDeviceValue("MDC_IDC_SET_CRT_LVRV_DELAY")}
                          ></CreateTitleLabel>
                        </Col>
                        <Col xl={6} md={6}>
                          <CreateTitleLabel
                            name={t("CRT Paced Chambers:")}  //MDC_IDC_SET_CRT_PACED_CHAMBERS
                            width="170px"
                            value={getDeviceValue("MDC_IDC_SET_CRT_PACED_CHAMBERS")}
                          ></CreateTitleLabel>
                        </Col>
                      </Row>
                    </CardBody>
                  </Row>
                  <Row>
                    <CardBody
                      style={{
                        height: "175px",
                        marginTop: "-100px",
                        marginLeft: "-20px",
                      }}
                    >
                      <Row>
                        <Col xl={6} md={6}>
                          <h6>CRT CHART</h6>
                          <PieChart
                            labels={[
                              "As-VP",
                              "As-VP",
                              "As-VP",
                              "As-VP",
                              "As-VP",
                            ]}
                            series={[2, 4, 5, 1]}
                          />
                        </Col>
                        <Col xl={6} md={6}>
                          <h6 style={{ marginBottom: "-10px" }}>
                            RA/RV PACING
                          </h6>
                          <BarChart series={series} yLabel="(%)" />
                        </Col>
                      </Row>
                    </CardBody>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Card>
              <CardHeader>
                <CardTitle>
                  <Button style={{ backgroundColor: ColorPalette.blue }}>
                    <SyncIcon sx={{ fontSize: 20, color: "white" }} />
                  </Button>
                  <b className="px-2">{t("BRADY CHART")}</b>
                </CardTitle>{" "}
              </CardHeader>
              <CardBody style={{ height: "700px" }}>
                <Row style={{ height: "320px" }}>
                  <LineChart
                    inputData={seriesBrady}
                    colors={["#0000FF", "#D22B2B"]}
                    yLabel="Stimulation Brady (%)"
                  />
                </Row>
                <Row
                  style={{
                    height: "320px",
                    marginTop: "20px",
                  }}
                >
                  <AreaChart
                    inputData={seriesArea}
                    colors={["#D22B2B", "#029386", "#3980c0", "#FFFF00"]}
                    yLabel="A/V Sequencies (%)"
                  />
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Tab4;
