import {
  Card,
  CardBody,
  CardTitle,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Col,
  Button,
} from "reactstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useTranslation } from "react-i18next";

import DeleteModal from "../../components/Common/DeleteModal"
import patientService from "../../services/patients"


export const PatientForm = ({ patient, onSubmit, onDelete, onCancel, hideResetButton }) => {
  const { t } = useTranslation();

  const initialPatient = patient ?? {
    first_name: '',
    last_name: '',
    birthday: '',
    sex: undefined,
    address: '',
    house_number: '',
    city: '',
    post_code: '',
    telephone: '',
    fax: '',
    email: '',
    health_insurance_id: '',
    health_insurance_provider: undefined,
    health_insurance_expiration_date: '',
    device:''
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialPatient,
    validationSchema: Yup.object({
      ...Object.keys(initialPatient).reduce((obj, key) => {
        obj[key] = Yup.mixed().required(t(key) + ' ' + t('is required') + ' ');
        return obj
      }, {})
    }),
    onSubmit,
  });

  return (
    <Form onSubmit={validation.handleSubmit}>
      <Row>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
          <Label for="first_name"><b>{t("first_name")}</b></Label>
          <Input
            id="first_name"
            name="first_name"
            type="text"
            onChange={validation.handleChange}
            value={validation.values.first_name}
            invalid={
              validation.touched.first_name && validation.errors.first_name
                ? true
                : false
            }
          />
          {validation.touched.first_name && validation.errors.first_name ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.first_name}</div>
            </FormFeedback>
          ) : null}
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
          <Label for="last_name"><b>{t("family_name")}</b></Label>
          <Input
            id="last_name"
            name="last_name"
            type="text"
            onChange={validation.handleChange}
            value={validation.values.last_name}
            invalid={
              validation.touched.last_name && validation.errors.last_name
                ? true
                : false
            }
          />
          {validation.touched.last_name && validation.errors.last_name ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.last_name}</div>
            </FormFeedback>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
          <Label for="birthday"><b>{t("birthday")}</b></Label>
          <Input
            type="date"
            name="birthday"
            id="birthday"
            onChange={validation.handleChange}
            value={validation.values.birthday}
            invalid={
              validation.touched.birthday && validation.errors.birthday
                ? true
                : false
            }
          />
        </Col>

        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
          <Label for="sex"><b>{t("sex")}</b></Label>
          <Input
            type="select"
            name="sex"
            id="sex"
            onChange={validation.handleChange}
            value={validation.values.sex}
            invalid={
              validation.touched.sex && validation.errors.sex ? true : false
            }
            defaultValue=""
          >
            <option value="" hidden>{t('Please select')}...</option>
            <option value="Female">{t("Female")}</option>
            <option value="Male">{t("Male")}</option>
          </Input>
        </Col>
      </Row>
      <Row>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
          <Label for="address"><b>{t("Address")}</b></Label>
          <Input
            id="address"
            name="address"
            type="text"
            onChange={validation.handleChange}
            value={validation.values.address}
            invalid={
              validation.touched.address && validation.errors.address
                ? true
                : false
            }
          />
          {validation.touched.address && validation.errors.address ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.address}</div>
            </FormFeedback>
          ) : null}
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
          <Label for="house_number"><b>{t("HouseNumber")}</b></Label>
          <Input
            id="house_number"
            name="house_number"
            type="text"
            onChange={validation.handleChange}
            value={validation.values.house_number}
            invalid={
              validation.touched.house_number &&
                validation.errors.house_number
                ? true
                : false
            }
          />
          {validation.touched.house_number &&
            validation.errors.house_number ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.house_number}</div>
            </FormFeedback>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
          <Label for="city"><b>{t("City")}</b></Label>
          <Input
            id="city"
            name="city"
            type="text"
            onChange={validation.handleChange}
            value={validation.values.city}
            invalid={
              validation.touched.city && validation.errors.city
                ? true
                : false
            }
          />
          {validation.touched.city && validation.errors.city ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.city}</div>
            </FormFeedback>
          ) : null}
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
          <Label for="post_code"><b>{t("PostCode")}</b></Label>
          <Input
            id="post_code"
            name="post_code"
            type="text"
            onChange={validation.handleChange}
            value={validation.values.post_code}
            invalid={
              validation.touched.post_code && validation.errors.post_code
                ? true
                : false
            }
          />
          {validation.touched.post_code && validation.errors.post_code ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.post_code}</div>
            </FormFeedback>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
          <Label for="telephone"><b>{t("Telephone")}</b></Label>
          <Input
            id="telephone"
            name="telephone"
            type="text"
            onChange={validation.handleChange}
            value={validation.values.telephone}
            invalid={
              validation.touched.telephone && validation.errors.telephone
                ? true
                : false
            }
          />
          {validation.touched.telephone && validation.errors.telephone ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.telephone}</div>
            </FormFeedback>
          ) : null}
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
          <Label for="fax"><b>{t("Fax")}</b></Label>
          <Input
            id="fax"
            name="fax"
            type="text"
            onChange={validation.handleChange}
            value={validation.values.fax}
            invalid={
              validation.touched.fax && validation.errors.fax ? true : false
            }
          />
          {validation.touched.fax && validation.errors.fax ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.fax}</div>
            </FormFeedback>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
          <Label for="email">{t("Email")}</Label>
          <Input
            id="email"
            name="email"
            type="text"
            onChange={validation.handleChange}
            value={validation.values.email}
            invalid={
              validation.touched.email && validation.errors.email
                ? true
                : false
            }
          />
          {validation.touched.email && validation.errors.email ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.email}</div>
            </FormFeedback>
          ) : null}
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
          <Label for="device"> <b>{t("Device")}</b> </Label>
          <Input
            id="device"
            name="device"
            type="text"
            onChange={validation.handleChange}
            value={validation.values.device}
            invalid={
              validation.touched.device && validation.errors.device
                ? true
                : false
            }
          />
          {validation.touched.device && validation.errors.device ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.device}</div>
            </FormFeedback>
          ) : null}
        </Col>      </Row>
      <Row>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
          <Label for="health_insurance_id"><b>{t("HealthInsuranceId")}</b></Label>
          <Input
            id="health_insurance_id"
            name="health_insurance_id"
            type="text"
            onChange={validation.handleChange}
            value={validation.values.health_insurance_id}
            invalid={
              validation.touched.health_insurance_id &&
                validation.errors.health_insurance_id
                ? true
                : false
            }
          />
          {validation.touched.health_insurance_id &&
            validation.errors.health_insurance_id ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.health_insurance_id}</div>
            </FormFeedback>
          ) : null}
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
          <Label for="health_insurance_provider">
          <b> {t("HealthInsuranceProvider")}</b>
          </Label>
          <Input
            id="health_insurance_provider"
            name="health_insurance_provider"
            type="select"
            onChange={validation.handleChange}
            value={validation.values.health_insurance_provider}
            invalid={
              validation.touched.health_insurance_provider &&
                validation.errors.health_insurance_provider
                ? true
                : false
            }
            defaultValue=""
          >
            <option value="" hidden>{t('Please select')}...</option>
            <option value="AOK">{t("AOK")}</option>
            <option value="HKK">{t("HKK")}</option>
            <option value="TK">{t("TK")}</option>
            <option value="IKK">{t("IKK")}</option>
            <option value="DAK">{t("DAK")}</option>
            <option value="KKH">{t("KKH")}</option>
          </Input>
        </Col>
      </Row>
      <Row>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
          <Label for="health_insurance_expiration_date">
          <b>   {t("HealthInsuranceExpirationDate")}</b>
          </Label>
          <Input
            id="health_insurance_expiration_date"
            name="health_insurance_expiration_date"
            type="date"
            onChange={validation.handleChange}
            value={validation.values.health_insurance_expiration_date}
            invalid={
              validation.touched.health_insurance_expiration_date &&
                validation.errors.health_insurance_expiration_date
                ? true
                : false
            }
          />
          {validation.touched.health_insurance_expiration_date &&
            validation.errors.health_insurance_expiration_date ? (
            <FormFeedback type="invalid">
              <div>
                {validation.errors.health_insurance_expiration_date}
              </div>
            </FormFeedback>
          ) : null}
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12}></Col>
      </Row>
      <div className="d-flex py-4 justify-content-between">
        <div>
          {onDelete && <Button
            type="button"
            color="danger"
            onClick={onDelete}>
            {t('Delete')}
          </Button>}
        </div>
        <div>
          {hideResetButton ??
            <Button type="button" className="me-2" color="secondary" onClick={validation.resetForm}>
              {t('Reset')}
            </Button>}
          {onCancel &&
            <Button type="button" className="me-2" onClick={onCancel}>
              {t('Cancel')}
            </Button>}
          <Button type="submit" color="primary">{t('Save')}</Button>
        </div>
      </div>
    </Form >
  )
}

const PatientFormCard = ({ patient, onSubmit, cardTitle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  document.title = t("patientInfo");

  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteEvent = () => {
    patientService.remove(patient.uuid).then(() => {
      setDeleteModal(false);
      localStorage.removeItem("patient");
      navigate("/DoctorDashboard")
    })
  }

  return (
    <Card>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
        title={t('confirm-delete-patient-title')}
        message={t('confirm-delete-patient-message')}
      />
      <CardTitle><h4>{cardTitle}</h4></CardTitle>
      <CardBody>
        <PatientForm patient={patient} onSubmit={onSubmit} onDelete={() => setDeleteModal(true)} />
      </CardBody>
    </Card >
  );
};

export default PatientFormCard;
