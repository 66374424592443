import { useDispatch, useSelector } from "react-redux";
import FeatherIcon from 'feather-icons-react';

import { changeTheme, changeTopbarTheme, } from '../../store/actions';
import { themeModeTypes, } from '../../constants/layout';

const ToggleThemeButton = () => {
  const dispatch = useDispatch();

  const { themeMode } = useSelector(state => ({
    themeMode: state.Layout.themeMode,
  }));

  const toggleThemeMode = () => {
    dispatch(changeTopbarTheme(themeMode == "light" ? "dark" : "light"))
    dispatch(changeTheme(themeMode == "light" ? themeModeTypes.DARK : themeModeTypes.LIGHT))
  }

  return (
    <div className="dropdown d-none d-sm-inline-block">
      <button type="button"
        className="btn header-item light-dark"
        id="mode-setting-btn"
        onClick={toggleThemeMode}
      >
        <FeatherIcon icon="moon" className="icon-sm layout-mode-dark" />
        <FeatherIcon icon="sun" className="icon-sm layout-mode-light" />
      </button>
    </div>

  )
}

export default ToggleThemeButton