import React from "react";
import ReactApexChart from "react-apexcharts";

const ScatterChart = ({ InputSeries, height, width, yMin, yMax, colors,yLabel }) => {
  const series = InputSeries;
  const options = {
    colors: colors ?? ["#3980c0", "#9ccc65", "#D22B2B"],
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false, floating: true, offsetY: -20, offsetX: -10 },
      height: 350,
      type: "scatter",
      // zoom: {
      //   type: "xy",
      // },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: true,
      borderColor: "#111",
      strokeDashArray: 7,
      position: "back",
      xaxis: {
        lines: {
          show: true,
          offsetX: 10,
          offsetY: 10
        }
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      show: true,
      title: {text: yLabel ?? "Y Axis"},
      min: yMin ?? 0,
      max: yMax ?? 100,
    },
    legend: {
      showForSingleSeries: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      offsetY: 0,
      offsetX: 0,
    }
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="scatter"
        height={height ? height : 300}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default ScatterChart;
