import React from 'react';

import LanguageDropdown from '../../components/Common/TopbarDropdown/LanguageDropdown';
import NotificationDropdown from '../../components/Common/TopbarDropdown/VerticalNotificationDropdown';

// import Img
import ProfileMenu from '../../components/Common/TopbarDropdown/ProfileMenu';
import ToggleThemeButton from '../../components/Common/ToggleThemeButton';

const Header = () => {
  return (
    <React.Fragment>
      <header id="page-topbar" className="isvertical-topbar" style={{ left: 0 }}>
        <div className="navbar-header">
          <div className="d-flex">
            <form className="app-search d-none d-sm-block">
              <div className="position-relative">
                DEMO SANA
              </div>
            </form>
          </div>

          <div className="d-flex">
            <LanguageDropdown />
            <NotificationDropdown />
            <ToggleThemeButton />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header;




