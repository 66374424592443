import React from "react";
import ReactApexChart from "react-apexcharts";
import dateTimeUtils from "./DateTimeUtils";
const ChartApex = ({ inputData, name, height, width }) => {
  
  const formattedMeasurements = inputData.measurements
  .slice(-width)
  .map(({ date, value }) => ({
    date: dateTimeUtils.formatDateTime(date),
    value,
  }));

const series = [
  {
    name: name,
    data: formattedMeasurements.map(({ date, value }) => ({ x: date, y: value })),
  },
  {
    name: "Upper Limit",
    data: formattedMeasurements.map(({ date }) => ({
      x: date,
      y: inputData.range.max,
    })),
  },
  {
    name: "Lower Limit",
    data: formattedMeasurements.map(({ date }) => ({
      x: date,
      y: inputData.range.min,
    })),
  },
];
  const options = {
    chart: {
      zoom: { enabled: true },
      toolbar: { show: true, floating: true, offsetY: -20, offsetX: -10 },
    },
    colors: ["#3980c0", "#9ccc65", "#000000"],
    stroke: {
      width: [3, 3, 3],
      curve: ["straight", "straight", "straight"],
      dashArray: [0, 8, 8],
    },
    grid: {
      row: { colors: ["transparent", "transparent"], opacity: 1 },
      borderColor: "#f1f1f1",
    },
    markers: { size: [6, 0, 0] },
    xaxis: {
      type: "numeric",
      labels: { format: "yyyy-MM-dd HH:mm" },
      floating: false,
      tickPlacement: "between",
    },
    yaxis: {
      title: { text: name + "  [ " + inputData.unit + " ]" },
      min: inputData.range.min - inputData.range.min / 10,
      max: inputData.range.max + inputData.range.max / 5,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      floating: false,
      offsetY: 0,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: { toolbar: { show: false } },
          legend: { show: false },
        },
      },
    ],
    fill: {
      type: "solid",
      opacity: 1,
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={height ? height : 500}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default ChartApex;
