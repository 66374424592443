import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";

import { FollowUpModalState } from "../../components/FollowUpModal";
import FollowUpModal from "../../components/FollowUpModal";
import patientService from "../../services/patients";
import { ColorPalette } from "../../components/ColorPalette";
import { useQuery, useQueryClient } from "react-query";

const DoctorInfoCard = ({
  warningsCount,
  deviceAlertsCount,
  resolvedCount,
}) => {
  const [followUpModal, setFollowUpModal] = useState(
    FollowUpModalState.Closed
  );

  const queryClient = useQueryClient()
  const query = useQuery(["patients"], () => patientService.getAll(() => queryClient.invalidateQueries(["patients"])))

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const user = authUser.title + " " + authUser.username;

  const { t } = useTranslation();

  if (query.isLoading) return

  return (
    <React.Fragment>
      <FollowUpModal
        state={followUpModal}
        toggle={() => setFollowUpModal(FollowUpModalState.Closed)}
        patients={query.data}
        invalidatePatients={() => queryClient.invalidateQueries(["patients"])}
      />
      <Card
        style={{
          width: "100%",
          height: "95%",
          marginTop: "20px",
          marginBottom: "40px",
        }}
      >
        <Card
          className="card border-dark  text-light text-center "
          style={{
            margin: "20px",
            backgroundColor: ColorPalette.blue,
            height: "100%",
          }}
        >
          <CardBody>
            <Row>
              <h3 className="text-white mb-5"> {t("WelcomeMessage")}</h3>
              <h1 className="text-white mb-5">{user}</h1>
              <p className="card-text">{t("DrGreatingCard")}</p>
            </Row>
            <Row style={{ marginTop: "50px", marginBottom: "10px" }}>
              <div className="d-flex justify-content-center">
                <Col xxl={4} xs={4} md={4}>
                  <button
                    style={{ backgroundColor: ColorPalette.orange }}
                    type="button"
                    className="btn btn-light position-relative p-4 avatar-sm rounded-rectangle mx-5"
                    onClick={() =>
                      setFollowUpModal(FollowUpModalState.DeviceAlerts)
                    }
                  >
                    <span className="avatar-title bg-transparent text-reset">
                      <i
                        className="bx bxs-ev-station bx-rotate-90"
                        style={{ fontSize: "2rem", color: "white" }}
                      ></i>
                    </span>
                    <span
                      className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-rectangle  p-1"
                      style={{ backgroundColor: "orange" }}
                    >
                      <span className="noti-dot rounded-pill">
                        {deviceAlertsCount}
                      </span>
                    </span>
                  </button>
                </Col>
                <Col xxl={4} xs={4} md={4}>
                  <button
                    style={{ backgroundColor: ColorPalette.red }}
                    type="button"
                    className="btn btn-light position-relative p-4 avatar-sm rounded-rectangle mx-5"
                    onClick={() =>
                      setFollowUpModal(FollowUpModalState.GeneralWarnings)
                    }
                  >
                    <span className="avatar-title bg-transparent text-reset">
                      <i
                        className="bx bx-pulse bx-flashing "
                        style={{ fontSize: "2rem", color: "white" }}
                      ></i>
                    </span>
                    <span
                      className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-rectangle p-1"
                      style={{ backgroundColor: "red" }}
                    >
                      <span className="noti-dot rounded-pill">
                        {warningsCount}
                      </span>
                    </span>
                  </button>
                </Col>

                <Col xxl={4} xs={4} md={4}>
                  <button
                    style={{ backgroundColor: ColorPalette.green }}
                    type="button"
                    className="btn btn-light position-relative p-4 avatar-sm rounded-rectangle mx-5"
                    onClick={() =>
                      setFollowUpModal(FollowUpModalState.Resolved)
                    }
                  >
                    <span className="avatar-title bg-transparent text-reset">
                      <i
                        className="bx bx-calendar-check"
                        style={{ fontSize: "2rem", color: "white" }}
                      ></i>
                    </span>
                    <span
                      className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-rectangle  p-1"
                      style={{ backgroundColor: "green" }}
                    >
                      <span className="noti-dot rounded-pill">
                        {resolvedCount}
                      </span>
                    </span>
                  </button>
                </Col>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Card>
    </React.Fragment>
  );
};

export default DoctorInfoCard;
