import React, { useState } from "react";
import {Tab1,Tab2,Tab3,Tab4,Tab5,Tab6} from"./Tabs/Tabs"
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";

import classnames from "classnames";
import { useTranslation } from "react-i18next";

const ChartTabs = () => {
  const [customActiveTab, setcustomActiveTab] = useState("2");

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col xl={12}>
      <Card style={{ marginTop: "-60px" }}>
          <CardBody>
            <Nav tabs className="nav-tabs-custom nav-justified">
              {/* <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">{t("tab1")}</span>
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">{t("tab2")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "3",
                  })}
                  onClick={() => {
                    toggleCustom("3");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">{t("tab3")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "4",
                  })}
                  onClick={() => {
                    toggleCustom("4");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">{t("tab4")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "6",
                  })}
                  onClick={() => {
                    toggleCustom("6");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">{t("tab6")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "5",
                  })}
                  onClick={() => {
                    toggleCustom("5");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">{t("tab5")}</span>
                </NavLink>
              </NavItem>

            </Nav>
            <TabContent activeTab={customActiveTab} className="p-1 text-muted">
              {/* <TabPane tabId="1" id="Open Events">
               <Tab1/>
              </TabPane> */}
              <TabPane tabId="2" id="Summary">
              <Tab2/>
              </TabPane>
              <TabPane tabId="3" id="Leads/Device">
              <Tab3/>
              </TabPane>
              <TabPane tabId="4" id="Brady/CRT">
              <Tab4/>
              </TabPane>
              <TabPane tabId="5" id="Cardio-MeMs">
              <Tab5/>
              </TabPane>
              <TabPane tabId="6" id="Arritmias">
              <Tab6/>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ChartTabs;
