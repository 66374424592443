import React, { useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import ChartDataChecker from "../../components/Charts/ChartDataChecker";
import { useTranslation } from "react-i18next";
import { MaterialReactTable } from "material-react-table";
import dateTimeUtils from "../../components/Charts/DateTimeUtils";

const ArterialBloodPressure = () => {
  const { t } = useTranslation();
  document.title = t("ArterialBloodPressure");
  const patient = JSON.parse(localStorage.getItem("patient"));

  let data = null;
  if (
    !patient.blood_pressure ||
    patient.blood_pressure.measurements.length === 0
  ) {
    patient.blood_pressure = null;
  } else {
    data = patient.blood_pressure.measurements.map(({ date, value }) => ({
      date : dateTimeUtils.formatDateTime(date),
      value,
      source: patient.blood_pressure.source ?? "---",
  }));
  }
  const columns = useMemo(
    () => [
      { accessorKey: "date", header: "Date", size: "150" },
      { accessorKey: "value", header: "Value", size: "150" },
      { accessorKey: "source", header: "Source", size: "150" },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
              <ChartDataChecker
                patientData={patient.blood_pressure}
                name={t("ArterialBloodPressure")}
              />
            </Col>
          </Row>
          <Row>
            {data != null ? (
              <MaterialReactTable columns={columns}
               data={data}
              enableColumnDragging={false}
              initialState={{
                sorting: [
                  {
                    id: 'date', 
                    desc: true,
                  }
                ]}}
              />
            ) : (
              <div></div>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ArterialBloodPressure;
