import {
  Col,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";

export const CreateTitleLabel = ({ name, width, value }) => {
  return (
    <FormGroup key={value}>
      <Col className="d-flex gap-2">
        <InputGroup>
          <InputGroupText style={{ width: width }}>{name}</InputGroupText>
          <Input readOnly value={value} />
        </InputGroup>
      </Col>
    </FormGroup>
  );
};

export const CreateLabel = ({ value }) => {
  return (
    <FormGroup key={value}>
      <Col className="d-flex gap-2 text-end">
        <Label readOnly value={value}>
          {value}
        </Label>
      </Col>
    </FormGroup>
  );
};

export const SingleSelectionBox = ({
  label,
  options,
  selectedOption,
  onChange,
}) => {
  return (
    <FormGroup>
      <Label>{label}</Label>
      <Input type="select" value={selectedOption} onChange={onChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};

export const CheckboxSelectionBox = ({
  label,
  options,
  selectedOptions,
  onChange,
}) => {
  return (
    <FormGroup>
      <Label>{label}</Label>
      {options.map((option) => (
        <FormGroup check key={option.value}>
          <Label check>
            <Input
              type="checkbox"
              value={option.value}
              checked={selectedOptions.includes(option.value)}
              onChange={onChange}
            />
            {option.label}
          </Label>
        </FormGroup>
      ))}
    </FormGroup>
  );
};

export const RadioSelectionBox = ({
  label,
  options,
  selectedOption,
  onChange,
  displayInline = false,
}) => {
  const wrapperClass = displayInline ? "d-flex" : "";

  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <div className={wrapperClass}>
        {options.map((option) => (
          <FormGroup
            check
            key={option.value}
            className={displayInline ? "me-3" : ""}
          >
            <Label check>
              <Input
                type="radio"
                name="radioSelection"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={onChange}
              />
              {option.label}
            </Label>
          </FormGroup>
        ))}
      </div>
    </FormGroup>
  );
};

// export const RadioSelectionBoxQuestionary = ({ questions, options, selectedOptions, onChange }) => {
//     return (
//       <FormGroup>
//         {questions.map((question, questionIndex) => (
//           <Row key={questionIndex}>
//             <Col>
//               <FormGroup>
//                 <Label>{question}</Label>
//               </FormGroup>
//             </Col>
//             {options.map((optionSet, optionSetIndex) => (
//               <Col key={`${questionIndex}-${optionSetIndex}`}>
//                 <FormGroup>
//                   {optionSet.map((option, optionIndex) => {
//                     const radioId = `${questionIndex}-${optionSetIndex}-${optionIndex}`;
//                     return (
//                       <FormGroup check inline key={radioId}>
//                         <Label check htmlFor={radioId}>
//                           <Input
//                             type="radio"
//                             name={`question-${questionIndex}`}
//                             id={radioId}
//                             value={option.value}
//                             checked={selectedOptions[questionIndex] === option.value}
//                             onChange={() => onChange(questionIndex, option.value)}
//                           />
//                           {option.label}
//                         </Label>
//                       </FormGroup>
//                     );
//                   })}
//                 </FormGroup>
//               </Col>
//             ))}
//           </Row>
//         ))}
//       </FormGroup>
//     );
//   };
export const RadioSelectionBoxQuestionary = ({ questions, options, selectedOptions, onChange }) => {
    return (
      <FormGroup>
        {questions.map((question, questionIndex) => (
          <Row key={questionIndex}>
            <Col>
              <FormGroup>
                <Label>{question}</Label>
              </FormGroup>
            </Col>
            {options.map((optionSet, optionSetIndex) => (
              <Col key={`${questionIndex}-${optionSetIndex}`}>
                <FormGroup>
                  {optionSet.map((option, optionIndex) => {
                    const radioId = `${questionIndex}-${optionSetIndex}-${optionIndex}`;
                    return (
                      <FormGroup check inline key={radioId}>
                        <Label check htmlFor={radioId}>
                          <Input
                            type="radio"
                            name={`question-${questionIndex}`}
                            id={radioId}
                            value={option.value}
                            checked={selectedOptions[questionIndex] === option.value}
                            onChange={() => onChange(questionIndex, option.value)}
                          />
                          {option.label}
                        </Label>
                      </FormGroup>
                    );
                  })}
                </FormGroup>
              </Col>
            ))}
          </Row>
        ))}
      </FormGroup>
    );
  };