import React, { useMemo, useState } from "react";
import {
  Col,
  CardTitle,
  Button,
  Container,
  Row,
  Card,
  CardBody,
  Label,
  Badge,
  Input,
} from "reactstrap";
import EventIcon from "@mui/icons-material/Event";
import { CreateTitleLabel } from "../../../components/TextInputTemplates";
import { ColorPalette } from "../../../components/ColorPalette";
import { useTranslation } from "react-i18next";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import getDeviceValue from "../getDeviceValue";
import LineChart from "../../../components/Charts/LineChart";
import CompressIcon from "@mui/icons-material/Compress";
import dateTimeUtils from "../../../components/Charts/DateTimeUtils";
import mems from "../../../assets/images/mems.png";
import DetailPanel from "../../../components/FollowUpModal/DetailPanel";
const Tab5 = () => {
  const { t } = useTranslation();
  const patient = JSON.parse(localStorage.getItem("patient"));

  const series = [
    {
      name: "PA Systolic",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2017 GMT").getTime(),
        100,
        {
          min: 26,
          max: 38,
        }
      ),
    },
    {
      name: "PA Average",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2017 GMT").getTime(),
        100,
        {
          min: 30,
          max: 40,
        }
      ),
    },
    {
      name: "PA Diastolic",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2017 GMT").getTime(),
        100,
        {
          min: 35,
          max: 45,
        }
      ),
    },
  ];

  const columns = useMemo(
    () => [
      { accessorKey: "ID", header: "ID", size: "50" },
      //MDC_IDC_EPISODE_ID
      { accessorKey: "egm", header: "PAPsys", size: "50" },

      { accessorKey: "type", header: "PAPdiast", size: "50" },
      //MDC_IDC_EPISODE_TYPE
      { accessorKey: "reviewer", header: "PAPmed", size: "50" },

      {
        accessorKey: "devicetype",
        header: "Device Type",
        size: "50", //MDC_IDC_EPISODE_VENDOR_TYPE
      },
      { accessorKey: "date", header: "Date", size: "50" },
      //MDC_IDC_EPISODE_DTM
      { accessorKey: "duration", header: "Reviewer", size: "50" },
      //MDC_IDC_EPISODE_DURATION
      { accessorKey: "Therapie", header: "Therapie", size: "100" },

      //MDC_IDC_EPISODE_DETECTION_THERAPY_DETAILS
      { accessorKey: "result", header: "Result", size: "50" }, //MDC_IDC_EPISODE_THERAPY_RESULT
    ],
    []
  );

  const [data, setData] = useState([
    {
      egm: "32",
      ID: getDeviceValue("MDC_IDC_EPISODE_ID"),

      type: "26",
      reviewer: "29",
      devicetype: "CardioMEMs",
      date: "2023-05-13",
      duration: "Dr. A. Nowik",
      Therapie: "ARNI increased",
      result: "Successful",
    },
    {
      egm: "42",
      ID: 2,
      type: "26",
      reviewer: "35",
      devicetype: "CardioMEMs",
      date: "2023-05-28",
      duration: "Dr. E. Siutsi",
      Therapie: "Sildenafil initiated",
      result: "Successful",
    },
    {
      egm: "38",
      ID: 4,
      type: "30",
      reviewer: "34",
      devicetype: "CardioMEMs",
      date: "2023-06-02",
      duration: "Dr. F. Scheubel",
      Therapie: "Jardiance",
      result: "Successful",
    },
    {
      egm: "28",
      ID: 3,
      type: "24",
      reviewer: "26",
      devicetype: "CardioMEMs",
      date: "2023-06-18",
      duration: "Dr. E. Ricardo",
      Therapie: "Status idem",
      result: "Successful",
    },
    {
      egm: "28",
      ID: 3,
      type: "24",
      reviewer: "26",
      devicetype: "CardioMEMs",
      date: "2023-06-18",
      duration: "Dr. E. Ricardo",
      Therapie: "Status idem",
      result: "Successful",
    },
    // Add more objects as needed
  ]);
  const renderDetailPanel = (row) => {
    // Custom detail panel renderer
    console.log(row);
    return (
      <div>
        <Row style={{ height: "100px" }}>
          <Col sm="4">
            <img src={mems} alt="Your Image" />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <Input type="text" placeholder="Enter text" />
          </Col>
        </Row>
      </div>
    );
  };

  const handleRowSelection = (row) => {
    setSelectedRow(row);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Card style={{ marginTop: "-80px" }}>
              <CardTitle>
                <Button style={{ backgroundColor: ColorPalette.blue }}>
                  <CompressIcon sx={{ fontSize: 20, color: "white" }} />
                </Button>
                <b className="px-2">{t("PA Pressure")}</b>
              </CardTitle>
              <CardBody>
                <Row style={{ height: "320px" }}>
                  <LineChart
                    inputData={series}
                    colors={["#0000FF", "#00AAFF", "#0066FF"]}
                    yLabel="mmHg"
                  />
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Row>
            <Card>
              <CardTitle>
                <Button style={{ backgroundColor: ColorPalette.blue }}>
                  <EventIcon sx={{ fontSize: 20, color: "white" }} />
                </Button>
                <b className="px-2">{t("Chronic Measurements")}</b>
              </CardTitle>
              <CardBody>
                <Row>
                  <MaterialReactTable
                    columns={columns}
                    data={data}
                    onRowSelection={handleRowSelection}
                    renderDetailPanel={({ row }) =>
                      <DetailPanel ecg={mems} minWidth={1400} />
                    }
                    isRowSelectable={() => true} // Enable row selection for all rows
                    enableColumnDragging={false}
                    isRowSelected={(rowData) => rowData === selectedRow} // Check if a row is selected
                    enableColumnVirtualization
                    enableRowActions
                    renderRowActions={({ row, table }) => (
                      <Box
                        sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}
                      >
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            table.setEditingRow(row);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => {
                            data.splice(row.index, 1); //assuming simple data table
                            setData([...data]);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    )}
                  />
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Tab5;
