import React from "react";
import {
  Button,
  CardTitle,
  CardHeader,
  Container,
  Row,
  Card,
  CardBody,
  Col,
} from "reactstrap";
import ScatterChart from "../../../components/Charts/ScatterChart";
import { CreateTitleLabel } from "../../../components/TextInputTemplates";
import { useTranslation } from "react-i18next";
import dateTimeUtils from "../../../components/Charts/DateTimeUtils";
import PublicIcon from "@mui/icons-material/Public";
import CableIcon from '@mui/icons-material/Cable';
import AddchartIcon from '@mui/icons-material/Addchart';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import { ColorPalette } from "../../../components/ColorPalette";
import getDeviceValue from "../getDeviceValue";
const Tab3 = () => {
  const { t } = useTranslation();
  const patient = JSON.parse(localStorage.getItem("patient"));

  const series0 = [
    {
      name: "RV",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2023 GMT").getTime(),
        100,
        {
          min: 2,
          max: 25,
        }
      ),
    },
  ];
  const series1 = [
    {
      name: "RV",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2023 GMT").getTime(),
        100,
        {
          min: 380,
          max: 410,
        }
      ),
    },
    {
      name: "RA",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2023 GMT").getTime(),
        100,
        {
          min: 440,
          max: 502,
        }
      ),
    },
    {
      name: "LV",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2023 GMT").getTime(),
        100,
        {
          min: 300,
          max: 350,
        }
      ),
    },
  ];
  const series2 = [
    {
      name: "Shock/HV",
      data: dateTimeUtils.generateDayWiseTimeSeries(
        new Date("11 Feb 2017 GMT").getTime(),
        100,
        {
          min: 40,
          max: 50,
        }
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Card style={{ marginTop: "-80px" }}>
              <CardHeader>
                <CardTitle>
                  <Button style={{ backgroundColor: ColorPalette.blue }}>
                    <DeveloperBoardIcon sx={{ fontSize: 20, color: "white" }} />
                  </Button>
                  <b className="px-2">{t("DEVICE")}</b>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                    <CreateTitleLabel
                      name={t("Last measure date:")}  //MDC_IDC_MSMT_BATTERY_DTM
                      width="220px"
                      value={getDeviceValue("MDC_IDC_MSMT_BATTERY_DTM")}
                    ></CreateTitleLabel>
                    <CreateTitleLabel
                      name={t("Status")}  //MDC_IDC_MSMT_BATTERY_STATUS
                      width="220px"
                      value={getDeviceValue("MDC_IDC_MSMT_BATTERY_STATUS")}
                    ></CreateTitleLabel>
                    <CreateTitleLabel
                      name={t("Time to ERI:")}   //MDC_IDC_MSMT_BATTERY_RRT_TRIGGER
                      width="220px"
                      value={getDeviceValue("MDC_IDC_MSMT_BATTERY_RRT_TRIGGER")}
                    ></CreateTitleLabel>
                  </Col>
                  <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                    <CreateTitleLabel
                      name={t("Device Model:")}  //MDC_IDC_DEV_MODEL
                      width="220px"
                      value={getDeviceValue("MDC_IDC_DEV_MODEL")}
                    ></CreateTitleLabel>
                   
                    <CreateTitleLabel
                      name={t("Device Serial:")}  //MDC_IDC_DEV_SERIAL
                      width="220px"
                      value={getDeviceValue("MDC_IDC_DEV_SERIAL")}
                    ></CreateTitleLabel>
                    <CreateTitleLabel
                      name={t("Implant Date:")}  //MDC_IDC_DEV_IMPLANT_DT
                      width="220px"
                      value={getDeviceValue("MDC_IDC_DEV_IMPLANT_DT")}
                    ></CreateTitleLabel>
                  </Col>
                  <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                    <CreateTitleLabel
                      name={t("Charge time:")}  //MDC_IDC_MSMT_CAP_CHARGE_TIME
                      width="220px"
                      value={getDeviceValue("MDC_IDC_MSMT_CAP_CHARGE_TIME")}
                    ></CreateTitleLabel>
                    <CreateTitleLabel
                      name={t("Charge Energy:")}  //MDC_IDC_MSMT_CAP_CHARGE_ENERGY
                      width="220px"
                      value={getDeviceValue("MDC_IDC_MSMT_CAP_CHARGE_ENERGY")}
                    ></CreateTitleLabel>
                    <CreateTitleLabel
                      name={t("Voltage :")}
                      width="220px"
                      value={getDeviceValue("MDC_IDC_MSMT_BATTERY_DTM")}
                    ></CreateTitleLabel>
                  </Col>
                  <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                    <CreateTitleLabel
                      name={t("Time since Implant:")}  //MDC_IDC_MSMT_BATTERY_VOLTAGE
                      width="220px"
                      value={getDeviceValue("MDC_IDC_MSMT_BATTERY_VOLTAGE")}
                    ></CreateTitleLabel>
                    <CreateTitleLabel
                      name={t("Battery Status")}   //MDC_IDC_MSMT_BATTERY_STATUS
                      width="220px"
                      value={getDeviceValue("MDC_IDC_MSMT_BATTERY_STATUS")}
                    ></CreateTitleLabel>
                  </Col>
                </Row>
              </CardBody>
              <Row>
                <div
                  className="progress Progress-xl"
                  style={{ marginTop: "20px", marginBottom: "-20px" }}
                >
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="200"
                    style={{ width: "100%" }}
                  ></div>
                </div>
              </Row>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>
                  <Button style={{ backgroundColor: ColorPalette.blue }}>
                    <CableIcon sx={{ fontSize: 20, color: "white" }} />
                  </Button>
                  <b className="px-2">{t("LEAD STATUS")}</b>
                </CardTitle>{" "}
              </CardHeader>
              <Row>
                <Card>
                  <CardBody>
                    <CardBody>
                      <Row>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                          <h4 className="card-title">RA LEAD</h4>
                        </Col>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                          <CreateTitleLabel
                            name={t("Pacing")}
                            width="220px"
                            value={getDeviceValue("MDC_IDC_MSMT_LEADCHNL_[CHAMBER]_PACING_THRESHOLD_AMPLITUDE")}
                          ></CreateTitleLabel>
                        </Col>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                          <CreateTitleLabel
                            name={t("Sensing")}
                            width="220px"
                            value={getDeviceValue("MDC_IDC_MSMT_LEADCHNL_[CHAMBER]_SENSING_INTR_AMPL_[MMM]")}
                          ></CreateTitleLabel>
                        </Col>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                          <CreateTitleLabel
                            name={t("Impedance")}
                            width="220px"
                            value={getDeviceValue("MDC_IDC_MSMT_LEADHVCHNL_IMPEDANCE")}
                          ></CreateTitleLabel>
                        </Col>
                      </Row>
                    </CardBody>
                    <hr />
                    <CardBody>
                      <Row>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                          <h4 className="card-title">RV LEAD</h4>
                        </Col>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                          <CreateTitleLabel
                            name={t("Pacing")}
                            width="220px"
                            value={getDeviceValue("MDC_IDC_STAT_AT_MODE_SW_COUNT")}
                          ></CreateTitleLabel>
                        </Col>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                          <CreateTitleLabel
                            name={t("Sensing")}
                            width="220px"
                            value={getDeviceValue("MDC_IDC_MSMT_LEADCHNL_[CHAMBER]_SENSING_INTR_AMPL_[MMM]")}
                          ></CreateTitleLabel>
                        </Col>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                          <CreateTitleLabel
                            name={t("Impedance")}
                            width="220px"
                            value={getDeviceValue("MDC_IDC_MSMT_LEADCHNL_[CHAMBER]_IMPEDANCE_VALUE")}
                          ></CreateTitleLabel>
                        </Col>
                      </Row>
                    </CardBody>
                    <hr />
                    <CardBody>
                      <Row>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                          <h4 className="card-title">LV LEAD</h4>
                        </Col>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                          <CreateTitleLabel
                            name={t("Pacing")}  //MDC_IDC_MSMT_LEADCHNL_[CHAMBER]_PACING_THRESHOLD_AMPLITUDE
                            width="220px"
                            value={getDeviceValue("MDC_IDC_MSMT_LEADCHNL_[CHAMBER]_PACING_THRESHOLD_AMPLITUDE")}
                          ></CreateTitleLabel>
                        </Col>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                          <CreateTitleLabel
                            name={t("Sensing")}  //MDC_IDC_MSMT_LEADCHNL_[CHAMBER]_SENSING_INTR_AMPL_[MMM]
                            width="220px"
                            value={getDeviceValue("MDC_IDC_MSMT_LEADCHNL_[CHAMBER]_SENSING_INTR_AMPL_[MMM]")}
                          ></CreateTitleLabel>
                        </Col>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                          <CreateTitleLabel
                            name={t("Impedance")}  //MDC_IDC_MSMT_LEADCHNL_[CHAMBER]_IMPEDANCE_VALUE
                            width="220px"
                            value={getDeviceValue("MDC_IDC_MSMT_LEADCHNL_[CHAMBER]_IMPEDANCE_VALUE")}
                          ></CreateTitleLabel>
                        </Col>
                      </Row>
                    </CardBody>
                    <hr />
                    <CardBody>
                      <Row>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                          <h4 className="card-title">SCHOCK LEAD</h4>
                        </Col>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}></Col>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}></Col>
                        <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                          <CreateTitleLabel
                            name={t("Impedance")}   //MDC_IDC_MSMT_LEADHVCHNL_IMPEDANCE
                            width="220px"
                            value={getDeviceValue("MDC_IDC_MSMT_LEADHVCHNL_IMPEDANCE")}
                          ></CreateTitleLabel>
                        </Col>
                      </Row>
                    </CardBody>
                    <Row></Row>
                  </CardBody>
                </Card>
              </Row>
              <Row>
                <Card>
                  <CardHeader>
                    <CardTitle>
                      <Button style={{ backgroundColor: ColorPalette.blue }}>
                        <AddchartIcon sx={{ fontSize: 20, color: "white" }} />
                      </Button>
                      <b className="px-2">{t("LEADS GRAPH")}</b>
                    </CardTitle>
                  </CardHeader>

                  <CardBody style={{ height: "980px" }}>
                    <Row style={{ height: "300px" }}>
                      <ScatterChart
                        InputSeries={series0}
                        yMin={0}
                        yMax={25}
                        yLabel="Sensing Amplitude (mV)"
                        colors={["#D22B2B"]}
                      />
                    </Row>
                    <Row style={{ height: "300px", marginTop: "20px" }}>
                      <ScatterChart
                        InputSeries={series1}
                        yMin={200}
                        yMax={1000}
                        yLabel="Impedance (ohms)"
                        colors={["#3980c0", "#029386", "#D22B2B"]}
                      />
                    </Row>
                    <Row style={{ height: "300px", marginTop: "20px" }}>
                      <ScatterChart
                        InputSeries={series2}
                        yMin={40}
                        yMax={120}
                        yLabel="Shock Lead Impedance (ohms)"
                        colors={["#3980c0"]}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Row>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Tab3;
