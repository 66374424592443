import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";


const SubmitButtonGroup = ({ onReset }) => {
  const { t } = useTranslation();

  return (
    <div className="text-end mt-4">
      <ul className="list-inline">
        <li className="list-inline-item">
          <Button color="secondary" onClick={onReset}> {t('Reset')}</Button>
        </li>
        <li className="list-inline-item">
          <Button type="submit" color="primary">{t('Save')}</Button>
        </li>
      </ul>
    </div>
  )
}

export default SubmitButtonGroup