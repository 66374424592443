import React, { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { Container, Row, Col } from "reactstrap";
import ChartDataChecker from "../../components/Charts/ChartDataChecker";
import { useTranslation } from "react-i18next";
import dateTimeUtils from "../../components/Charts/DateTimeUtils";

const RestingPulse = () => {
  const{t}=useTranslation();
  document.title = t("resting_pulse")
  const patient = JSON.parse(localStorage.getItem("patient"));
  
  let data=null;
  if (!patient.resting_pulse || patient.resting_pulse.measurements.length === 0) {
    patient.resting_pulse=null;
  }
  else
  {
    data = patient.resting_pulse.measurements.map(({ date, value }) => ({
      date : dateTimeUtils.formatDateTime(date),
      value,
      source:patient.resting_pulse.source ?? "---"
    }));
  }
  const columns = useMemo(
    () => [
      { accessorKey: "date", header: "Date", size: "150" },
      { accessorKey: "value", header: "Value", size: "150" },
      { accessorKey: "source", header: "Source", size: "150" },
    ],
    []
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
              <ChartDataChecker
                patientData={
                  patient.resting_pulse
                }
                name={t("resting_pulse")}
                apex1={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
              {data != null ? (
                <MaterialReactTable columns={columns} data={data} enableColumnDragging={false}
                initialState={{
                  sorting: [
                    {
                      id: 'date', 
                      desc: true,
                    }
                  ]}}
                   />
              ) : (
                <div></div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RestingPulse;
