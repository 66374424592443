import jsonData from "./deviceData.json";

const getDeviceValue = (referenceId) => {
  const patient = JSON.parse(localStorage.getItem("patient"));


  if(patient === null) return null;
  // console.log("patient", patient);

  var patientID = patient.first_name == "Johnny" ? 0 : 1;
  const item = jsonData.find((item) => item["Reference ID"] === referenceId);
  if (patientID === 0) {
    return item ? item["Value1"] : null;
  } else {
    return item ? item["Value2"] : null;
  }
};

export default getDeviceValue;
