// dateTimeUtils.js
const formatDateTimeTimeZoneUS = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);
  const formattedDateTime = dateTime.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: "UTC",
  });

  return formattedDateTime;
};

const formatDateTime = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, "0");
  const day = String(dateTime.getDate()).padStart(2, "0");
  const hours = String(dateTime.getHours()).padStart(2, "0");
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");

  const formattedDateTime = dateTimeString.includes(":")
    ? `${year}-${month}-${day} ${hours}:${minutes}`
    : `${year}-${month}-${day}`;

  return formattedDateTime;
};

const generateDayWiseTimeSeries = (baseval, count, yrange) => {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = baseval;
    var y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    series.push([x, y]);
    baseval += 86400000; // Adding 1 day in milliseconds
    i++;
  }
  return series;
};



export default {
  formatDateTimeTimeZoneUS,
  formatDateTime,
  generateDayWiseTimeSeries,
};
