import { ColorPalette } from "../../components/ColorPalette";
import { useNavigate } from "react-router-dom";
import { Grid, _ } from "gridjs-react";
import { deDE } from "gridjs/l10n";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import { FollowUpModalState } from "../../components/FollowUpModal";
import { useReactToPrint } from "react-to-print";
import { useEffect, useRef, useState } from "react";
import PdfDocument from "../../components/PdfDocument";
import { getLoggedinUser } from "../../helpers/api_helper";

const PatientsGrid = ({
  patients,
  filter,
  attributes,
  setFollowUpModal,
}) => {
  const { t, i18n } = useTranslation();
  const [currentPatient, setCurrentPatient] = useState(null);
  const [print, setPrint] = useState(false);

  const navigate = useNavigate();
  const refPdf = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => refPdf?.current,
  });

  const onPrintButtonClicked = (cell) => {
    setCurrentPatient(cell);
    setPrint(!print);
  };

  useEffect(handlePrint, [print]);

  const formatCritical = (args) => {
    if (args == undefined) return;
    const { unit, range, measurements } = args;
    let value = measurements.at(-1).value;

    if (typeof value === "string" && value.includes("/")) {
      const splits = value.split("/");
      value =
        Math.round(parseFloat(splits[0])) +
        "/" +
        Math.round(parseFloat(splits[1]).toFixed(2));
    }

    const valueWithUnit = unit ? value + " " + unit.toLowerCase() : value;

    if (value < range.min || value > range.max) {
      return _(<div style={{ color: "red" }}>{valueWithUnit}</div>);
    } else {
      return _(valueWithUnit);
    }
  };

  const formatCriticalPAM = (args) => {
    if (args == undefined) return;
    const { unit, range, measurements } = args;
    const { systolic, diastolic } = measurements.at(-1);

    if (systolic < range.systolic.min || systolic > range.systolic.max) {
      return _(
        <div>
          <div style={{ color: "red" }}>{systolic}</div>
          {diastolic} {unit.toLowerCase()}
        </div>
      );
    }

    if (diastolic < range.diastolic.min || diastolic > range.diastolic.max) {
      return _(
        <div>
          {systolic}
          <div style={{ color: "red" }}>{diastolic}</div> {unit.toLowerCase()}
        </div>
      );
    } else {
      return _(`${systolic}/${diastolic} ${unit.toLowerCase()}`);
    }
  };

  const sortCritical = {
    compare: (a, b) => {
      try {
        if (a === undefined) return -1;
        if (b === undefined) return 1;
        return a.measurements.at(-1).value - b.measurements.at(-1).value;
      } catch (e) {
        console.log("could not compare", a, b);
      }
    },
  };

  const hideIfFilter = (cell, row, column) => {
    if (filter && !attributes.includes(column.id)) {
      return { hidden: "hidden" };
    }
  };

  const openFollowUpModal = (option, patient) => {
    localStorage.setItem("patient", JSON.stringify(patient));
    setFollowUpModal(option);
  };

  // TODO: Prefer different method, maybe redux-persist
  const routeToPatient = (patient) => {
    localStorage.setItem("patient", JSON.stringify(patient));
    navigate("/patient-dashboard");
  };
  return (
    <Grid
      columns={[
        {
          id: "name",
          name: _(<h5>{t("name")}</h5>),
          data: (row) => row,
          formatter: (cell, row) =>
            _(
              <div className="text-start">
                {filter && (
                  <i
                    className="btn mdi mdi-file-pdf"
                    onClick={() => openPdfModal(cell)}
                  />
                )}
                {
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => routeToPatient(cell)}
                  >
                    <font size="+1">
                      {`${cell.first_name} ${cell.last_name}`}
                    </font>
                  </button>
                }
              </div>
            ),
          sort: {
            compare: (a, b) => a.first_name.localeCompare(b.first_name),
          },
          width: "200px",
        },
        {
          id: "birthday",
          width: "150px",
          name: _(<h5>{t("birthday")}</h5>),
        },
        {
          id: "device_alerts",
          name: _(<h5>{t("device_alerts")}</h5>),
          data: (row) => row,
          formatter: (cell) =>
            _(
              <Button
                outline
                size="lg"
                className="rounded-rectangle"
                style={{
                  color: "white",
                  backgroundColor: ColorPalette.orange,
                  borderColor: ColorPalette.orange,
                }}
                onClick={() =>
                  openFollowUpModal(FollowUpModalState.DeviceAlerts, cell)
                }
                disabled={
                  cell.device_alerts == undefined ||
                  cell.device_alerts.length -
                  cell.device_alerts.filter(
                    (warning) => warning.level === "resolved"
                  ).length ==
                  0
                }
              >
                <b>
                  {cell.device_alerts?.length -
                    cell.device_alerts?.filter(
                      (warning) => warning.level === "resolved"
                    ).length || 0}
                </b>
              </Button>
            ),
          attributes: hideIfFilter,
          width: "150px",
        },
        {
          id: "warnings",
          name: _(<h5>{t("warnings")}</h5>),
          data: (row) => row,
          formatter: (cell) =>
            _(
              <Button
                outline
                size="lg"
                className="rounded-rectangle"
                style={{
                  color: "white",
                  backgroundColor: ColorPalette.red,
                  borderColor: ColorPalette.red,
                }}
                onClick={() =>
                  openFollowUpModal(FollowUpModalState.GeneralWarnings, cell)
                }
                disabled={
                  cell.warnings == undefined ||
                  cell.warnings.length -
                  cell.warnings.filter(
                    (warning) => warning.level === "resolved"
                  ).length ==
                  0
                }
              >
                <b>
                  {cell.warnings?.length -
                    cell.warnings?.filter(
                      (warning) => warning.level === "resolved"
                    ).length || 0}
                </b>
              </Button>
            ),
          attributes: hideIfFilter,
          width: "150px",
        },
        {
          id: "resolved_alerts",
          name: _(<h5>{t("Resolved")}</h5>),
          hidden: getLoggedinUser().title === "Dr",
          data: (row) => row,
          formatter: (cell) => {
            const resolvedAlerts = [...(cell.warnings ?? []), ...(cell.device_alerts ?? [])]
              .filter(it => it.level === "resolved")

            return _(
              <Button
                outline
                size="lg"
                className="rounded-rectangle"
                style={{
                  color: "white",
                  backgroundColor: ColorPalette.green,
                  borderColor: ColorPalette.green,
                }}
                onClick={() => openFollowUpModal(FollowUpModalState.Resolved, cell)}
                disabled={resolvedAlerts.length === 0}
              >
                <b>{resolvedAlerts.length}</b>
              </Button>)
          },
          attributes: hideIfFilter,
          width: "150px",
        },
        {
          id: "resting_pulse",
          name: _(<h5>{t("resting_pulse")}</h5>),
          formatter: formatCritical,
          sort: sortCritical,
          attributes: hideIfFilter,
          width: "150px",
        },
        {
          id: "blood_pressure",
          name: _(<h5>{t("blood_pressure")}</h5>),
          formatter: formatCritical,
          sort: sortCritical,
          attributes: hideIfFilter,
          width: "150px",
        },
        {
          id: "weight",
          name: _(<h5>{t("weight")}</h5>),
          formatter: formatCritical,
          sort: sortCritical,
          attributes: hideIfFilter,
          width: "150px",
        },
        {
          id: "blood_glucose",
          name: _(<h5>{t("blood_glucose")}</h5>),
          formatter: formatCritical,
          sort: sortCritical,
          attributes: hideIfFilter,
          width: "150px",
        },
        {
          id: "blood_oxygen_saturation",
          name: _(<h5>{t("blood_oxygen_saturation")}</h5>),
          formatter: formatCritical,
          sort: sortCritical,
          attributes: hideIfFilter,
          width: "150px",
        },
        {
          id: "pulmonary_arterial_pressure",
          name: _(<h5>{t("pulmonary_arterial_pressure")}</h5>),
          formatter: formatCriticalPAM,
          sort: sortCritical,
          attributes: hideIfFilter,
          width: "150px",
        },

        {
          id: "temperature",
          name: _(<h5>{t("temperature")}</h5>),
          formatter: formatCritical,
          sort: sortCritical,
          attributes: hideIfFilter,
          width: "150px",
        },
        {
          id: "physical_activity",
          name: _(<h5>{t("physical_activity")}</h5>),
          formatter: formatCritical,
          sort: sortCritical,
          attributes: hideIfFilter,
          width: "150px",
        },

        {
          id: "body_fat",
          name: _(<h5>{t("body_fat")}</h5>),
          formatter: formatCritical,
          sort: sortCritical,
          attributes: hideIfFilter,
          width: "150px",
        },
      ]}
      data={patients}
      sort={true}
      search={{
        selector: (cell, rowIndex, cellIndex) => {
          console.log(cellIndex, cell);
          return cellIndex === 0
            ? cell.first_name + " " + cell.last_name
            : cell;
        },
      }}
      resizable
      pagination={{ enabled: false, limit: 50 }}
      language={i18n.language == "gr" ? deDE : undefined}
      style={{
        td: {
          "white-space": "nowrap",
          "text-align": "center",
        },
      }}
    />
  );
};

export default PatientsGrid;




// import React, { useMemo, useEffect, useRef, useState } from "react";
// import { MaterialReactTable } from "material-react-table";
// import { ColorPalette } from "../../components/ColorPalette";
// import { Container, Row, Col, Button } from "reactstrap";
// import { useNavigate } from "react-router-dom";
// import { useTheme, useMediaQuery } from '@mui/material';
// import { useTranslation } from "react-i18next";
// import { FollowUpModalState } from "../../components/FollowUpModal";

// const mapData = (data) => {
//   if (Array.isArray(data)) {
//     return data.map((item) => mapData(item));
//   }
//   const mappedData = {};
//   for (const key in data) {
//     if (Array.isArray(data[key])) {
//       mappedData[key] = data[key].length;
//     } else if (typeof data[key] === "object" && data[key] !== null) {
//       if (
//         data[key].hasOwnProperty("measurements") &&
//         Array.isArray(data[key].measurements) &&
//         data[key].measurements.length > 0
//       ) {
//         const lastMeasurement =
//           data[key].measurements[data[key].measurements.length - 1];
//         mappedData[key] = `${lastMeasurement.value} ${data[key].unit}`;
//       } else {
//         mappedData[key] = mapData(data[key]);
//       }
//     } else {
//       mappedData[key] = data[key];
//     }
//   }
//   return mappedData;
// };

// const PatientsGrid = ({ patients, filter, attributes, setFollowUpModal }) => {
//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
//   const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
//   const isLargeScreen = useMediaQuery(theme.breakpoints.between('md','xl'));
//   const result = mapData(patients);
//   const OnRedOrangeWarningClick = (patientIndex, first) => {
//     localStorage.setItem("patient", JSON.stringify(patients[patientIndex]));
//     setFollowUpModal(
//       first ? FollowUpModalState.First : FollowUpModalState.Second
//     );
//   };

//   // TODO: Prefer different method, maybe redux-persist
//   const routeToPatient = (patientIndex) => {
//     localStorage.setItem("patient", JSON.stringify(patients[patientIndex]));
//     navigate("/patient-dashboard");
//   };

//   const formatCriticalPAM = (args) => {
//         console.log(args);
//         if (args == undefined) return;
//         const { unit, range, measurements } = args;
//         const { systolic, diastolic } = measurements.at(-1);
    
//         if (systolic < range.systolic.min || systolic > range.systolic.max) {
//           return _(
//             <div>
//               <div style={{ color: "red" }}>{systolic}</div>
//               {diastolic} {unit.toLowerCase()}
//             </div>
//           );
//         }
    
//         if (diastolic < range.diastolic.min || diastolic > range.diastolic.max) {
//           return _(
//             <div>
//               {systolic}
//               <div style={{ color: "red" }}>{diastolic}</div> {unit.toLowerCase()}
//             </div>
//           );
//         } else {
//           return _(`${systolic}/${diastolic} ${unit.toLowerCase()}`);
//         }
//       };
    


//   const columns = useMemo(
//     () => [
//       {
//         muiTableHeadCellProps: {
//           align: "center",
//         },
//         muiTableBodyCellProps: {
//           align: "center",
//         },
//         accessorKey: "first_name",
//         header: (
//           <div
//             style={{
//               fontWeight: "bold",
//               fontSize: "16px",
//               color: "black",
//               cursor: "pointer",
//             }}
//             onClick={() => {}}
//           >
//             {t("Name")}
//           </div>
//         ),
//         size: 150,
//         Cell: ({ row, cell }) => (
//           <Button
//             style={{
//               backgroundColor: "transparent",
//               outline: "none",
//               border: "none",
//             }}
//             onClick={() => routeToPatient(row.index)}
//           >
//             {
//               <h4 style={{ color: "black" }}>
//                 {cell.row.original.first_name +
//                   " " +
//                   cell.row.original.last_name}
//               </h4>
//             }
//           </Button>
//         ),
//       },
//       {
//         hideOnSmallScreen: true ,
//         accessorKey: "birthday",
//         header: t("birthday"),
//         size: 50,
//         headerAlign: "center",
//         Cell: ({ cell, row }) => (
//           <b style={{ color: "black" }}>{cell.getValue()}</b>
//         ),
//       },
//       {
//         accessorKey: "warnings",
//         header: t("warnings"),
//         size: 50,
//         headerAlign: "center",
//         Cell: ({ cell, row }) => (
//           <Button
//           outline
//           size="lg"
//           className="rounded-rectangle"
//             style={{
//               backgroundColor: ColorPalette.red,
//               outline: "none",
//               border: "none",
//             }}
//             onClick={() => OnRedOrangeWarningClick(row.index, true)}
//             disabled={cell.getValue()===undefined || cell.getValue()===null || cell.getValue()===0}
//           >
//             {<b style={{ color: "white" }}>{cell.getValue()??0}</b>}
//           </Button>
//         ),
//       },
//       {
//         accessorKey: "device_alerts",
//         header: t("Devices"),
//         size: 50,
//         headerAlign: "center",
//         Cell: ({ cell, row }) => (
//           <Button
//           outline
//           size="lg"
//           className="rounded-rectangle"
//             style={{
//               backgroundColor: ColorPalette.orange,
//               outline: "none",
//               border: "none",
//             }}
//             onClick={() => OnRedOrangeWarningClick(row.index, false)}
//             disabled={cell.getValue()===undefined || cell.getValue()===null || cell.getValue()===0}
//           >
//             {<b style={{ color: "white" }}>{cell.getValue()??0}</b>}
//           </Button>
//         ),
//       },
//       {
//         hideOnSmallScreen: true ,
//         hideOnMediumScreen: true ,
//         accessorKey: "resting_pulse",
//         header: t("resting_pulse"),
//         size: 50,
//         headerAlign: "center",
//         Cell: ({ cell, row }) => (
//           <b style={{ color: "black" }}>{cell.getValue()}</b>
//         ),
//       },
//       {
//         hideOnSmallScreen: true ,
//         hideOnMediumScreen: true ,
//         accessorKey: "blood_pressure",
//         header: t("blood_pressure"),
//         size: 50,
//         headerAlign: "center",
//         Cell: ({ cell, row }) => (
//           <b style={{ color: "black" }}>{cell.getValue()}</b>
//         ),
//       },
//       {
//         hideOnSmallScreen: true ,
//         hideOnMediumScreen: true ,
//         accessorKey: "weight",
//         header: t("weight"),
//         size: 50,
//         headerAlign: "center",
//         Cell: ({ cell, row }) => (
//           <b style={{ color: "black" }}>{cell.getValue()}</b>
//         ),
//       },
//       {
//         hideOnSmallScreen: true ,
//         hideOnMediumScreen: true ,
//         accessorKey: "blood_glucose",
//         header: t("blood_glucose"),
//         size: 50,
//         headerAlign: "center",
//         Cell: ({ cell, row }) => (
//           <b style={{ color: "black" }}>{cell.getValue()}</b>
//         ),
//       },
//       {
//         hideOnSmallScreen: true ,
//         hideOnMediumScreen: true ,
//         accessorKey: "blood_oxygen_saturation",
//         header: t("blood_oxygen_saturation"),
//         size: 50,
//         headerAlign: "center",
//         Cell: ({ cell, row }) => (
//           <b style={{ color: "black" }}>{cell.getValue()}</b>
//         ),
//       },
//       {
//         hideOnSmallScreen: true ,
//         hideOnMediumScreen: true ,
//         accessorKey: "pulmonary_arterial_pressure",
//         header: t("pulmonary_arterial_pressure"),
//         size: 50,
//         headerAlign: "center",
//         Cell: ({ cell, row }) => (
//           <b style={{ color: "black" }}>{
//             cell.getValue()}</b>
//         ),
//       },
//       {
//         hideOnSmallScreen: true ,
//         hideOnMediumScreen: true ,
//         accessorKey: "temperature",
//         header: t("temperature"),
//         size: 50,
//         headerAlign: "center",
//         Cell: ({ cell, row }) => (
//           <b style={{ color: "black" }}>{cell.getValue()}</b>
//         ),
//       },
//       {
//         hideOnSmallScreen: true ,
//         hideOnMediumScreen: true ,
//         accessorKey: "physical_activity",
//         header: t("physical_activity"),
//         size: 50,
//         headerAlign: "center",
//         Cell: ({ cell, row }) => (
//           <b style={{ color: "black" }}>{cell.getValue()}</b>
//         ),
//       },
//       {
//         hideOnSmallScreen: true ,
//         hideOnMediumScreen: true ,
//         accessorKey: "body_fat",
//         header: t("body_fat"),
//         size: 50,
//         headerAlign: "center",
//         Cell: ({ cell, row }) => (
//           <b style={{ color: "black" }}>{cell.getValue()}</b>
//         ),
//       },
//     ],
//     []
//   );

//  const visibleColumns = columns.filter(column => {
//     return !(isSmallScreen && column.hideOnSmallScreen) &&
//            !(isMediumScreen && column.hideOnMediumScreen) &&
//            !(isLargeScreen && column.hideOnMediumScreen);
//   });
//   return (
//     // <React.Fragment>
//     //           {Object.keys(result).length > 0 ? (
//                 <MaterialReactTable
//                   columns={visibleColumns}
//                   data={result}
//                   enablePagination={false}
//                   enableColumnDragging={false}
//                   enableColumnVirtualization
//                   positionToolbarAlertBanner="none"       
//                   initialState={{
//                     density: "compact",
//                   }}
//                   muiTablePaperProps={{
//                     elevation: 0,
//                     sx: {
//                       borderRadius: "0",
//                       border: "0px dashed #e0e0e0",
//                     },
//                   }}

//                 />
//     //           ) : (
//     //             <div>No data available</div>
//     //           )}
//     // </React.Fragment>
//   );
// };
// export default PatientsGrid;
// // enableColumnResizing
