import React from "react";
import { Col, Container, Row } from "reactstrap";
import OnlineVideoPlayer from "./OnlineVideoPlayer"
import { useTranslation } from "react-i18next";

const Education = () => {
    const { t } = useTranslation();
    document.title = "Educational Movies ";
   
   const videoData=[{
    url:'https://player.vimeo.com/video/553927770',
    title:"edtitle1",
    description:"edDescription1"},
    
    {url:'https://player.vimeo.com/video/282731589',
    title:"edtitle2",
    description:"edDescription2"},
    
    {url:'https://player.vimeo.com/video/490495048',
    title:"edtitle3",
    description:"edDescription3"},
     
    {url:'https://player.vimeo.com/video/274049743',
     title:"edtitle4",
     description:"edDescription4"},
     
     {url:'https://player.vimeo.com/video/553927770',
     title:"edtitle5",
     description:"edDescription5"},
     
     {url:'https://player.vimeo.com/video/494990189',
     title:"edtitle6",
     description:"edDescription6"},
     
     {url:'https://player.vimeo.com/video/477361899',
     title:"edtitle7",
     description:"edDescription7"},
      
     {url:'https://player.vimeo.com/video/429316201',
      title:"edtitle8",
      description:"edDescription8"}];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
        <Row>
            <Col xl={6}>
            <OnlineVideoPlayer url={videoData[0].url} title={t(videoData[0].title)} description={t(videoData[0].description)} />
            </Col>
            <Col xl={6}>
            <OnlineVideoPlayer url={videoData[1].url} title={t(videoData[1].title)} description={t(videoData[1].description)} />
            </Col>
            <Col xl={6}>
            <OnlineVideoPlayer url={videoData[2].url} title={t(videoData[2].title)} description={t(videoData[2].description)} />
            </Col>
            <Col xl={6}>
            <OnlineVideoPlayer url={videoData[3].url} title={t(videoData[3].title)} description={t(videoData[3].description)} />
            </Col>
            <Col xl={6}>
            <OnlineVideoPlayer url={videoData[4].url} title={t(videoData[4].title)} description={t(videoData[4].description)} />
            </Col>
            <Col xl={6}>
            <OnlineVideoPlayer url={videoData[5].url} title={t(videoData[5].title)} description={t(videoData[5].description)} />
            </Col>
            <Col xl={6}>
            <OnlineVideoPlayer url={videoData[6].url} title={t(videoData[6].title)} description={t(videoData[6].description)} />
            </Col>
            <Col xl={6}>
            <OnlineVideoPlayer url={videoData[7].url} title={t(videoData[7].title)} description={t(videoData[7].description)} />
            </Col>
        </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Education;
