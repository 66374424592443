import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Button } from 'reactstrap';
import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';

import FollowUpTable from './FollowUpTable';
import ConfirmModal from '../ConfirmModal';
import patientService from "../../services/patients"
import { getLoggedinUser } from '../../helpers/api_helper';


export const FollowUpModalState = Object.freeze({
  Closed: "closed",
  DeviceAlerts: "deviceAlerts",
  GeneralWarnings: "generalWarnings",
  Resolved: "resolved"
})

const stateToTab = (state) => {
  switch (state) {
    case FollowUpModalState.Closed: return "0";
    case FollowUpModalState.DeviceAlerts: return "1";
    case FollowUpModalState.GeneralWarnings: return "2";
    case FollowUpModalState.Resolved: return "3";
  }
}

const FollowUpModal = ({ state, toggle, patients, invalidatePatients }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(stateToTab(state))

  const refGeneralGrid = useRef(null);
  const refDevicesGrid = useRef(null);
  const refPrintableGrid = useRef(null);

  useEffect(() => {
    setTab(stateToTab(state))
  }, [state])

  const handlePrint = useReactToPrint({
    content: () => refPrintableGrid?.current,
  });

  const onSubmit = (event) => {
    event.preventDefault()

    const save = (key, rows) => {
      const row = rows
        .filter((row) => row.getIsSelected())
        .map((row) => row.original)

      if (row.length == 0) return;

      const foundPatient = patients.find(p =>
        row.some(r => p.uuid === r.patientUUID))

      if (!foundPatient) {
        console.warn("Patient not found!")
      }

      const updatedWarnings = foundPatient[key].map(w => {
        if (row.some(r => r.uuid === w.uuid)) {
          return {
            ...w,
            level: "resolved",
            annotation: event.target.annotation.value,
            follow_up_type: event.target.followUpType.value,
            resolvedBy: getLoggedinUser()
          }
        }
        return w
      })

      const updatedPatient = {
        ...foundPatient,
        [key]: updatedWarnings
      }

      patientService
        .update(updatedPatient.uuid, updatedPatient).then(() => {
          if (patients.length == 1) {
            localStorage.setItem("patient", JSON.stringify(updatedPatient));
          }
          invalidatePatients()
        })
        .catch(error => {
          console.warn("Updating patient failed")
        })
    }

    if (state == FollowUpModalState.DeviceAlerts)
      save("device_alerts", refDevicesGrid.current.getCoreRowModel().flatRows)
    else if (state == FollowUpModalState.GeneralWarnings)
      save("warnings", refGeneralGrid.current.getCoreRowModel().flatRows)
  }

  const recordButton = getLoggedinUser().title === "Pfl" || tab !== "3"
  const title = t('Recording Follow Up for') + " "
    + (patients.length > 1 ? t('All Patients') : patients[0].first_name + " " + patients[0].last_name)


  return (
    <>
      <Modal isOpen={state != FollowUpModalState.Closed} className='modal-xl'>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <FollowUpTable
            ref={{ refGeneralGrid, refDevicesGrid, refPrintableGrid }}
            patients={patients}
            tab={tab}
            setTab={setTab}
            onConfirm={toggle}
          />
          {(tab !== "3" && getLoggedinUser().title === "Dr") &&
            <Form id="warning-form" onSubmit={onSubmit}>
              <FormGroup>
                <Label for="followUpType">{t('Follow-Up Type')}</Label>
                <Input type="select" name="followUpType" id="followUpType">
                  <option value="virtual_visit">{t('Virtual Visit')} </option>
                  <option value="phone_call">{t('Phone Call')}</option>
                  <option value="remote_monitoring">{t('Remote Monitoring')}</option>
                  <option value="examine_medical_records">{t('Examine Medical Records')}</option>
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="annotation">{t('Annotation')}</Label>
                <Input type="textarea" name="annotation" id="annotation-text" />
              </FormGroup>
            </Form>
          }
        </ModalBody >

        {getLoggedinUser().title === "Dr" && <ModalFooter>
          <div className="text-end mt-4">
            <ul className="list-inline">
              <li className="list-inline-item">
                <Button onClick={handlePrint}>{t('Print')}</Button>
              </li>
              <li className="list-inline-item">
                {recordButton &&
                  <Button type="submit" color="success" form="warning-form">
                    {t('Record')}
                  </Button>
                }
              </li>
              <li className="list-inline-item">
                <ConfirmModal onConfirm={toggle} />
              </li>
            </ul>
          </div >
        </ModalFooter>}
      </Modal>
    </>
  )
}

export default FollowUpModal