import React from "react";
import {CardTitle, Col, Row, Card, CardHeader, CardBody } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import { useTranslation } from "react-i18next";

const ClinicalNoteCard = ({ isReadOnly, data, columns, handleRowClick }) => {
  const { t } = useTranslation();

  return (
    <Card>
      { isReadOnly?(
      <CardHeader>
        <h5 style={{ marginBottom: "-1px", color: "black" }}>
          Clinical Notes</h5>
      </CardHeader>
      ):(
      <CardTitle>
        <h4>Clinical Notes</h4>
      </CardTitle>
      )}
      <CardBody>
        <Row>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
            {data != null ? (
              <MaterialReactTable
                data={data}
                columns={columns}
                enableColumnDragging={false}
                enableColumnActions={!isReadOnly}
                enableColumnFilters={!isReadOnly}
                enablePagination={!isReadOnly}
                enableSorting={!isReadOnly}
                enableBottomToolbar={!isReadOnly}
                enableTopToolbar={!isReadOnly}
                muiTableBodyRowProps={
                  !isReadOnly
                    ? ({ staticRowIndex }) => ({
                        onClick: () => handleRowClick({ staticRowIndex }),
                        sx: { cursor: "pointer" },
                      })
                    : undefined
                }
              />
            ) : (
              <div></div>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ClinicalNoteCard;
