import React from "react";
import { Container, Row } from "reactstrap";

const FollowUps = () => {
  document.title = "Follow Up";
  // const patient = JSON.parse(localStorage.getItem("patient"));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FollowUps;
