import React,{useState,useEffect} from "react";
import { Container, InputGroup,InputGroupText,Input,ListGroup,ListGroupItem } from "reactstrap";
import SearchIcon from "@mui/icons-material/Search";
import { fakerDE as faker } from '@faker-js/faker';


const generateAddresses = (count) => {
  const addresses = [];
  for (let i = 0; i < count; i++) {
    const address = {
      name:faker.person.fullName(),
      street: faker.address.streetAddress(),
      city: faker.address.city(),
      state: faker.address.state(),
      zipCode: faker.address.zipCode(),
    };
    addresses.push(address);
  }
  return addresses;
};

const SearchAddress = (props) => {
  const {placeholder,count}=props;
  const [originalAddresses] = useState(generateAddresses(count));
  const [searchTerm, setSearchTerm] = useState('');
  const [matchedAddress, setMatchedAddress] = useState(null);

  useEffect(() => {
    const filteredAddresses = originalAddresses.filter(address =>
      address.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      address.street.toLowerCase().includes(searchTerm.toLowerCase()) ||
      address.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
      address.state.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filteredAddresses.length > 0) {
      setMatchedAddress(filteredAddresses[0]);
    } else {
      setMatchedAddress(null);
    }
  }, [searchTerm, originalAddresses]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <InputGroup>
             <InputGroupText>
             <SearchIcon style={{ color: "blue" }} />
             </InputGroupText>
             <Input value={searchTerm} onChange={handleSearch} placeholder={placeholder} />
           </InputGroup>
        {matchedAddress && (
          <ListGroup>
            <ListGroupItem>
              <strong>Name :</strong> {matchedAddress.name}<br />
              <strong>Street:</strong> {matchedAddress.street}<br />
              <strong>City:</strong> {matchedAddress.city}<br />
              <strong>State:</strong> {matchedAddress.state}<br />
              <strong>Zip Code:</strong> {matchedAddress.zipCode}
            </ListGroupItem>
          </ListGroup>
        )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SearchAddress;
