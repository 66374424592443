// Used when patient is null
const SidebarData = [
  {
    label: "TeamDashboard",
    icon: "bx bx-user-circle icon nav-icon",
    url: "/DoctorDashboard",
  },
  {
    label: "patientTable",
    icon: "dripicons-user-group icon nav-icon",
    url: "/PatientsTable"
  },
  {
    label: "Overview",
    isMainMenu: true,
  },
  // {
  //   label: "MedicalTeam",
  //   icon: "dripicons-user-group icon nav-icon",
  //   url: "/medicalTeam",
  // },
  {
    label: "Education",
    icon: "bx bx-chat icon nav-icon",
    url: "/Education",
  },
  // {
  //   label: "Alerts",
  //   icon: "dripicons-warning icon nav-icon",
  //   url: "/alerts",
  // },
  // {
  //   label: "Calendar",
  //   icon: "bx bx-calendar icon nav-icon",
  //   url: "/apps-calendar",
  // }
];

export default SidebarData;

