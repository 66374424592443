// Used when patient is NOT null
const SidebarDataFull = [

  {
    label: "TeamDashboard",
    icon: "bx bx-user-circle icon nav-icon",
    url: "/DoctorDashboard",
  },
  {
    label: "patientTable",
    icon: "dripicons-user-group icon nav-icon",
    url: "/PatientsTable"
  },
  {
    label: "Overview",
    isMainMenu: true,
  },
  {
    label: "PatientDashboard",
    icon: "bx bx-user-circle icon nav-icon",
    url: "/patient-dashboard",
  },
  {
    label: "VitalParameters",
    icon: "bx bx-sort-down icon nav-icon",
    subItem: [
      { sublabel: "Arterial Blood Pressure", link: "/ArterialBloodPressure" },
      // { sublabel: "HeartRate", link: "/HeartRate" },
      { sublabel: "MeanPulmonaryArterialPressure", link: "/MeanPulmonaryArterialPressure" },
      { sublabel: "weight", link: "/Weight" },
      { sublabel: "bodyFat", link: "/BodyFat" },
      { sublabel: "PhysicalActivity", link: "/PhysicalActivity" },
      { sublabel: "resting_pulse", link: "/RestingPulse" },
      { sublabel: "Temperature", link: "/Temperature" },
      { sublabel: "Oxygenation", link: "/OxygenSaturation" },
      { sublabel: "bloodGlucose", link: "/bloodGlucose" },
      { sublabel: "ECG", link: "/ECG" }
    ],
  },
  {
    label: "Information",
    icon: "bx bxs-user-detail icon nav-icon",
    url: "/information"
  },
  {
    label: "MedicalTeam",
    icon: "dripicons-user-group icon nav-icon",
    url: "/medicalTeam",
  },
  {
    label: "Education",
    icon: "bx bx-chat icon nav-icon",
    url: "/Education",
  },
  {
    label: "Alerts",
    icon: "dripicons-warning icon nav-icon",
    url: "/alerts",
  },
  {
    label: "Calendar",
    icon: "bx bx-calendar icon nav-icon",
    url: "/apps-calendar",
  },
  {
    label: "LaboratoryFindings",
    icon: "bx bx-test-tube icon nav-icon",
    url: "/Laboratory"
  },
  {
    label: "Treatment Plans",
    icon: "bx bx-edit-alt icon nav-icon",
    url: "/treatmentPlans",
  },
  {
    label: "Chat",
    icon: "bx bx-user-voice icon nav-icon",
    url: "/Chat",
  },
  {
    label: "ClinicalNote",
    icon: "bx bx-archive icon nav-icon",
    url: "/ClinicalNote",
  },
  {
    label: "Questionnaires",
    icon: "bx bx-file icon nav-icon",
    subItem: [
      { sublabel: "CGI-I", link: "/pages-maintenance" },
      { sublabel: "CGI-S", link: "/pages-maintenance" },
      { sublabel: "COPDAssesmentTest", link: "/pages-maintenance" },
      { sublabel: "FallRiskAssesment", link: "/pages-maintenance" },
      { sublabel: "FollowUpQuestionnaire", link: "/pages-maintenance" },
      { sublabel: "HealthAssesmentComplete", link: "/pages-maintenance" },
      { sublabel: "PAR-Q+", link: "/#" },
      { sublabel: "PatientHealthQuestionnaire", link: "/pages-maintenance" },
    ],
  },
  // {
  //   label: "Follow Ups",
  //   icon: "bx bx-receipt icon nav-icon",
  //   url: "/followUps",
  // },
  {
    label: "Vital Report",
    icon: "bx bx-printer icon nav-icon",
    url: "/reports",
  },
  {
    label: "Devices",
    isMainMenu: true,
    url: "/pages-maintenance",
  },
  {
    label: "Device Dashboard",
    icon: "bx bx-hdd icon nav-icon",
    url: "/DeviceDashboard",
  },
  {
    label: "DeviceReports",
    icon: "bx bx-printer icon nav-icon",
    url: "/MViewFHIR",
  },

];

export default SidebarDataFull;
