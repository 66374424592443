import React from "react";
import { MaterialReactTable } from "material-react-table";
import { Container, Row, Col } from "reactstrap";
import ChartDataChecker from "../../components/Charts/ChartDataChecker";
import { useTranslation } from "react-i18next";
import dateTimeUtils from "../../components/Charts/DateTimeUtils";

const MeanPulmonaryArterialPressure = () => {
  const { t } = useTranslation();
  document.title = t("pulmonary_arterial_pressure");
  const patient = JSON.parse(localStorage.getItem("patient"));

  let data = null;
  let columns = []; // Declare columns variable

  if (
    !patient.pulmonary_arterial_pressure ||
    patient.pulmonary_arterial_pressure.measurements.length === 0
  ) {
    patient.pulmonary_arterial_pressure = null;
  } else {
    if (patient.mean_pressure != null) {
      const measurements1 = patient.pulmonary_arterial_pressure.measurements;
      const measurements2 = patient.mean_pressure.measurements;

      const mergedMeasurements = measurements1.map((measurement1) => {
        const matchingMeasurement = measurements2.find(
          (measurement2) => measurement2.date === measurement1.date
        );

        return {
          date: measurement1.date,
          systolic: measurement1.systolic,
          diastolic: measurement1.diastolic,
          value: matchingMeasurement ? matchingMeasurement.value : null,
        };
      });
      patient.pulmonary_arterial_pressure.measurements = mergedMeasurements;
    }
    data = patient.pulmonary_arterial_pressure.measurements.map(
      ({ date, value, systolic, diastolic }) => ({
        date: dateTimeUtils.formatDateTime(date),
        value,
        systolic,
        diastolic,
        source: patient.blood_oxygen_saturation.source ?? "---",
      })
    );
    columns = [
      { accessorKey: "date", header: "Date", size: "150" },
      { accessorKey: "value", header: "Mean", size: "150" },
      { accessorKey: "systolic", header: "Systolic", size: "150" },
      { accessorKey: "diastolic", header: "Diastolic", size: "150" },
      { accessorKey: "source", header: "Source", size: "150" },
    ];
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
              <ChartDataChecker
                patientData={patient.pulmonary_arterial_pressure ?? null}
                name={t("pulmonary_arterial_pressure")}
                apex3={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
              {data != null ? (
                <MaterialReactTable
                  columns={columns}
                  data={data}
                  enableColumnDragging={false}
                  initialState={{
                    sorting: [
                      {
                        id: "date",
                        desc: true,
                      },
                    ],
                  }}
                />
              ) : (
                <div></div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MeanPulmonaryArterialPressure;
