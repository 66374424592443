import React, { useState, useEffect } from "react";
import { CardTitle } from "reactstrap";

const Clock = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  //   const formattedDate = date.toLocaleString();
  const formattedDate = date.toLocaleTimeString("en-US", {
    weekday: "short",
    day: "2-digit",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const [weekday, month, day, time] = formattedDate.split(" ");
  const trimWeekday = weekday.replace(/,/g, "");
  const trimDay = day.replace(/,/g, "");
  const [hour, minute] = time.split(":");
  let finalFormattedDate = `${trimWeekday} ${trimDay}. ${month}, ${hour}:${minute}`;

  return (
    <React.Fragment>
      <CardTitle style={{ marginLeft: "10px",marginTop:"20px"}}>
        <h4>{finalFormattedDate}</h4>
      </CardTitle>
    </React.Fragment>
  );
};

export default Clock;
