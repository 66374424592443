// import { deDE } from "gridjs/l10n";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, NavLink, TabContent, TabPane, Row } from "reactstrap";
import PrintableGrid from "../PrintableGrid";
import { MaterialReactTable } from "material-react-table";
import ResolvedTable from "./ResolvedTable";
import ResolvedTableNurse from "./ResolvedTableNurse";
import { getLoggedinUser } from "../../helpers/api_helper";

const FollowUpTable = React.forwardRef(
  ({ patients, tab, setTab, nav = true, hasHeader = true, onConfirm }, ref) => {
    const { t, i18n } = useTranslation();
    const { refGeneralGrid, refDevicesGrid, refPrintableGrid } = ref ?? {
      refGeneralGrid: null,
      refDevicesGrid: null,
      refPrintableGrid: null,
    };
    const [rowSelection, setRowSelection] = useState({});

    const formatLevel = (level) => {
      let color = undefined;
      switch (level) {
        case "control":
          color = "blue";
          break;
        case "intermediate":
          color = "orange";
          break;
        case "high":
          color = "red";
          break;
        case "resolved":
          color = "green";
          break;
      }
      return <div style={{ color }}>{t(level)}</div>;
    };

    const columns = [
      {
        accessorKey: "name",
        header: t("Patient"),
        size: "100",
        GroupedCell: ({ cell, row, }) => {
          const levels = row.leafRows.map(r => r.original.level)
          const control = levels.filter(l => l === "control").length
          const intermediate = levels.filter(l => l === "intermediate").length
          const high = levels.filter(l => l === "high").length
          return <>
            {cell.getValue()} (
            <b>{high + intermediate + control}</b>)
            (<b style={{ color: "red" }}>{high}</b>,
            <b style={{ color: "orange" }}>{intermediate}</b>,
            <b style={{ color: "blue" }}>{control}</b>)
          </>
        }
      },
      {
        accessorKey: "type",
        header: t("Warning"),
        size: "100",
        GroupedCell: ({ cell, row, }) => {
          const levels = row.leafRows.map(r => r.original.level)
          const control = levels.filter(l => l === "control").length
          const intermediate = levels.filter(l => l === "intermediate").length
          const high = levels.filter(l => l === "high").length
          return <>
            {cell.getValue()} (
            <b>{high + intermediate + control}</b>)
            (<b style={{ color: "red" }}>{high}</b>,
            <b style={{ color: "orange" }}>{intermediate}</b>,
            <b style={{ color: "blue" }}>{control}</b>)
          </>
        },
        AggregatedCell: ({ cell }) => {
          return !cell.row.getIsExpanded() ? cell.row.original.type : null
        },
      },
      {
        accessorKey: "value",
        header: t("Value"),
        size: "50",
        AggregatedCell: ({ cell }) => {
          return !cell.row.getIsExpanded() ? cell.row.original.value : null
        },
      },
      {
        accessorKey: "level",
        header: t("Level"),
        size: "50",
        Cell: ({ cell }) => formatLevel(cell.getValue()),
        AggregatedCell: ({ cell }) => {
          return !cell.row.getIsExpanded() ? formatLevel(cell.row.original.level) : null
        },
      },
      {
        accessorKey: "date",
        header: t("Date"),
        size: "50",
        AggregatedCell: ({ cell }) => {
          return !cell.row.getIsExpanded() ? cell.row.original.date : null
        },
      },
    ];

    const warnings = [];
    patients.forEach((patient) => {
      if (patient.warnings?.length > 0) {
        patient.warnings
          .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
          .forEach((warning) => {
            try {
              if (warning.level !== "resolved") {
                warnings.push({
                  ...warning,
                  patientUUID: patient.uuid,
                  name: patient.first_name + " " + patient.last_name,
                  value: warning.value + " " + warning.unit.toLowerCase(),
                });
              }
            } catch (e) {
              // TODO: don't ignore
            }
          });
      }
    });

    const deviceAlerts = [];
    patients.forEach((patient) => {
      if (patient.device_alerts?.length > 0) {
        patient.device_alerts
          .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
          .forEach((alert) => {
            try {
              if (alert.level !== "resolved") {
                deviceAlerts.push({
                  ...alert,
                  patientUUID: patient.uuid,
                  name: patient.first_name + " " + patient.last_name,
                  value: alert.value + " " + alert.unit.toLowerCase(),
                });
              }
            } catch (e) {
              // TODO: don't ignore
            }
          });
      }
    });

    const pdfTitle =
      (tab == "1" ? t("General Warnings") : t("device_alerts")) +
      ` ${t("for")} ${t("All Patients")}`;

    return (
      <div>
        {nav && (
          <Nav tabs pills>
            <PrintableGrid
              ref={refPrintableGrid}
              title={pdfTitle}
              columns={columns}
              data={tab == "1" ? deviceAlerts : warnings}
              language={i18n.language == "gr" ? deDE : undefined}
            />
            <NavItem>
              <NavLink
                className={tab == "1" ? "active" : ""}
                onClick={() => setTab("1")}
              >
                {t("device_alerts")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={tab == "2" ? "active" : ""}
                onClick={() => setTab("2")}
              >
                {t("General Warnings")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={tab == "3" ? "active" : ""}
                onClick={() => setTab("3")}
              >
                {t("Resolved Warnings")}
              </NavLink>
            </NavItem>
          </Nav>
        )}
        <TabContent activeTab={tab}>
          <TabPane tabId="1">
            <Row>
              <MaterialReactTable
                positionToolbarAlertBanner="none"
                enableTopToolbar={hasHeader}
                // enableColumnFilters={hasHeader}
                tableInstanceRef={refDevicesGrid}
                columns={columns}
                data={deviceAlerts}
                enableMultiRowSelection={false}
                enableColumnDragging={false}
                // getRowId={(row) => row.uuid}
                enableRowSelection={getLoggedinUser().title === "Dr"}
                onRowSelectionChange={setRowSelection}
                state={{ rowSelection }}
                // enableColumnResizing
                enableGrouping
                // enableStickyHeader
                // enableStickyFooter
                initialState={{
                  // columnVisibility: { uuid: false },
                  density: "compact",
                  expanded: true,
                  pagination: { pageIndex: 0, pageSize: 100 },
                  sorting: [{ id: "name", desc: false }, { id: "date", desc: true }],
                  grouping: patients.length > 1 ? ["name"] : ["type"],
                  columnVisibility: { name: patients.length > 1 }
                }}
                muiTablePaperProps={{
                  elevation: 0, //change the mui box shadow
                  //customize paper styles
                  sx: {
                    borderRadius: "0",
                    border: "1px dashed #e0e0e0",
                  },
                }}
                enableColumnVirtualization
                positionExpandColumn="last"
              />
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <MaterialReactTable
                positionToolbarAlertBanner="none"
                enableTopToolbar={hasHeader}
                tableInstanceRef={refGeneralGrid}
                columns={columns}
                data={warnings}
                enableMultiRowSelection={false}
                enableColumnDragging={false}
                // getRowId={(row) => row.uuid}
                enableRowSelection={getLoggedinUser().title === "Dr"}
                onRowSelectionChange={setRowSelection}
                state={{ rowSelection }}
                enableColumnResizing={false}
                enableGrouping
                enableStickyHeader
                enableStickyFooter
                initialState={{
                  density: "compact",
                  expanded: true,
                  pagination: { pageIndex: 0, pageSize: 20 },
                  sorting: [{ id: "name", desc: false }, { id: "date", desc: true }],
                  grouping: patients.length > 1 ? ["name"] : ["type"],
                  columnVisibility: { name: patients.length > 1 }
                }}
                muiTablePaperProps={{
                  elevation: 0, //change the mui box shadow
                  //customize paper styles
                  sx: {
                    borderRadius: "0",
                    border: "1px dashed #e0e0e0",
                  },
                }}
                enableColumnVirtualization
                positionExpandColumn="last"
              />
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              {getLoggedinUser().title === "Dr" ? (
                <ResolvedTable patients={patients} />
              ) : (
                <ResolvedTableNurse patients={patients} onConfirm={onConfirm} />
              )}
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  }
);

export default FollowUpTable;
