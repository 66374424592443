import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import patientService from "../../services/patients";
import { useTranslation } from "react-i18next";
import NoteModal from "./NoteModal";
import { MaterialReactTable } from "material-react-table";
import ClinicalNoteCard from "../../components/ClinicalNoteCard";
const ClinicalNote = () => {
  const { t } = useTranslation();
  document.title = "Device Dashboard";

  const [currentNote, setCurrentNote] = useState({
    status: null,
    description: null,
    index: null,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const patient = JSON.parse(localStorage.getItem("patient"));
  const authUser = JSON.parse(localStorage.getItem("authUser"));

  const user = authUser.title + " " + authUser.username;

  const handleToggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const saveModal = (note, category, index) => {
    const newData = [...data];
    if (index === null) {
      newData.push({
        description: note,
        status: category,
        registered: user,
        initiated_date: new Date().toLocaleString(),
        last_update: new Date().toLocaleString(),
      });
    } else {
      newData[index] = {
        description: note,
        status: category,
        registered: user,
        initiated_date: newData[index].initiated_date,
        last_update: new Date().toLocaleString(),
      };
    }
    setData(newData);
    setPatient(newData);
    setModalOpen(false);
    setCurrentNote({ status: null, description: null });
  };

  const setPatient = (notes) => {
    const updatedPatient = { ...patient };
    updatedPatient.clinical_notes = notes;

    patientService
      .update(updatedPatient.uuid, updatedPatient)
      .then((returnedPatient) => {
        localStorage.setItem("patient", JSON.stringify(updatedPatient));
        console.log("Patient updated:", returnedPatient);
        setData(notes);
      })
      .catch((error) => {
        console.log("Updating patient failed:", error);
      });
  };

  useEffect(() => {
    const fetchClinicalNotes = async () => {
      try {
        const response = await patientService.getClinicalNotes(patient.uuid);
        setData(response.data);
      } catch (error) {
        console.log("Error fetching clinical notes:", error);
      }
    };

    if (patient) {
      setData(patient.clinical_notes || []);
    } else {
      fetchClinicalNotes();
    }
  }, [patient]);

  const columns = useMemo(
    () => [
      { accessorKey: "description", header: "Description", size: "150" },
      { accessorKey: "status", header: "Status", size: "150" },
      { accessorKey: "registered", header: "Registered", size: "150" },
      { accessorKey: "initiated_date", header: "Initiated Date", size: "150" },
      { accessorKey: "last_update", header: "Last Update", size: "150" },
    ],
    []
  );
  const handleRowClick = (staticRowIndex) => {
    const { status, description } = data[staticRowIndex.staticRowIndex];
    setCurrentNote({
      status,
      description,
      index: staticRowIndex.staticRowIndex,
    });
    setModalOpen(true);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <ClinicalNoteCard data={data} columns={columns} handleRowClick={handleRowClick} />
          </Row>
          <Row>
            <div className="text-end mt-4">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Button color="primary" onClick={handleToggleModal}>
                    {t("Add Note")}
                  </Button>
                </li>
              </ul>
            </div>
            <NoteModal
              isOpen={modalOpen}
              toggleModal={handleToggleModal}
              onSaveForm={saveModal}
              currentNote={currentNote}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClinicalNote;
