import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane,FormGroup,Table,Label,Input,FormText } from 'reactstrap';

const DynamicTabs = ({ pdfData }) => {
  const [activeTab, setActiveTab] = useState(1);

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const renderTabs = (pdfData) => {
    const tabs = [];
    for (let i = 0; i < pdfData.length; i++) {
      const tabNumber = i + 1;
      tabs.push(
        <NavItem key={i}>
          <NavLink
            className={activeTab === tabNumber ? 'active' : ''}
            onClick={() => toggleTab(tabNumber)}
          >
            PDF {tabNumber}
          </NavLink>
        </NavItem>
      );
    }
    return tabs;
  };

  const renderTabContent = (pdfData) => {
    const tabContent = [];
    for (let i = 0; i < pdfData.length; i++) {
      const tabNumber = i + 1;
      tabContent.push(
        <TabPane key={i} tabId={tabNumber}>
          <div style={{ width: '100%', height: '1200px' }}>
            <iframe
              src={pdfData[i]}
              title={`PDF ${tabNumber}`}
              style={{ width: '100%', height: '100%', border: 'none' }}
            />
          </div>
        </TabPane>
      );
    }
    return tabContent;
  };

  return (
    <div>
      <Nav tabs>{renderTabs(pdfData)}</Nav>
      <TabContent activeTab={activeTab}>{renderTabContent(pdfData)}</TabContent>
    </div>
  );
};



function getData (data){
    const dataRead = [];
    const dataList= [];
  
    try {

      for (const item1 of data.entry) {
        const url = item1.fullUrl;
        const resource = item1.resource;
  
        if (resource.resourceType === 'Observation') {
          const basic_code = resource.code;
          const text = basic_code.text;
          const coding = basic_code.coding[0];
          const display = coding.display;
  
          const time = resource.effectiveDateTime;
  
          const components = [];
          if ('component' in resource) {
            for (const item2 of resource.component) {
              let valueDateTime = null;
              if ('valueDateTime' in item2) {
                valueDateTime = item2.valueDateTime;
              }
  
              let value = null;
              if ('valueQuantity' in item2) {
                value = item2.valueQuantity.value;
              }
  
              const coding_componect = item2.code.coding[0];
              const display_componect = coding_componect.display;
  
              components.push({
                timevalue: valueDateTime,
                display: display_componect,
                value: value
              });
            }
          }
  
          const fulldata = {
            text: text,
            time: time,
            display: display,
            component: components
          };
          dataRead.push(fulldata);

        }
  
        if (resource.resourceType === 'DocumentReference') {

          // Do something for DocumentReference
        }
  
        if (resource.resourceType === 'Binary') {
          const pdf = resource.data;

          const pdfBinary = window.atob(pdf);
          const byteArray = new Uint8Array(pdfBinary.length);
          
          for (let i = 0; i < pdfBinary.length; i++) {
            byteArray[i] = pdfBinary.charCodeAt(i);
          }
          
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          
          dataList.push(url)
          // Do something with the binary data
        }
      }
    } catch (error) {
      console.error('Error reading data file:', error);
      alert("Error Data")
    }
  
    return [dataRead,dataList];
  }

function ViewFHIR() {
  const [jsonData, setJsonData] = useState(null);
  const [pdfData, setPDFData] = useState(null);

  const handleFileChange = (event) => {
    
    
    
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      
      setJsonData(null)
      setPDFData(null); 

      try {
        const json = e.target.result;
        const data = JSON.parse(json);
      
        const dataFix=getData(data)
        setJsonData(dataFix[0]); 
  
        if(dataFix.length>0){
           setPDFData(dataFix[1]); 
        }
        else{
          setPDFData(null); 
        }
      
      } catch (error) {
        alert("Error Data FHIR")
      }
  

   
    };

    reader.readAsText(file);
  };

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div>
      


      
     <FormGroup>
        <Label for="exampleFile">
        Import FHIR Patient Case
        </Label>



           

        <Input
          id="exampleFile"
          name="file"
          type="file"
          accept=".*"
          onChange={(e)=>{

            handleFileChange(e)
            setIsChecked(false);
          }
          }
        />
        <FormText>
        {jsonData && <h3>FHIR Data <br/> PDF :  {pdfData.length}  <br/>Records : {jsonData.length-1 } </h3>}

        
        <div className="form-check form-switch mb-3" dir="ltr">
          <input type="checkbox" className="form-check-input" id="customSwitch1" defaultChecked={isChecked} onChange={handleCheckboxChange}  />
          <label className="form-check-label" htmlFor="customSwitch1">Show Document</label>
        </div>

       


        {pdfData && !isChecked && <DynamicTabs pdfData ={pdfData} />}
        </FormText>
      </FormGroup>

  
      <Table  bordered size="sm">
        <thead >
          <tr>
        
            <th>Time</th>
            <th>Text</th>
            <th>Display</th>
            <th>Count</th>
            <th>Components</th>
          </tr>
        </thead>
        <tbody>
      {jsonData && jsonData.map((item1, index) => (      
          <tr scope="row">
       
            <td>{item1.time}</td>
            <td>{item1.text}</td>
            <td>{item1.display}</td>
            <td>{item1.component.length}</td>
            {
            item1.component.map((item2, index) => ( 
              <pre>{JSON.stringify(item2, null, 2)}</pre>
            ))
            }
          </tr>   
      ))}
     </tbody>
     </Table>

      
    </div>
  );
}

export default ViewFHIR;







