import React from "react";
import { Container, Row } from "reactstrap";
import RangesForm from "../Information/RangesForm";
import patientService from "../../services/patients"
import jsonData from "./DeviceAlertsLimits.json"
const Alerts = () => {
  document.title = "Device Dashboard";
  const patient = JSON.parse(localStorage.getItem("patient"));

  const setPatient = (values) => {
    const updatedPatient = { ...patient, ...values }
    patientService
      .update(updatedPatient.uuid, updatedPatient).then(returnedPatient => {
        localStorage.setItem("patient", JSON.stringify(updatedPatient));
        console.log("returned", returnedPatient)
      })
      .catch(error => {
        console.log("Updating patient failed")
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}  >
          <Row >
            <RangesForm title={"General Warnings"} patient={patient} onSubmit={setPatient} />
          </Row>
          <Row >
            <RangesForm title={"Device Alerts"}  patient={jsonData} onSubmit={setPatient} />
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default Alerts;

