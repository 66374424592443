import firebase from "firebase/compat/app";
import { getFirestore } from "firebase/firestore";
import { collection, doc, getDoc } from "firebase/firestore";

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";

const formatTimestamp = (timestamp) => {
  const milliseconds =
    timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000);
  return new Date(milliseconds);
};

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);

      const app = firebase.initializeApp(firebaseConfig);

      const db = getFirestore(app);
      const docRef = doc(db, "client", "infor");

      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          localStorage.setItem("clientId", docSnap.data().clientId);
          localStorage.setItem("clientSecret", docSnap.data().clientSecret);
        }
      });

      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          const collection = firebase.firestore().collection("users");
          const currentUser = firebase.auth().currentUser;
          collection
            .doc(currentUser.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const user = {
                  uid: currentUser.uid,
                  email: currentUser.email,
                  title:
                    doc.data().title.charAt(0).toUpperCase() +
                    doc.data().title.slice(1),
                  username:
                    doc.data().username.charAt(0).toUpperCase() +
                    doc.data().username.slice(1),
                };
                this.setLoggeedInUser(user);
              }
            });
        } else {
          localStorage.removeItem("authUser");
        }
      });
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password, username, title) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (userCredential) => {
            const user = userCredential.user;
            const userDetails = {
              email: user.email,
              title: title,
              username: username,
              createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
              lastLoginTime: firebase.firestore.FieldValue.serverTimestamp(),
            };
            // Add the user details to Firestore
            const collection = firebase.firestore().collection("users");
            collection
              .doc(user.uid)
              .set(userDetails)
              .then(() => {
                resolve(user);
              })
              .catch((error) => {
                reject(this._handleError(error));
              });
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };
  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (user) => {
            resolve(firebase.auth().user);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          const currentUser = firebase.auth().currentUser;

          // Get the user details from Firestore
          const collection = firebase.firestore().collection("users");
          collection
            .doc(currentUser.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const events = doc.data().events;
                events?.forEach((event) => {
                  if(event.date !=null)
                  event.date = formatTimestamp(event.date);
                  if(event.start !=null)
                  event.start = formatTimestamp(event.start);
                  if(event.end !=null)
                  event.end = formatTimestamp(event.end);
                });
                const user = {
                  uid: currentUser.uid,
                  email: currentUser.email,
                  events: events,
                  title:
                    doc.data().title.charAt(0).toUpperCase() +
                    doc.data().title.slice(1),
                  username:
                    doc.data().username.charAt(0).toUpperCase() +
                    doc.data().username.slice(1),
                };
                this.setLoggeedInUser(user);
                resolve(user);
              } else {
                // User document does not exist in Firestore
                reject("User details not found");
              }
            })
            .catch((error) => {
              reject(this._handleError(error));
            });
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  getEventsFromFirebase = () => {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((currentUser) => {
        if (currentUser) {
          const collection = firebase.firestore().collection("users");
          collection
            .doc(currentUser.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const events = doc.data().events;
                events.forEach((event) => {
                  if(event.date !=null)
                  event.date = formatTimestamp(event.date);
                  if(event.start !=null)
                  event.start = formatTimestamp(event.start);
                  if(event.end !=null)
                  event.end = formatTimestamp(event.end);
                });
                resolve(events);
              } else {
                console.log("No events found");
                resolve([]); // Return an empty array when no events are found
              }
            })
            .catch((error) => {
              reject(this._handleError(error));
            });
        } else {
          console.log("No logged-in user found");
          reject("No logged-in user found");
        }
      });
    });
  };

  addNewEventFirebase = (event) => {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((currentUser) => {
        if (currentUser) {
          const collection = firebase.firestore().collection("users");
          collection
            .doc(currentUser.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const events = doc.data().events || [];
                events.push(event);
                collection
                  .doc(currentUser.uid)
                  .update({ events })
                  .then(() => {
                    resolve("Event added successfully");
                  })
                  .catch((error) => {
                    reject(this._handleError(error));
                  });
              } else {
                console.log("User not found");
                reject("User not found");
              }
            })
            .catch((error) => {
              reject(this._handleError(error));
            });
        } else {
          console.log("No logged-in user found");
          reject("No logged-in user found");
        }
      });
    });
  };

  deleteEventFirebase = (eventId) => {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((currentUser) => {
        if (currentUser) {
          const collection = firebase.firestore().collection("users");
          collection
            .doc(currentUser.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const events = doc.data().events || [];
                const updatedEvents = events.filter(
                  (event) => event.id !== eventId
                );
                collection
                  .doc(currentUser.uid)
                  .update({ events: updatedEvents })
                  .then(() => {
                    resolve("Event deleted successfully");
                  })
                  .catch((error) => {
                    reject(this._handleError(error));
                  });
              } else {
                console.log("User not found");
                reject("User not found");
              }
            })
            .catch((error) => {
              reject(this._handleError(error));
            });
        } else {
          console.log("No logged-in user found");
          reject("No logged-in user found");
        }
      });
    });
  };

  updateEventFirebase = (updatedEvent) => {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((currentUser) => {
        if (currentUser) {
          const collection = firebase.firestore().collection("users");
          collection
            .doc(currentUser.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const events = doc.data().events || [];
                const eventIndex = events.findIndex(
                  (event) => event.id === updatedEvent.id
                );

                if (eventIndex !== -1) {
                  events[eventIndex] = updatedEvent;
                  collection
                    .doc(currentUser.uid)
                    .update({ events })
                    .then(() => {
                      resolve("Event updated successfully");
                    })
                    .catch((error) => {
                      reject(this._handleError(error));
                    });
                } else {
                  console.log("Event not found");
                  reject("Event not found");
                }
              } else {
                console.log("User not found");
                reject("User not found");
              }
            })
            .catch((error) => {
              reject(this._handleError(error));
            });
        } else {
          console.log("No logged-in user found");
          reject("No logged-in user found");
        }
      });
    });
  };

  /**
   * Social Login user with given details
   */
  socialLoginUser = (data, type) => {
    let credential = {};
    if (type === "google") {
      credential = firebase.auth.GoogleAuthProvider.credential(
        data.idToken,
        data.token
      );
    } else if (type === "facebook") {
      credential = firebase.auth.FacebookAuthProvider.credential(data.token);
    }
    return new Promise((resolve, reject) => {
      if (credential) {
        firebase
          .auth()
          .signInWithCredential(credential)
          .then((user) => {
            resolve(this.addNewUserToFirestore(user));
          })
          .catch((error) => {
            reject(this._handleError(error));
          });
      } else {
        // reject(this._handleError(error));
      }
    });
  };

  addNewUserToFirestore = (user) => {
    const collection = firebase.firestore().collection("users");
    const { profile } = user.additionalUserInfo;
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp(),
    };
    collection.doc(firebase.auth().currentUser.uid).set(details);
    return { user, details };
  };

  setLoggeedInUser = (user) => {
    localStorage.setItem("authUser", JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return JSON.parse(localStorage.getItem("authUser"));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
