import React from "react";
import ViewFHIR from "./ViewFHIR";
import {Container} from "reactstrap";

const MViewFHIR = () => {

    document.title = "Docs4D RPM Platform";

    return (
        <React.Fragment>

          <div className="page-content">
            <Container fluid={true}>   
               <ViewFHIR></ViewFHIR>
            </Container>
          </div>
        </React.Fragment>
    );
};

export default MViewFHIR;
