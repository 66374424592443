import {
  Card,
  CardBody,
  CardTitle,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Col,
  FormGroup,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import SubmitButtonGroup from "../../components/SubmitButtonGroup";


const CapitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1)

const RangeFormGroup = ({ vitalId, validation }) => {
  const { t } = useTranslation();
  const renderInputs = (values) => {
    const inputs = [];
    for (let key in values) {
      if (key === "min") {
        inputs.push(
          <Col key={`${vitalId}-${key}`} md={6} xl={6}>
            <div className="input-group">
              <span className="input-group-text" >Min</span>
              <Input
                name={`${vitalId}.min`}
                type="number"
                onChange={validation.handleChange}
                value={values[key]}
                invalid={
                  validation.touched[vitalId] && validation.errors[vitalId]?.min
                }
              />
              {validation.touched[vitalId] &&
                validation.errors[vitalId]?.min && (
                  <FormFeedback type="invalid">
                    <div>{validation.errors[vitalId]?.min}</div>
                  </FormFeedback>
                )}
            </div>
          </Col>
        );
      } else if (
        typeof values[key] === "object" &&
        values[key].min !== undefined
      ) {
        const capitalizedKey = CapitalizeFirstLetter(key);
        const label = `Min ${capitalizedKey}`.padEnd(10);
        inputs.push(
          <Col key={`${vitalId}-${key}-min`} md={6} xl={6}>
            <div className="input-group">
              <span className="input-group-text" style={{ width: "7rem" }}>{label}</span>
              <Input
                name={`${vitalId}.${key}.min`}
                type="number"
                onChange={validation.handleChange}
                value={values[key].min}
                invalid={
                  validation.touched[vitalId] &&
                  validation.errors[vitalId]?.[key]?.min
                }
              />
              {validation.touched[vitalId] &&
                validation.errors[vitalId]?.[key]?.min && (
                  <FormFeedback type="invalid">
                    <div>{validation.errors[vitalId]?.[key]?.min}</div>
                  </FormFeedback>
                )}
            </div>
          </Col>
        );
      }

      if (key === "max") {
        inputs.push(
          <Col key={`${vitalId}-${key}`} md={6} xl={6}>
            <div className="input-group">
              <span className="input-group-text">Max</span>
              <Input
                name={`${vitalId}.max`}
                type="number"
                onChange={validation.handleChange}
                value={values[key]}
                invalid={
                  validation.touched[vitalId] && validation.errors[vitalId]?.max
                }
              />
              {validation.touched[vitalId] &&
                validation.errors[vitalId]?.max && (
                  <FormFeedback type="invalid">
                    <div>{validation.errors[vitalId]?.max}</div>
                  </FormFeedback>
                )}
            </div>
          </Col>
        );
      } else if (
        typeof values[key] === "object" &&
        values[key].max !== undefined
      ) {
        const capitalizedKey = CapitalizeFirstLetter(key);
        const label = `Max ${capitalizedKey}`.padEnd(10); // Adjust the desired width as needed
        inputs.push(
          <Col key={`${vitalId}-${key}-max`} md={6} xl={6}>
            <div className="input-group">
              <span className="input-group-text" style={{ width: "7rem" }}>{label}</span>
              <Input
                name={`${vitalId}.${key}.max`}
                type="number"
                onChange={validation.handleChange}
                value={values[key].max}
                invalid={
                  validation.touched[vitalId] &&
                  validation.errors[vitalId]?.[key]?.max
                }
              />
              {validation.touched[vitalId] &&
                validation.errors[vitalId]?.[key]?.max && (
                  <FormFeedback type="invalid">
                    <div>{validation.errors[vitalId]?.[key]?.max}</div>
                  </FormFeedback>
                )}
            </div>
          </Col>
        );
      }
    }
    inputs.sort((a, b) => a.key < b.key ? 1 : -1)
    return inputs
  };

  return (
    <FormGroup>
      <Label for={vitalId}> <b> {t(vitalId)}</b> </Label>
      <Row className="g-0">{renderInputs(validation.values[vitalId])}</Row>
    </FormGroup>
  );
};

const RangesForm = ({ title,patient, onSubmit }) => {
  document.title = "Patient Information";

  const { t } = useTranslation();

  const ranges = Object.entries(patient)
    .map((it) => {
      const [key, value] = it;
      return { id: key, range: value.range ?? undefined };
    })
    .filter(({ range }) => range !== undefined);

  const initialValues = {};
  const validationSchema = {};

  // TODO: maybe useEffect
  ranges.forEach(({ id, range }) => {
    initialValues[id] = range;
    validationSchema[id] = Yup.lazy(value => {
      if ('min' in value && 'max' in value) {
        return Yup.object().shape({
          min: Yup.number().required("Min value is required"),
          max: Yup.number().required("Max value is required")
        })
      }

      const objectKeys = Object.keys(value);

      return Yup.object().shape({
        [objectKeys[0]]: Yup.object().shape({
          min: Yup.number().required(`Nested Min value is required for ${objectKeys[0]}`),
          max: Yup.number().required(`Nested Max value is required for ${objectKeys[0]}`),
        }),
        [objectKeys[1]]: Yup.object().shape({
          min: Yup.number().required(`Nested Min value is required for ${objectKeys[1]}`),
          max: Yup.number().required(`Nested Max value is required for ${objectKeys[1]}`),
        })
      })
    })
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: (values) => {
      const updatedPatient = patient;
      for (const [key, value] of Object.entries(values)) {
        updatedPatient[key].range = value;
      }
      onSubmit(updatedPatient);
    },
  });

  return (
    <Card>
      <CardTitle> <h4> {title??t("Critical Limits")} </h4> </CardTitle>
      <CardBody>
        <Form onSubmit={validation.handleSubmit}>
          <Row>
            {ranges.map((vital) => (
              <Col xl={4} lg={6} key={vital.id}>
                <RangeFormGroup vitalId={vital.id} validation={validation} />
              </Col>
            ))}
          </Row>
          <SubmitButtonGroup onReset={validation.resetForm} />
        </Form>
      </CardBody>
    </Card>
  );
};

export default RangesForm;
