import React, { useState } from "react";
import { Container, Row,Card,CardHeader,CardBody,Col } from "reactstrap";
import PatientFormCard from "./PatientFormCard";
import RangesForm from "./RangesForm";
import patientService from "../../services/patients";
import SelectPatientDropDown from "./SelectPatientDropdown";
import { useTranslation } from "react-i18next";
import SearchAddress from "./SearchAddress";

const Information = () => {
  document.title = "Report ";
  const { t } = useTranslation();
  const [patient, setPatient] = useState(
    JSON.parse(localStorage.getItem("patient"))
  );

  const savePatient = (values) => {
    const updatedPatient = { ...patient, ...values };
    patientService
      .update(updatedPatient.uuid, updatedPatient)
      .then((returnedPatient) => {
        localStorage.setItem("patient", JSON.stringify(updatedPatient));
        setPatient(updatedPatient);
      })
      .catch((error) => {
        console.log("Updating patient failed");
      });
  };

  const setCurrentPatient = (newPatient) => {
    localStorage.setItem("patient", JSON.stringify(newPatient));
    setPatient(newPatient);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <PatientFormCard
              patient={patient}
              onSubmit={savePatient}
              cardTitle={
                <SelectPatientDropDown
                  patient={patient}
                  onSelect={setCurrentPatient}
                />
              }
            />
          </Row>
          <Row  style={{marginTop:"-5px"}}>
            <Col xl={6} md={12}>
              <Card >
                <CardHeader><h5>{t("General Practitioner")}</h5></CardHeader>
                <CardBody style={{marginTop:"-100px"}}>
                  <SearchAddress
                    count={200}
                    placeholder="Search Doctor"
                  ></SearchAddress>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6} md={12}>
              <Card>
                <CardHeader><h5>{t("Pharmacy")}</h5></CardHeader>
                <CardBody style={{marginTop:"-100px"}}>
                  <SearchAddress
                    count={200}
                    placeholder="Search Pharmacy"
                  ></SearchAddress>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Information;
