import React, { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { Container, Row, Col } from "reactstrap";
import ChartDataChecker from "../../components/Charts/ChartDataChecker";
import { useTranslation } from "react-i18next";
import dateTimeUtils from "../../components/Charts/DateTimeUtils";

const BloodGlucose = () => {
  const { t } = useTranslation();
  document.title = t("bloodGlucose");
  const patient = JSON.parse(localStorage.getItem("patient"));

  let data = null;
  if (
    !patient.blood_glucose ||
    patient.blood_glucose.measurements.length === 0
  ) {
    patient.blood_glucose = null;
  } else {
    data = patient.blood_glucose.measurements.map(({ date, value }) => ({
      date : dateTimeUtils.formatDateTime(date),
      value,
      source:patient.blood_glucose.source ?? "---"
    }
    ));
  }
  const columns = useMemo(
    () => [
      { accessorKey: "date", header: "Date", size: "150" },
      { accessorKey: "value", header: "Value", size: "150" },
      { accessorKey: "source", header: "Source", size: "150" },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
              <ChartDataChecker
                patientData={patient.blood_glucose}
                name={t("bloodGlucose")}
                apex1={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
              {data != null ? 
                <MaterialReactTable
                data={data}
                columns={columns}
                enableColumnDragging={false}
                initialState={{
                  sorting: [
                    {
                      id: 'date', 
                      desc: true,
                    }
                  ]}}
                />
              : 
                <div></div>
              }
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BloodGlucose;
