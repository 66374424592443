import React from "react";
import { Container, Row, Col } from "reactstrap";
import LaboratoryFindingsCard from '../../components/LaboratoryFindingsCard'; 


const Laboratory = () => {
  document.title = "Laboratory";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xl={12}>
              <LaboratoryFindingsCard />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Laboratory;
