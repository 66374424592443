import React from "react";
import { Container, Row, Col, Card, CardBody, UncontrolledAlert } from "reactstrap";
import SlideWithControl from "../../components/SlideWithControl";
import { useTranslation } from "react-i18next";

const ECG = () => {
  const { t } = useTranslation();
  document.title = t("ECG");
  const patient = JSON.parse(localStorage.getItem("patient"));
  const showEcg = patient?.id !== "98247"


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
              <Card>
                <CardBody>
                  {showEcg ? <SlideWithControl id="carouselExampleControls" />
                    : <UncontrolledAlert
                      style={{
                        height: "480px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10px",
                        marginBottom: '10px'
                      }}
                      color="info"
                      toggle={false}
                      className="text-center">
                      <i className="mdi mdi-alert-circle-outline d-block display-4 mt-2 mb-2 text-info"></i>
                      <h5 className="text-info h4">{t("attention")}</h5>
                      <p className="mb-0 fs-5"> {t("No")} <span style={{ margin: '0 5px', fontWeight: 'bold' }}>ECG</span> {t("DataIsProvided")}</p>
                    </UncontrolledAlert>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ECG;
