import React from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({series,labels,colors,height}) => {
  
  const data = {
    series: series,
    options: {
      labels: labels??['Resolved','Control', 'Intermediate Risk', 'High Risk'],
      colors: colors??["#33a186","#3980c0",  "#fc931d", "#f34e4e"],
    legend: {
      show:true,
      position: "bottom",
      horizontalAlign: "left",
      floating: false,
      offsetY: 0,
      offsetX: 0,
    }
  }
}
  return (
    <React.Fragment>
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="pie"
        height= {height??"300"}
        className="apex-charts"
      />
    </React.Fragment>
  )
}

export default PieChart;
