import React from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  Button,
  CardTitle,
  Label,
  Col,
  Badge,
} from "reactstrap";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import BatteryCharging60Icon from "@mui/icons-material/BatteryCharging60";
import ChargingStationIcon from "@mui/icons-material/ChargingStation";
import PublicIcon from "@mui/icons-material/Public";
import EventIcon from "@mui/icons-material/Event";
import { useTranslation } from "react-i18next";
import { ColorPalette } from "../../../components/ColorPalette";
import { CreateTitleLabel } from "../../../components/TextInputTemplates";
import { CardHeader } from "@mui/material";
import getDeviceValue from "../getDeviceValue";

const Tab2 = () => {
  const { t } = useTranslation();
  const patient = JSON.parse(localStorage.getItem("patient"));
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row style={{ marginTop: "-80px" }}>
            <Col xxl={6} xl={6} md={6} sm={12} xs={12}>
              <Card style={{ display: "flex", height: "98%",marginLeft:"-10px" }}>
                <CardTitle>
                  <Button style={{ backgroundColor: ColorPalette.blue }}>
                    <EventIcon sx={{ fontSize: 20, color: "white" }} />
                  </Button>
                  <b className="px-2">{t("EVENTS SUMMARY")}</b>
                </CardTitle>
                <CardBody>
                  <Row>
                    <Col xxl={6} xl={6} md={6} sm={12} xs={12}>
                      <CreateTitleLabel
                        name={t("Since Last Reset")}
                        width="200px"
                        value="02 Mar 2017" //
                      ></CreateTitleLabel>
                      <Label className="px-4">
                        {t("No New VTachy episode with therapy")},
                      </Label>
                      <Badge className="fs-5" color="success">
                        OK
                      </Badge>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xxl={6} xl={6} md={6} sm={12} xs={12}>
              <Card style={{ display: "flex", height: "98%" }}>
                <CardTitle>
                  <Button style={{ backgroundColor: ColorPalette.blue }}>
                    <EventIcon sx={{ fontSize: 20, color: "white" }} />
                  </Button>
                  <b className="px-2">{t("Percent Paced")}</b>
                </CardTitle>
                <CardBody>
                  <Row>
                    <Col xxl={6} xl={6} md={6} sm={12} xs={12}>
                      <CreateTitleLabel
                        name={t("Atrium")} //MDC_IDC_STAT_BRADY_RA_PERCENT_PACE
                        //value="12%"
                        width="200px"
                        value={getDeviceValue(
                          "MDC_IDC_STAT_BRADY_RA_PERCENT_PACE"
                        )}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("Right Ventricle")} //MDC_IDC_STAT_BRADY_RV_PERCENT_PACED
                        //value="27%"
                        width="200px"
                        value={getDeviceValue(
                          "MDC_IDC_STAT_BRADY_RV_PERCENT_PACED"
                        )}
                      ></CreateTitleLabel>
                    </Col>
                    <Col xxl={6} xl={6} md={6} sm={12} xs={12}>
                      <CreateTitleLabel
                        name={t("Left Ventricle")} //MDC_IDC_STAT_CRT_LV_PERCENT_PACED
                        width="200px"
                        value={getDeviceValue(
                          "MDC_IDC_STAT_CRT_LV_PERCENT_PACED"
                        )}
                      ></CreateTitleLabel>
                      <CreateTitleLabel
                        name={t("CRT")} //MDC_IDC_STAT_CRT_PERCENT_PACED
                        width="200px"
                        //value="64%"
                        value={getDeviceValue(
                          "MDC_IDC_STAT_CRT_PERCENT_PACEDT"
                        )}
                      ></CreateTitleLabel>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Card >
              <CardTitle>
                <Button style={{ backgroundColor: ColorPalette.blue }}>
                  <PublicIcon sx={{ fontSize: 20, color: "white" }} />
                </Button>
                <b className="px-2">{t("SETTING SUMMARY-GENERAL")}</b>
              </CardTitle>
              <CardBody >
                <Row>
                  <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                    <CreateTitleLabel
                      name={t("Last Interrogation Type:")} //MDC_IDC_SESS_TYPE_PREVIOUS
                      width="200px"
                      value={getDeviceValue("MDC_IDC_SESS_TYPE_PREVIOUS")}
                    ></CreateTitleLabel>
                    <CreateTitleLabel
                      name={t("Previous Interrogation Type:")} //MDC_IDC_SESS_TYPE_PREVIOUS
                      width="200px"
                      value={getDeviceValue("MDC_IDC_SESS_TYPE_PREVIOUS")}
                    ></CreateTitleLabel>
                  </Col>
                  <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                    <CreateTitleLabel
                      name={t("Reprogrammed :")} //MDC_IDC_SESS_REPROGRAMMED_PREVIOUS
                      width="200px"
                      value={getDeviceValue(
                        "MDC_IDC_SESS_REPROGRAMMED_PREVIOUS"
                      )}
                    ></CreateTitleLabel>
                    <CreateTitleLabel
                      name={t("Previous Interrogation Date:")} //MDC_IDC_SESS_DTM_PREVIOUS
                      width="200px"
                      value={getDeviceValue("MDC_IDC_SESS_DTM_PREVIOUS")}
                    ></CreateTitleLabel>
                  </Col>
                  <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                    <CreateTitleLabel
                      name={t("Clinician Name:")} //MDC_IDC_SESS_CLINICIAN_NAME
                      width="200px"
                      value={getDeviceValue("MDC_IDC_SESS_CLINICIAN_NAME")}
                    ></CreateTitleLabel>
                    <CreateTitleLabel
                      name={t("Clinican Contact:")} //MDC_IDC_SESS_CLINICIAN_CONTACT_INFORMATION
                      width="200px"
                      value={getDeviceValue(
                        "MDC_IDC_SESS_CLINICIAN_CONTACT_INFORMATION"
                      )}
                    ></CreateTitleLabel>
                  </Col>
                  <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                    <CreateTitleLabel
                      name={t("Clinic Name:")} //MDC_IDC_SESS_CLINIC_NAME
                      width="200px"
                      value={getDeviceValue("MDC_IDC_SESS_CLINIC_NAME")}
                    ></CreateTitleLabel>
                  </Col>
                </Row>
              </CardBody>

              <CardTitle>
                <Button style={{ backgroundColor: ColorPalette.blue }}>
                  <BatteryCharging60Icon
                    sx={{ fontSize: 20, color: "white" }}
                  />
                </Button>
                <b className="px-2">{t("SETTING SUMMARY-BATTERY")}</b>
              </CardTitle>
              <CardBody>
                <Row>
                  <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                    <CreateTitleLabel
                      name={t("Battery Measurment Date:")} //MDC_IDC_MSMT_BATTERY_DTM
                      width="200px"
                      value={getDeviceValue("MDC_IDC_MSMT_BATTERY_DTM")}
                    ></CreateTitleLabel>
                    <CreateTitleLabel
                      name={t("Battery Voltage:")} //MDC_IDC_MSMT_BATTERY_VOLTAGE
                      width="200px"
                      value={getDeviceValue("MDC_IDC_MSMT_BATTERY_VOLTAGE")}
                    ></CreateTitleLabel>
                  </Col>
                  <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                    <CreateTitleLabel
                      name={t("Battery Status:")} //MDC_IDC_MSMT_BATTERY_STATUS
                      width="200px"
                      value={getDeviceValue("MDC_IDC_MSMT_BATTERY_STATUS")}
                    ></CreateTitleLabel>
                    <CreateTitleLabel
                      name={t("Battery Impedance:")} //MDC_IDC_MSMT_BATTERY_IMPEDANCE
                      width="200px"
                      value={getDeviceValue("MDC_IDC_MSMT_BATTERY_IMPEDANCE")}
                    ></CreateTitleLabel>
                  </Col>
                  <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                    <CreateTitleLabel
                      name={t("Battery Remaining %")} //MDC_IDC_MSMT_BATTERY_REMAINING_PERCENTAGE
                      width="200px"
                      value={getDeviceValue(
                        "MDC_IDC_MSMT_BATTERY_REMAINING_PERCENTAGE"
                      )}
                    ></CreateTitleLabel>
                    <CreateTitleLabel
                      name={t("RRT (ERI) Trigger:")} //MDC_IDC_MSMT_BATTERY_RRT_TRIGGER
                      width="200px"
                      value={getDeviceValue("MDC_IDC_MSMT_BATTERY_RRT_TRIGGER")}
                    ></CreateTitleLabel>
                  </Col>
                  <Col xxl={3} xl={6} md={6} sm={12} xs={12}>
                    <CreateTitleLabel
                      name={t("Battery Remaining Time:")} //MDC_IDC_MSMT_BATTERY_REMAINING_LONGEVITY
                      width="200px"
                      value={getDeviceValue(
                        "MDC_IDC_MSMT_BATTERY_REMAINING_LONGEVITY"
                      )}
                    ></CreateTitleLabel>
                    <CreateTitleLabel
                      name={t("Example Replacable")}
                      width="200px"
                      value={getDeviceValue("MDC_IDC_STAT_CRT_PERCENT_PACEDT")}
                    ></CreateTitleLabel>
                  </Col>
                </Row>
              </CardBody>
              <Row>
                <Col xxl={6} xl={6} md={6} sm={12} xs={12}>
                  <CardTitle>
                    <Button style={{ backgroundColor: ColorPalette.blue }}>
                      <ChargingStationIcon
                        sx={{ fontSize: 20, color: "white" }}
                      />
                    </Button>
                    <b className="px-2">{t("SETTING SUMMARY-CAPACITOR")}</b>
                  </CardTitle>
                  <CardBody>
                    <Row>
                      <Col xxl={6} xl={12} md={12} sm={12} xs={12}>
                        <CreateTitleLabel
                          name={t("Charge Date:")} //MDC_IDC_MSMT_CAP_CHARGE_ENERGY
                          width="200px"
                          value={getDeviceValue(
                            "MDC_IDC_MSMT_CAP_CHARGE_ENERGY"
                          )}
                        ></CreateTitleLabel>
                        <CreateTitleLabel
                          name={t("Charge Energy")} //MDC_IDC_MSMT_CAP_CHARGE_ENERGY
                          width="200px"
                          value={getDeviceValue(
                            "MDC_IDC_MSMT_CAP_CHARGE_ENERGY"
                          )}
                        ></CreateTitleLabel>
                      </Col>
                      <Col xxl={6} xl={12} md={12} sm={12} xs={12}>
                        <CreateTitleLabel
                          name={t("Charge Time:")} //MDC_IDC_MSMT_CAP_CHARGE_TIME
                          width="200px"
                          value={getDeviceValue("MDC_IDC_MSMT_CAP_CHARGE_TIME")}
                        ></CreateTitleLabel>
                        <CreateTitleLabel
                          name={t("Charge Type:")} //MDC_IDC_MSMT_CAP_CHARGE_TYPE
                          width="200px"
                          value={getDeviceValue("MDC_IDC_MSMT_CAP_CHARGE_TYPE")}
                        ></CreateTitleLabel>
                      </Col>
                    </Row>
                  </CardBody>
                </Col>
                <Col xxl={6} xl={6} md={6} sm={12} xs={12}>
                  <CardTitle>
                    <Button style={{ backgroundColor: ColorPalette.blue }}>
                      <DynamicFormIcon sx={{ fontSize: 20, color: "white" }} />
                    </Button>
                    <b className="px-2">{t("SETTING SUMMARY-MAGNET MODE")}</b>
                  </CardTitle>
                  <CardBody>
                    <Row>
                      <Col xxl={6} xl={12} md={12} sm={12} xs={12}>
                        <CreateTitleLabel
                          name={t("Magnet Mode:")} //MDC_IDC_SET_MAGNET_RESP
                          width="180px"
                          value={getDeviceValue("MDC_IDC_SET_MAGNET_RESP")}
                        ></CreateTitleLabel>
                      </Col>
                    </Row>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Tab2;
