import React, { useMemo } from "react";
import {CardTitle, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { MaterialReactTable } from "material-react-table";

const LaboratoryFindingsCard = ({isReadOnly}) => {
  const patient = JSON.parse(localStorage.getItem("patient"));

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 150,
      },
      {
        accessorKey: "value", //access nested data with dot notation
        header: "Value       28.06.2023",
        size: 148,
      },
      {
        accessorKey: "min", //normal accessorKey
        header: "Value   01.04.2023",
        size: 148,
      },
      {
        accessorKey: "max",
        header: "Value 27.02.2023",
        size: 148,
      },
      {
        accessorKey: "date",
        header: "Value 15.01.2023",
        size: 148,
      },
    ],
    []
  );

  const data = useMemo(
    () => [
      {
        name: "Natrium (mmol/L)",
        value: "138,8mmol/L",
        min: "137,2mmol/L",
        max: "136,0mmol/L",
        date: "136,7mmol/L",
      },
      {
        name: "Kalium (mmol/L)",
        value: "4,9mmol/L",
        min: "4,7mmol/L",
        max: "5,1mmol/L",
        date: "4,7mmol/L",
      },
      {
        name: "Kreatinin (µmol/L)",
        value: "1,3µmol/L",
        min: "1,1µmol/L",
        max: "0,9µmol/L",
        date: "0.8µmol/L",
      },
      {
        name: "GFRe (ml/min)",
        value: "29,98ml/min",
        min: "31,23ml/min",
        max: "33,98ml/min",
        date: "35,48ml/min",
      },
      {
        name: "NTproBNP (ng/L)",
        value: "138ng/L",
        min: "129ng/L",
        max: "132ng/L",
        date: "133ng/L",
      },
      {
        name: "BNP (pg/mL)",
        value: "141pg/mL",
        min: "135pg/mL",
        max: "132pg/mL",
        date: "129pg/mL",
      },
      {
        name: "AST (U/L)",
        value: "5,4 U/L",
        min: "5,4 U/L",
        max: "5,4 U/L",
        date: "5,4 U/L",
      },
      {
        name: "ALT (U/L)",
        value: "6,9 U/L",
        min: "6,5 U/L",
        max: "6,2 U/L",
        date: "6,0 U/L",
      },
      {
        name: "LDL (mmol/L)",
        value: "118 mmol/l",
        min: "123 mmol/l",
        max: "128 mmol/l",
        date: "132 mmol/l",
      },
      {
        name: "CK (U/L)",
        value: "148U/L",
        min: "147U/L",
        max: "98U/L",
        date: "65U/L",
      },
      {
        name: "Mikroalbumin (mg/mmol) {creat}",
        value: "-",
        min: "-",
        max: "-",
        date: "-",
      },
      { name: "HbA1C %", value: "-", min: "-", max: "-", date: "-" },
      { name: "INR", value: "2,5", min: "3,1", max: "3,2", date: "3,6" },
    ],
    []
  );
  return (
    <Card>
      {isReadOnly ?(
      <CardHeader>
        <h5 style={{ marginBottom: "-1px", color: "black" }}>
        Laboratory Findings</h5>
      </CardHeader>
      ):(
        <CardTitle><h4> Laboratory Findings</h4></CardTitle>
      )}

      <CardBody style={{ height: "1000px" }}>
        <Row>
          <MaterialReactTable
            columns={columns}
            data={data}
            enableColumnDragging={false}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableSorting={false}
            enableBottomToolbar={false}
            enableTopToolbar={false}
            muiTableBodyRowProps={{ hover: false }}
            muiTableProps={{
              sx: {
                border: "1px solid rgba(81, 81, 81, 1)",
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                border: "1px solid rgba(81, 81, 81, 1)",
              },
            }}
            muiTableBodyCellProps={{
              sx: {
                border: "1px solid rgba(81, 81, 81, 1)",
              },
            }}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                borderRadius: "0",
                border: "0px dashed #e0e0e0",
              },
            }}
          />
        </Row>
      </CardBody>
    </Card>
  );
};

export default LaboratoryFindingsCard;
