import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardHeader,
  Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";

import FollowUpModal, { FollowUpModalState } from "../../components/FollowUpModal";
import patientService from "../../services/patients";
import PatientsGrid from "./PatientsGrid";
import { ColorPalette } from "../../components/ColorPalette";
import CreatePatientButton from "./CreatePatientButton";
import { useQuery, useQueryClient } from "react-query";


//min max heart rate 75,150
//min max weight 40,140
export const NormalDataMap = (jsonData, min, max) => {
  const entries = jsonData.entry || [];

  const measurements = entries
    .reduce((result, entry) => {
      const { resource } = entry;

      if (resource?.valueQuantity) {
        const date = resource.effectiveDateTime;
        const value = resource.valueQuantity.value;
        result.push({ date, value });
      } else if (resource?.component) {
        const date = resource.effectiveDateTime;
        const value = resource.component[0].valueQuantity.value;
        result.push({ date, value });
      }

      return result;
    }, [])
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  const firstEntry = jsonData.entry[0];

  const mappedData = {
    unit: firstEntry.resource.component[0].valueQuantity.unit,
    source: firstEntry.resource.contained[0].deviceName[0].name,
    range: {
      min: min,
      max: max,
    },
    measurements: measurements,
  };

  return mappedData;
};

export function mapBloodPressure(data) {
  // Extract the entries from the data
  const entries = data.entry;

  // Check if entries exist
  if (!entries || entries.length === 0) {
    throw new Error("No entries found in the data.");
  }

  // Get the necessary properties from the first entry
  const component = entries[0].resource?.component;
  const contained = entries[0].resource?.contained;
  const unit = component?.[0]?.valueQuantity?.unit;
  const source = contained?.[0]?.deviceName?.[0]?.name;

  // Check if the necessary properties exist
  if (!unit || !source) {
    throw new Error("Required properties not found in the data.");
  }

  // Map the entries to the desired format
  const mappedData = {
    unit,
    source,
    range: {
      systolic: {
        min: 95,
        max: 120,
      },
      diastolic: {
        min: 70,
        max: 80,
      },
    },
    measurements: entries
      .map((entry) => {
        const observation = entry.resource;
        const date = observation.effectiveDateTime;

        let systolic, diastolic;
        observation.component.forEach((component) => {
          const code = component.code.coding[0].code;
          if (code === "8480-6") {
            systolic = component.valueQuantity.value.toFixed(4);
          } else if (code === "8462-4") {
            diastolic = component.valueQuantity.value.toFixed(4);
          }
        });

        const value = `${systolic}/${diastolic}`;

        return {
          date,
          value,
        };
      })
      .sort((a, b) => new Date(a.date) - new Date(b.date)),
  };

  return mappedData;
}

const PatientsTable = () => {
  const { t } = useTranslation();
  document.title = t("patientTable");
  const [active, setActive] = useState(false);

  const queryClient = useQueryClient()
  const query = useQuery(["patients"], () => patientService.getAll(() => queryClient.invalidateQueries(["patients"])))

  const [filter, setFilter] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [followUpModal, setFollowUpModal] = useState(FollowUpModalState.Closed);
  const [followUpModalAll, setFollowUpModalAll] = useState(FollowUpModalState.Closed);

  const filterData = () => {
    let newAttributes = [];
    let newPatients = [];

    for (const patient of query.data) {
      for (const [key, values] of Object.entries(patient)) {
        if (values.hasOwnProperty("range")) {
          const range = values.range;
          const value = values.measurements.at(-1).value;
          if (value < range.min || value > range.max) {
            newAttributes.push(key);
            newPatients.push(patient);
          }
        }
      }
    }

    setAttributes(newAttributes);
  };

  const resetData = () => {
    setAttributes([]);
  };

  const toggleFilter = () => {
    if (!filter) filterData();
    else resetData();
    setFilter(!filter);
  };

  const toggleFollowUpModal = () => {
    setFollowUpModal(FollowUpModalState.Closed);
  };

  const toggleFollowUpModalAll = () => {
    setFollowUpModalAll(FollowUpModalState.Closed);
  };

  if (query.isLoading) return

  const currentPatient = query.data?.find(p => p?.uuid == JSON.parse(localStorage.getItem("patient"))?.uuid)


  return (
    <React.Fragment>
      {query.data && (
        <div className="page-content">
          {currentPatient &&
            <FollowUpModal
              state={followUpModal}
              toggle={toggleFollowUpModal}
              patients={[currentPatient]}
              invalidatePatients={() => queryClient.invalidateQueries(["patients"])}
            />
          }

          <FollowUpModal
            state={followUpModalAll}
            toggle={toggleFollowUpModalAll}
            patients={query.data}
            invalidatePatients={() => queryClient.invalidateQueries(["patients"])}
          />

          <Container fluid={true}>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <div className="d-flex justify-content-between">

                      <div>

                        <Button
                          className="me-2"
                          style={{
                            backgroundColor: ColorPalette.orange,
                            outline: "none",
                            border: "none",
                          }}
                          onClick={() =>
                            setFollowUpModalAll(FollowUpModalState.DeviceAlerts)
                          }
                        >
                          {t("Device Alerts")}
                        </Button>
                        <Button
                          className="me-2"
                          style={{
                            backgroundColor: ColorPalette.red,
                            outline: "none",
                            border: "none",
                          }}
                          onClick={() =>
                            setFollowUpModalAll(FollowUpModalState.GeneralWarnings)
                          }
                        >
                          {t("General Warnings")}
                        </Button>
                        <CreatePatientButton />
                      </div>
                      <div>
                        <Button
                          style={{
                            outline: "none",
                            border: "none",
                          }}
                          onClick={patientService.resetPatients}
                        >
                          {t("Reset Patients")}
                        </Button>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <PatientsGrid
                      patients={query.data}
                      filter={filter}
                      attributes={attributes}
                      setFollowUpModal={setFollowUpModal}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default PatientsTable;
