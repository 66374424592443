import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MaterialReactTable } from "material-react-table";
import DetailPanel from "./DetailPanel";


const ResolvedTableNurse = React.forwardRef(({ patients, onConfirm }, ref) => {
  const { t } = useTranslation();
  const [rowSelection, setRowSelection] = useState({});

  const columns = [
    {
      accessorKey: "name",
      header: t("Patient"),
      size: "150",
    },
    {
      accessorKey: "type",
      header: t("Warning"),
      size: "75",
      AggregatedCell: ({ cell }) => {
        return !cell.row.getIsExpanded() ? cell.row.original.type : null
      },
    },
    {
      accessorKey: "value", header: t("Value"), size: "50",
      AggregatedCell: ({ cell }) => {
        return !cell.row.getIsExpanded() ? cell.row.original.value : null
      },
    },
    {
      accessorKey: "follow_up_type", header: t("Type"), size: "50",
      AggregatedCell: ({ cell }) => {
        return !cell.row.getIsExpanded() ? cell.row.original.follow_up_type : null
      },
    },
    {
      accessorKey: "date", header: t("Date"), size: "50",
      AggregatedCell: ({ cell }) => {
        return !cell.row.getIsExpanded() ? cell.row.original.date : null
      },
    },
    {
      accessorKey: "resolvedBy",
      accessorFn: (row) => row.resolvedBy ? row.resolvedBy.title + " " + row.resolvedBy.username : undefined,
      header: t("Resolved by"), size: "50",
      AggregatedCell: ({ cell, row }) => {
        return !cell.row.getIsExpanded() ? (cell.row.original.resolvedBy ? cell.row.original.resolvedBy.title + " " + cell.row.original.resolvedBy.username : undefined) : null
      }
    },
  ];

  const warnings = [];
  patients.forEach((patient) => {
    if (patient.warnings?.length > 0) {
      patient.warnings.forEach((warning) => {
        try {
          if (warning.level === "resolved") {
            warnings.push({
              ...warning,
              patientUUID: patient.uuid,
              name: patient.first_name + " " + patient.last_name,
              value: warning.value + " " + warning.unit.toLowerCase(),
            });
          }
        } catch (e) {
          // TODO: don't ignore
        }
      });
    }
  });

  const deviceAlerts = [];
  patients
    .forEach((patient) => {
      if (patient.device_alerts?.length > 0) {
        patient.device_alerts.forEach((alert) => {
          try {
            if (alert.level === "resolved") {
              deviceAlerts.push({
                ...alert,
                patientUUID: patient.uuid,
                name: patient.first_name + " " + patient.last_name,
                value: alert.value + " " + alert.unit.toLowerCase(),
              });
            }
          } catch (e) {
            // TODO: don't ignore
          }
        });
      }
    });

  const allAlerts = [...warnings, ...deviceAlerts].sort((a, b) => Date.parse(b.date) - Date.parse(a.date))

  return (
    <MaterialReactTable
      positionToolbarAlertBanner="none"
      tableInstanceRef={ref}
      columns={columns}
      data={allAlerts}
      enableColumnResizing
      enableGrouping
      enableStickyHeader
      enableStickyFooter
      initialState={{
        density: "compact",
        expanded: false,
        pagination: { pageIndex: 0, pageSize: 20 },
        sorting: [{ id: "name", desc: false }, { id: "date", desc: true }],
        grouping: patients.length > 1 ? ["name"] : ["type"],
        columnVisibility: { name: patients.length > 1 }
      }}
      muiTablePaperProps={{
        elevation: 0, //change the mui box shadow
        //customize paper styles
        sx: {
          borderRadius: "0",
          border: "1px dashed #e0e0e0",
        },
      }}
      enableColumnVirtualization
      muiTableBodyRowProps={({ row }) => ({
        //add onClick to row to select upon clicking anywhere in the row
        onClick: row.getToggleSelectedHandler(),
        sx: { cursor: "pointer" },
      })}
      renderDetailPanel={({ row }) =>
        <DetailPanel minWidth={1000} doctorAnnotation={row.original.annotation} onConfirm={onConfirm} />
      }
      positionExpandColumn="last"
    />
  );
}
);

export default ResolvedTableNurse;
