import React from "react";
import ReactApexChart from "react-apexcharts";
import dateTimeUtils from "./DateTimeUtils";

const ChartApex = ({ inputData, name, height, width }) => {
  const formattedMeasurements = inputData.measurements
    .slice(-width)
    .map(({ date, systolic, diastolic, value }) => ({
      date: dateTimeUtils.formatDateTime(date),
      systolic,
      diastolic,
      mean: value,
    }));

    const series = [
    {
      name: "sys UpLimit",
      data: formattedMeasurements.map(({ date }) => ({
        x: date,
        y: inputData.range.systolic.max,
      })),
    },
    {
      name: "systolic",
      data: formattedMeasurements.map(({ date, systolic }) => ({
        x: date,
        y: systolic,
      })),
    },
    {
      name: "sys LowLimit",
      data: formattedMeasurements.map(({ date }) => ({
        x: date,
        y: inputData.range.systolic.min,
      })),
    },
    {
      name: "mean",
      data: formattedMeasurements.map(({ date,mean }) => ({
        x: date,
        y: mean,
      })),
    },
    {
      name: "dias UpLimit",
      data: formattedMeasurements.map(({ date }) => ({
        x: date,
        y: inputData.range.diastolic.max,
      })),
    },
    {
      name: "diastolic",
      data: formattedMeasurements.map(({ date, diastolic }) => ({
        x: date,
        y: diastolic,
      })),
    },
    {
      name: "dias LowLimit",
      data: formattedMeasurements.map(({ date }) => ({
        x: date,
        y: inputData.range.diastolic.min,
      })),
    },
  ];

  const options = {
    chart: {
      zoom: { enabled: true },
      toolbar: { show: true, floating: true, offsetY: -20, offsetX: -10 },
    },
    colors: [
      "#3980c0",
      "#3980c0",
      "#3980c0",
      "#398000",
      "#fa6374",
      "#fa6374",
      "#fa6374",
    ],
    stroke: {
      width: [3, 3, 3, 3, 3, 3, 3],
      curve: [
        "straight",
        "straight",
        "straight",
        "straight",
        "straight",
        "straight",
        "straight",
      ],
      dashArray: [8, 0, 8, 0, 8, 0, 8],
    },
    // title: { text: name, align: "left", style: { fontWeight: 500 } },
    grid: {
      row: { colors: ["transparent", "transparent"], opacity: 1 },
      borderColor: "#f1f1f1",
    },
    markers: { size: [0, 6, 0, 6, 0, 6, 0] },
    xaxis: {
      type: "numeric",
      labels: { format: "yyyy-MM-dd HH:mm" },
      floating: false,
      tickPlacement: 'between'
    },
    yaxis: {
      title: { text: name + "  [ " + inputData.unit + " ]" },
      min:
        inputData.range.diastolic.min < inputData.range.systolic.min
          ? inputData.range.diastolic.min - 20
          : inputData.range.systolic.min - 20,
      max:
        inputData.range.diastolic.max > inputData.range.systolic.max
          ? inputData.range.diastolic.max + 20
          : inputData.range.systolic.max + 20,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      floating: false,
      offsetY: 0,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: { toolbar: { show: false } },
          legend: { show: false },
        },
      },
    ],
    fill: {
      type: "solid",
      opacity: 1,
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={height ? height : 500}
        className="apex-charts"
      />
    </React.Fragment>
  );
};
export default ChartApex;
