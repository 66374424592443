import React, { useState,useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";

const ModalTemplate = ({ isOpen, toggleModal, onClose,title, children }) => {

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} scrollable={true} size="xl" style={{height: "2000px"}}>
      <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
      <ModalBody>
        {children}
        <ModalFooter>
        <Button className="d-flex justify-content-end ml-auto" onClick={onClose} style={{marginLeft: "auto"}}>Close</Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};

export default ModalTemplate;
