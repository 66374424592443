import axios from "axios";
import {
  getDatabase,
  ref,
  onValue,
} from "firebase/database";

const baseUrl = process.env.REACT_APP_PROXY
console.log({ baseUrl })

export const getDeviceObservations = (id) => {
  return axios.get(baseUrl + "/deviceObservations/" + id)
    .then(({ entry }) => entry.map(({ resource }) => ({
      display: resource.code.coding[0].display,
      value: resource.valueDateTime ?? resource.valueQuantity ?? resource.valueString,
      effectiveDateTime: resource.effectiveDateTime,
      valuePeriod: resource.valuePeriod,

      component: resource.component?.map(c => ({
        display: c.code.coding[0].display,
        value: c.valueDateTime ?? c.valueQuantity ?? c.valueString,
        valuePeriod: c.valuePeriod,
      }))
    })))
}

export const getDevicePdfUrls = async (id) => {
  return axios.get(baseUrl + "/devicePdfUrls/" + id)
}

export const getDevicePdf = (url) => {
  console.log("getting", url)
  return axios.get(baseUrl + "/" + url)
}

export const getIDCO_FHIR_D4D_20230620080929_978118 = (onUpdate) => {
  const database = getDatabase();
  const patientsRef = ref(database, "/IDCO_FHIR_D4D_20230620080929_978118");
  return new Promise((resolve, reject) => {
    // Attach a listener to get the data
    const onDataCallback = (snapshot) => {
      const data = snapshot.val();
      resolve(data);
      onUpdate?.(data);
    };
    const errorCallback = (error) => {
      reject(error);
    };
    onValue(patientsRef, onDataCallback, errorCallback);
  });
};
export const getIDCO_FHIR_D4D_20230620080945_978125 = (onUpdate) => {
  const database = getDatabase();
  const patientsRef = ref(database, "/IDCO_FHIR_D4D_20230620080945_978125");
  return new Promise((resolve, reject) => {
    // Attach a listener to get the data
    const onDataCallback = (snapshot) => {
      const data = snapshot.val();
      // const patientsArray = Object.values(data); // Convert object to array
      resolve(data);
      onUpdate?.(data);
    };
    const errorCallback = (error) => {
      reject(error);
    };
    onValue(patientsRef, onDataCallback, errorCallback);
  });
};
export const getIDCO_FHIR_D4D_20230620081012_978132 = (onUpdate) => {
  const database = getDatabase();
  const patientsRef = ref(database, "/IDCO_FHIR_D4D_20230620081012_978132");
  return new Promise((resolve, reject) => {
    // Attach a listener to get the data
    const onDataCallback = (snapshot) => {
      const data = snapshot.val();
      //  const patientsArray = Object.values(data); // Convert object to array
      resolve(data);
      onUpdate?.(data);
    };
    const errorCallback = (error) => {
      reject(error);
    };
    onValue(patientsRef, onDataCallback, errorCallback);
  });
};

export const getDataByEnding = (ending, onUpdate) => {
  const database = getDatabase();
  const patientsRef = ref(database, `/IDCO_FHIR_D4D_${ending}`);

  return new Promise((resolve, reject) => {
    const onDataCallback = (snapshot) => {
      const data = snapshot.val();
      // const patientsArray = Object.values(data);
      resolve(data);
      onUpdate?.(data);
    };

    const errorCallback = (error) => {
      reject(error);
    };

    onValue(patientsRef, onDataCallback, errorCallback);
  });
};

