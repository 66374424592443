import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useTranslation } from "react-i18next";
import SubmitButtonGroup from "../../components/SubmitButtonGroup";

const MedicalForm = ({ title, patient, onSubmit }) => {
  const { t } = useTranslation();
  document.title = t("Medical Team Info");
  const validation = useFormik({
    initialValues: {
      first_name: patient.first_name,
      last_name: patient.last_name,
      birthday: patient.birthday,
      sex: patient.sex,
      address: patient.address,
      house_number: patient.house_number,
      city: patient.city,
      post_code: patient.post_code,
      telephone: patient.telephone,
      fax: patient.fax,
      email: patient.email,
      health_insurance_id: patient.health_insurance?.id,
      health_insurance_provider: patient.health_insurance?.provider,
      health_insurance_expiration_date:
        patient.health_insurance?.expiration_date,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name required."),
      last_name: Yup.string().required("Last name is required."),
    }),
    onSubmit,
  });

  return (
    <Card>
      <CardTitle><h4>{title}</h4></CardTitle>
      <CardBody>
        <Form onSubmit={validation.handleSubmit}>
          <Row>
            <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
              <Label for="first_name"><b>{t("first_name")}</b></Label>
              <Input
                id="first_name"
                name="first_name"
                type="text"
                onChange={validation.handleChange}
                value={validation.values.first_name}
                invalid={
                  validation.touched.first_name && validation.errors.first_name
                    ? true
                    : false
                }
              />
              {validation.touched.first_name && validation.errors.first_name ? (
                <FormFeedback type="invalid">
                  <div>{validation.errors.first_name}</div>
                </FormFeedback>
              ) : null}
            </Col>

            <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
              <Label for="last_name"><b>{t("family_name")}</b> </Label>
              <Input
                id="last_name"
                name="last_name"
                type="text"
                onChange={validation.handleChange}
                value={validation.values.last_name}
                invalid={
                  validation.touched.last_name && validation.errors.last_name
                    ? true
                    : false
                }
              />
              {validation.touched.last_name && validation.errors.last_name ? (
                <FormFeedback type="invalid">
                  <div>{validation.errors.last_name}</div>
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
              <Label for="birthday"><b>{t("birthday")}</b></Label>
              <Input
                type="date"
                name="birthday"
                id="birthday"
                onChange={validation.handleChange}
                value={validation.values.birthday}
                invalid={
                  validation.touched.birthday && validation.errors.birthday
                    ? true
                    : false
                }
              />
            </Col>

            <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
              <Label for="sex"><b>{t("sex")}</b></Label>
              <Input
                type="select"
                name="sex"
                id="sex"
                onChange={validation.handleChange}
                value={validation.values.sex}
                invalid={
                  validation.touched.sex && validation.errors.sex ? true : false
                }
              >
                <option value="Female">{t("Female")}</option>
                <option value="Male">{t("Male")}</option>
              </Input>
            </Col>
          </Row>
          <Row>
            <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
              <Label for="address"><b>{t("Address")}</b></Label>
              <Input
                id="address"
                name="address"
                type="text"
                onChange={validation.handleChange}
                value={validation.values.address}
                invalid={
                  validation.touched.address && validation.errors.address
                    ? true
                    : false
                }
              />
              {validation.touched.address && validation.errors.address ? (
                <FormFeedback type="invalid">
                  <div>{validation.errors.address}</div>
                </FormFeedback>
              ) : null}
            </Col>
            <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
              <Label for="house_number"><b>{t("HouseNumber")}</b></Label>
              <Input
                id="house_number"
                name="house_number"
                type="text"
                onChange={validation.handleChange}
                value={validation.values.house_number}
                invalid={
                  validation.touched.house_number &&
                  validation.errors.house_number
                    ? true
                    : false
                }
              />
              {validation.touched.house_number &&
              validation.errors.house_number ? (
                <FormFeedback type="invalid">
                  <div>{validation.errors.house_number}</div>
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
              <Label for="city"><b>{t("City")}</b></Label>
              <Input
                id="city"
                name="city"
                type="text"
                onChange={validation.handleChange}
                value={validation.values.city}
                invalid={
                  validation.touched.city && validation.errors.city
                    ? true
                    : false
                }
              />
              {validation.touched.city && validation.errors.city ? (
                <FormFeedback type="invalid">
                  <div>{validation.errors.city}</div>
                </FormFeedback>
              ) : null}
            </Col>
            <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
              <Label for="post_code"><b>{t("PostCode")}</b></Label>
              <Input
                id="post_code"
                name="post_code"
                type="text"
                onChange={validation.handleChange}
                value={validation.values.post_code}
                invalid={
                  validation.touched.post_code && validation.errors.post_code
                    ? true
                    : false
                }
              />
              {validation.touched.post_code && validation.errors.post_code ? (
                <FormFeedback type="invalid">
                  <div>{validation.errors.post_code}</div>
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
              <Label for="telephone"><b>{t("Telephone")}</b></Label>
              <Input
                id="telephone"
                name="telephone"
                type="text"
                onChange={validation.handleChange}
                value={validation.values.telephone}
                invalid={
                  validation.touched.telephone && validation.errors.telephone
                    ? true
                    : false
                }
              />
              {validation.touched.telephone && validation.errors.telephone ? (
                <FormFeedback type="invalid">
                  <div>{validation.errors.telephone}</div>
                </FormFeedback>
              ) : null}
            </Col>
            <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
              <Label for="fax"><b>{t("Fax")}</b></Label>
              <Input
                id="fax"
                name="fax"
                type="text"
                onChange={validation.handleChange}
                value={validation.values.fax}
                invalid={
                  validation.touched.fax && validation.errors.fax ? true : false
                }
              />
              {validation.touched.fax && validation.errors.fax ? (
                <FormFeedback type="invalid">
                  <div>{validation.errors.fax}</div>
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
              <Label for="email"><b>{t("Email")}</b></Label>
              <Input
                id="email"
                name="email"
                type="text"
                onChange={validation.handleChange}
                value={validation.values.email}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  <div>{validation.errors.email}</div>
                </FormFeedback>
              ) : null}
            </Col>
            <Col xxl={6} xl={6} lg={12} md={12} sm={12}></Col>
          </Row>
          <SubmitButtonGroup onReset={validation.resetForm} />
        </Form>
      </CardBody>
    </Card>
  );
};

export default MedicalForm;
