import React from "react";
import { Navigate } from "react-router-dom";
import firebase from "firebase/compat/app";

// DashBoard
import DeviceDashboard from "../Pages/DeviceDashboard/Index";
import PatientDashboard from "../Pages/PatientDashboard/Index";
import MViewFHIR from"../Pages/ViewFHIR/index";
import DoctorDashboard from"../Pages/DoctorDashboard/Index";
import Alerts from"../Pages/Alerts/Index";
import MedicalTeam from"../Pages/MedicalTeam/Index";
import Laboratory from"../Pages/Laboratory/Index";
import FollowUps from"../Pages/FollowUps/Index";
import ClinicalNote from"../Pages/ClinicalNote/Index";
import TreatmentPlans from "../Pages/TreatmentPlans/Index"
import Chat from "../Pages/Chat/index"

//Vitals
import {
  ArterialBloodPressure ,
  MeanPulmonaryArterialPressure,
  Weight,
  PhysicalActivity,
  RestingPulse,
  Temperature,
  OxygenSaturation,
  BloodGlucose,
  ECG,
  BodyFat
}from "../Pages/VitalParameters/Index";

//Education
import Education from "../Pages/Education/Index";

//Authentication
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
// import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";
// Calendar
import Calendar from "../Pages/Calendar/index";
import MaintenancePage from '../Pages/Utility/pages-maintenance';

import PatientsTable from '../Pages/PatientsTable/PatientsTable';

// Inner Authentication Pages
import InnerLogin from "../Pages/InnerAuthPages/login";
import InnerRegister from "../Pages/InnerAuthPages/Register";
import RecoverPassword from "../Pages/InnerAuthPages/RecoverPassword";
import LockScreen from "../Pages/InnerAuthPages/LockScreen";
import InnerLogout from "../Pages/InnerAuthPages/Logout";
import ConfirmMail from "../Pages/InnerAuthPages/ConfirmMail";
import EmailVerification from "../Pages/InnerAuthPages/EmailVerification";
import TwoStepVerification from "../Pages/InnerAuthPages/TwoStepVerification";
import Reports from "../Pages/Reports/Index";
import Information from "../Pages/Information/Index";
import Reminders from "../Pages/Reminders/index"

const authProtectedRoutes = [
  
  
  { path: "/PatientsTable", component: <PatientsTable /> },
  { path: "/Chat", component: <Chat /> },

  // dashboard
  { path: "/DeviceDashboard", component: <DeviceDashboard /> },
  { path: "/patient-dashboard", component: <PatientDashboard /> },
  { path: "/MViewFHIR", component: <MViewFHIR /> },
  { path: "/DoctorDashboard", component: <DoctorDashboard /> },
  //Vital
  {path: "/ArterialBloodPressure", component:<ArterialBloodPressure/>},
  {path: "/RestingPulse", component:<RestingPulse/>},
  {path: "/MeanPulmonaryArterialPressure", component:<MeanPulmonaryArterialPressure/>},
  {path: "/Weight", component:<Weight/>},
  {path: "/PhysicalActivity", component:<PhysicalActivity/>},
  {path: "/Temperature", component:<Temperature/>},
  {path: "/OxygenSaturation", component:<OxygenSaturation/>},
  {path: "/BloodGlucose", component:<BloodGlucose/>},
  {path: "/ECG", component:<ECG/>},
  {path: "/BodyFat", component:<BodyFat/>},
  {path: "/Education", component:<Education/>},
  // Profile
  { path: "/userprofile", component: <UserProfile /> },
  // Calendar
  { path: "/apps-calendar", component: <Calendar /> },
  { path: "/Reminders", component: <Reminders /> },
  { path: "/information", component: <Information /> },
  { path: "/reports", component: <Reports /> },
  { path: "/alerts", component: <Alerts /> },
  { path: "/followUps", component: <FollowUps /> },
  { path: "/medicalTeam", component: <MedicalTeam /> },
  { path: "/Laboratory", component: <Laboratory /> },
  { path: "/ClinicalNote", component: <ClinicalNote /> },
  { path: "/TreatmentPlans", component: <TreatmentPlans /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/DoctorDashboard" />,
  }
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  // { path: "/register", component: <Register /> },
  // Inner Auth Pages  
  { path: "/auth-login", component: <InnerLogin /> },
  { path: "/auth-register", component: <InnerRegister /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/auth-logout", component: <InnerLogout /> },
  { path: "/auth-confirm-mail", component: <ConfirmMail /> },
  { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-two-step-verification", component: <TwoStepVerification /> },

  // Utility Pages
  { path: "/pages-maintenance", component: <MaintenancePage /> }
];

export { authProtectedRoutes, publicRoutes };
