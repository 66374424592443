import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ series, colors, height,yLabel }) => {
  const data = {
    series: series,
    options: {
      chart: {
        type: 'bar',
        zoom: { enabled: false },
        toolbar: { show: false, floating: true, offsetY: -20, offsetX: -10 },
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        show: true,
        title: {text: yLabel ?? "Y Axis"},
      },
      colors: colors ?? ["#33a186", "#3980c0"],
    legend: {
      show:true,
      position: "bottom",
      horizontalAlign: "left",
      floating: false,
      offsetY: 0,
      offsetX: 0,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '100%',
        endingShape: 'rounded'
      },
    },
      stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
  }
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="bar"
        height={height ?? "300"}
        width="100%"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default BarChart;
