import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";

const NoteModal = ({ isOpen, toggleModal, onSaveForm, currentNote }) => {
  const [inputValue, setInputValue] = useState(
    currentNote.description || ""
  );
  const [selectedCategory, setSelectedCategory] = useState(
    currentNote.status || ""
  );
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (
      isOpen &&
      currentNote.description !== null &&
      currentNote.status !== null
    ) {
      setInputValue(currentNote.description);
      setSelectedCategory(currentNote.status);
    }else if(isOpen) {
      resetModal();
    }
  }, [isOpen, currentNote]);

  const resetModal = () => {
    setInputValue("");
    setSelectedCategory("");
    setShowWarning(false);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setShowWarning(false);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setShowWarning(false);
  };

  const handleSaveChanges = () => {
    if (inputValue.trim() === "" || selectedCategory === "") {
      setShowWarning(true);
      return;
    }

    onSaveForm(inputValue, selectedCategory, currentNote.index);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size="lg">
      <ModalHeader toggle={toggleModal}>Clinical Note</ModalHeader>
      <ModalBody>
        <Label for="text">Please enter the note:</Label>
        <Input
          type="text"
          value= {inputValue}
          onChange={handleInputChange}
          placeholder="Input"
          style={{ height: "100px", marginBottom: "10px" }}
        />
        {showWarning && inputValue.trim() === "" && (
          <div style={{ color: "red" }}>
            Please enter a value for the input field.
          </div>
        )}
        <hr />
        <Label for="categorySelect">Category</Label>
        <Input
          type="select"
          name="category"
          onChange={handleCategoryChange}
          value= {selectedCategory}
          style={{ marginBottom: "10px" }}
        >
          <option value="">--Select--</option>
          <option value="Initiated">Initiated</option>
          <option value="In Progress">In Progress</option>
          <option value="Complete">Complete</option>
        </Input>
        {showWarning && selectedCategory === "" && (
          <div style={{ color: "red" }}>Please select a category.</div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveChanges}>
          Save Changes
        </Button>
        <Button color="secondary" onClick={toggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NoteModal;
