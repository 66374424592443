import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

const OnlineVideoPlayer = ({ url,title,description }) => {
  return (
    <Card>
      <CardHeader>
        <h4 className="card-title">{title?title:"Educational Video"}</h4>
        <p className="card-title-desc">{description?description:"This video is really useful, isn't it?"}</p>
      </CardHeader>
      <CardBody>
        <div className="ratio ratio-16x9">
          <iframe src={url} title="YouTube video" allowFullScreen></iframe>
        </div>
      </CardBody>
    </Card>
  );
};

export default OnlineVideoPlayer;