import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Col,
  Row,
  CardBody,
  Card,
  Container,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { ColorPalette } from "../../components/ColorPalette";
import { Mail, Margin } from "@mui/icons-material";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import deviceData from "./Device.json";
import FollowUpTable from "../../components/FollowUpModal/FollowUpTable";
import { MaterialReactTable } from "material-react-table";
import classnames from "classnames";
import { CreateTitleLabel } from "../../components/TextInputTemplates";
import getDeviceValue from "./getDeviceValue";

const PatientInfoCard = ({ patient, handleFtechPdf,handleAction }) => {
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const patientInfo = {
    name: patient.first_name + " " + patient.last_name,
    birthdate: patient.birthday,
  };
  const { t } = useTranslation();
  const [selectDevice, setSelectDivcice] = useState();
  const listModus = ["ICD CRT-D", "CRT-D", "DDDRV", "CRT-D VR"];

  const columns = useMemo(
    () => [
      { accessorKey: "description", header: "Description", size: "150" },
      { accessorKey: "status", header: "Status", size: "200" },
      { accessorKey: "registered", header: "Registered", size: "200" },
      { accessorKey: "initiated_date", header: "Initiated Date", size: "200" },
      { accessorKey: "last_update", header: "Last Update", size: "200" },
    ],
    []
  );
  const data = patient.actions ?? [];
  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col xl={4} md={4}>
            <Card>
              <CardBody style={{ height: "300px" }}>
                <Row
                  className="align-items-center"
                  style={{ marginTop: "-5px", marginBottom: "-20px" }}
                >
                    <Col className="d-flex gap-1">
                      <Button style={{ backgroundColor: ColorPalette.red }}>
                        <BloodtypeIcon sx={{ fontSize: 25, color: "white" }} />
                      </Button>

                      <Button style={{ backgroundColor: ColorPalette.green }}>
                        <Mail sx={{ fontSize: 25, color: "white" }} />
                      </Button>

                      <Input
                        type="text"
                        readOnly
                        value={
                          patientInfo.name + "  |  " + patientInfo.birthdate
                        }
                        style={{ textAlign: "center", fontSize: "20px" }}
                        key="patient-info"
                      />
                    </Col>
                </Row>
                <Row
                  className="align-items-center"
                  style={{ marginTop: "25px" }}
                >
                  <Col className="d-flex gap-1">
                    <Button
                      onClick={handleFtechPdf}
                      style={{ backgroundColor: ColorPalette.blue , marginBottom: "7.5px" }}
                    >
                      <MonitorHeartIcon sx={{ fontSize: 25, color: "white" }} />
                    </Button>
                    <Button
                      onClick={handleAction}
                      style={{ backgroundColor: ColorPalette.blue , marginBottom: "7.5px" }}
                    >
                      <EventRepeatIcon sx={{ fontSize: 25, color: "white" }} />
                    </Button>
                    <div style={{ paddingTop: "1px" }}>
                    <CreateTitleLabel 
                      name={t("Device")}
                      width="100px"
                      value={
                        getDeviceValue("MDC_IDC_DEV_MODEL") +
                        " " +
                        getDeviceValue("MDC_IDC_DEV_SERIAL")
                      }
                    ></CreateTitleLabel>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Col xs={12} sm={12}>
                    <CreateTitleLabel
                      name={t("RA Lead")}
                      width="200px"
                      value={
                        getDeviceValue("MDC_IDC_LEAD_MODEL") +
                        " " +
                        "86435186414"
                      }
                    ></CreateTitleLabel>
                    <CreateTitleLabel
                      name={t("RV Lead")}
                      width="200px"
                      value={
                        getDeviceValue("MDC_IDC_LEAD_MODEL") +
                        " " +
                        "5646813516115"
                      }
                    ></CreateTitleLabel>
                    <CreateTitleLabel
                      name={t("LV Lead")}
                      width="200px"
                      value={"CaptureFix ML" + " " + "164646846846"}
                    ></CreateTitleLabel>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl={8} md={8}>
            <Card>
              <CardBody style={{ height: "300px" }}>
                <Nav
                  tabs
                  className="nav-tabs-custom nav-justified"
                  style={{ marginTop: "-20px" }}
                >
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        {t("Device Alerts")}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        {t("Actions Log")}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  activeTab={customActiveTab}
                  className="p-1 text-muted"
                >
                  <TabPane tabId="1" id="Device Alerts">
                    <FollowUpTable
                      hasHeader={false}
                      nav={false}
                      patients={[patient]}
                      tab={"1"}
                      style={{ marginTop: "50px" }}
                    />
                  </TabPane>
                  <TabPane tabId="2" id="Actions Log">
                    <MaterialReactTable
                      enableTopToolbar={false}
                      positionToolbarAlertBanner="none"
                      data={data}
                      columns={columns}
                      enableColumnDragging={false}
                      manualPagination
                      initialState={{
                        pagination: { pageSize: 4, pageIndex: 1 },
                        // columnVisibility: { uuid: false },
                        density: "compact",
                        grouping: ["name"],
                      }}
                      muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                          borderRadius: "0",
                          border: "0px dashed #e0e0e0",
                        },
                      }}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default PatientInfoCard;
