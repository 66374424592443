import React from "react";
import ReactApexChart from "react-apexcharts";

const RadialChart = ({series,xOffset}) => {
  const data = {
    series: series,
    options: {
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '30%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                const total = series.reduce((sum, value) => sum + value, 0);
                return total;
              },
            },
          },
        },
      },
      labels: ['Resolved','Control', 'Intermediate Risk', 'High Risk'],
      colors: ["#33a186","#3980c0",  "#fc931d", "#f34e4e"],
      legend: {
        show: true,
        floating: true,
        fontSize: '16px',
        position: 'left',
        offsetX: xOffset ?? 10,
        offsetY: 5,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0
        },
        formatter: function(seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
        },
        itemMargin: {
          vertical: 3
        }
      },
    },
    responsive: [{
      breakpoint: 100,
      options: {
        legend: {
            show: false
        }
      }
    }]
  }
  return (
    <React.Fragment>
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="radialBar"
        height="390"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

export default RadialChart;
