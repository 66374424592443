import React from "react";
import { UncontrolledCarousel } from "reactstrap";

// Carousel images
import img1 from "../assets/images/ECG.png";
import img2 from "../assets/images/ECG1.png";
import img3 from "../assets/images/ECG2.jpg";
import img4 from "../assets/images/ECG3.png";

const SlideWithControl = () => {
  return (
    <React.Fragment>
      <UncontrolledCarousel
        interval={false}
        indicators={false}
        items={[
          {
            altText: " ",
            caption: " ",
            key: 1,
            src: img1,
          },
          {
            altText: " ",
            caption: " ",
            key: 2,
            src: img2,
          },
          {
            altText: " ",
            caption: " ",
            key: 3,
            src: img3,
          },
          {
            altText: " ",
            caption: " ",
            key: 4,
            src: img4,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default SlideWithControl;
