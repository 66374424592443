import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import ConfirmModal from "../ConfirmModal";

const DetailPanel = ({ ecg, doctorAnnotation, onConfirm, minWidth }) => {
  const { t } = useTranslation();

  return (
    <Container style={{ minWidth: minWidth }}>
      <Row>
        <Col xs="6">
          {ecg ? (
            <img
              src={ecg}
              style={{
                height: "230px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "20px",
              }}
            />
          ) : (
            <Alert
              style={{
                height: "200px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "20px",
              }}
              color="info"
              className="text-center"
            >
              <i className="mdi mdi-alert-circle-outline d-block display-4 mt-2 mb-2 text-info"></i>
              <h5 className="text-info h4">{t("attention")}</h5>
              <p className="mb-0 fs-5">
                {t("No")}
                <span style={{ margin: "0 5px", fontWeight: "bold" }}>
                  {name}
                </span>
                {t("DataIsProvided")}
              </p>
            </Alert>
          )}
        </Col>

        <Col xs="6">
          <Form>
            <Row>
              <FormGroup>
                <Label readOnly for="doctor-annotation">
                  {t("Doctor Annotation")}
                </Label>
                <Input
                  value={doctorAnnotation}
                  type="textarea"
                  name="doctor-annotation"
                  id="annotation-text"
                />
              </FormGroup>
              <FormGroup>
                <Label for="nurse-annotation">{t("Nurse Annotation")}</Label>
                <Input
                  type="textarea"
                  name="nurse-annotation"
                  id="annotation-text"
                />
              </FormGroup>
            </Row>
            <Row>
              <div className="text-end mt-4">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Button type="submit" color="success" form="warning-form">
                      {t("Record")}
                    </Button>
                  </li>
                  <li className="list-inline-item">
                    <ConfirmModal onConfirm={onConfirm} />
                  </li>
                </ul>
              </div>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
export default DetailPanel;
