import React, { useEffect, useState } from "react";
import { Grid } from "gridjs-react"
import { Card, CardBody, CardText, CardTitle } from "reactstrap";

import { chunk } from 'lodash'

const PrintableGrid = React.forwardRef(({ title, columns, data, language }, ref) => {

  const chunks = chunk(data, 15)

  const grids = chunks.map((chunk, index) =>
    <Grid
      key={index}
      columns={columns}
      data={chunk}
      language={language}
    />)

  return (
    <div style={{ display: "none" }}>
      <div ref={ref}>
        <Card >
          <CardBody>
            {title && <CardTitle>{title}</CardTitle>}
            <CardText>
              {grids}
            </CardText>
          </CardBody>
        </Card>
      </div>
    </div>
  )
})

export default PrintableGrid