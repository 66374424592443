import PropTypes from 'prop-types';
import React from "react";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

const DeleteModal = ({ show, onDeleteClick, onCloseClick, title, message }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "9em", color: "orange" }}
              />
              <h2>{title ?? t("modalCalQ")}</h2>
              <h4>{message ?? t("modalCalH")}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <Button
                color="secondary"
                onClick={onCloseClick}
                className="ms-2"

              >
                {t("Cancel")}
              </Button>
              <Button
                color='danger'
                onClick={onDeleteClick}
                className="ms-2"
              >
                {t("Delete")}
              </Button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default DeleteModal;
