import React, { useEffect, useState, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { FaFilePdf } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Grid, _ } from "gridjs-react";
import LaboratoryFindingsCard from "../../components/LaboratoryFindingsCard";
import ClinicalNoteCard from "../../components/ClinicalNoteCard";
import TreatmentPlansCard from "../../components/TreatmentPlansCard";

import ChartDataChecker from "../../components/Charts/ChartDataChecker";

const PdfReports = React.forwardRef(({ patient, handlePrint }, ref) => {
  const { t } = useTranslation();
  const [clinicalNoteData, setClinicalNoteData] = useState([]);

  const title = `${patient.first_name} ${patient.last_name}`;

  const chartProps = [
    {
      patientData: patient.blood_pressure ?? "",
      name: t("ArterialBloodPressure"),
    },
    {
      patientData: patient.pulmonary_arterial_pressure ?? "",
      name: t("pulmonary_arterial_pressure"),
      apex3: true,
    },
    { patientData: patient.weight ?? "", name: t("weight"), apex1: true },
    { patientData: patient.body_fat ?? "", name: t("BodyFat"), apex1: true },
    {
      patientData: patient.physical_activity ?? "",
      name: t("PhysicalActivity"),
      apex1: true,
    },
    {
      patientData: patient.resting_pulse ?? "",
      name: t("RestingPulse"),
      apex1: true,
    },
    {
      patientData: patient.temperature ?? "",
      name: t("Temperature"),
      apex1: true,
    },
    {
      patientData: patient.blood_oxygen_saturation ?? "",
      name: t("Oxygenation"),
      apex1: true,
    },
    {
      patientData: patient.blood_glucose ?? "",
      name: t("bloodGlucose"),
      apex1: true,
    },
  ];

  const columns = [
    {
      id: "name",
      name: t("Name"),
      formatter: (cell) =>
        _(
          <div style={{ width: 150 }} className="text-start">
            {t(cell)}
          </div>
        ),
      width: 100,
    },
    {
      id: "latest",
      name: _(<div className="text-center">{t("Latest")}</div>),
      formatter: (cell) => cell?.value ?? "",
      width: 120,
    },
    {
      id: "latest",
      name: _(<div className="text-center">{t("Date")}</div>),
      formatter: (cell) => (cell ? `(${cell?.date})` : ""),
      width: 120,
    },
    {
      id: "min",
      name: _(<div className="text-center">{t("Min")}</div>),
      width: 70,
    },
    {
      id: "max",
      name: _(<div className="text-center">{t("Max")}</div>),
      width: 70,
    },
    {
      id: "average",
      name: _(<div className="text-center">{t("Average")}</div>),
      width: 100,
    },
    {
      id: "occurrences",
      name: _(<div className="text-center">{t("Occurrences")}</div>),
      width: 110,
    },
  ];

  const vitals = [
    "blood_pressure_sys",
    "blood_pressure_dia",
    "pulmonary_arterial_pressure_sys",
    "pulmonary_arterial_pressure_dia",
    "weight",
    "body_fat",
    "physical_activity",
    "resting_pulse",
    "temperature",
    "blood_oxygen_saturation",
    "blood_glucose",
  ];

  const patientProcessed = patient;

  // prepare blood pressure
  patientProcessed.blood_pressure_sys = {};
  patientProcessed.blood_pressure_sys.measurements = [];
  patientProcessed.blood_pressure_dia = {};
  patientProcessed.blood_pressure_dia.measurements = [];

  patient["blood_pressure"].measurements.forEach((it) => {
    const values = it.value.split("/");
    patient.blood_pressure_sys.measurements.push({
      value: parseInt(values[0]),
      date: it.date,
    });
    patient.blood_pressure_dia.measurements.push({
      value: parseInt(values[1]),
      date: it.date,
    });
  });

  // prepare pulmonary_arterial_pressure
  patientProcessed.pulmonary_arterial_pressure_sys = {};
  patientProcessed.pulmonary_arterial_pressure_sys.measurements = [];
  patientProcessed.pulmonary_arterial_pressure_dia = {};
  patientProcessed.pulmonary_arterial_pressure_dia.measurements = [];

  patient["pulmonary_arterial_pressure"]?.measurements.forEach((it) => {
    patient.pulmonary_arterial_pressure_sys.measurements.push({
      value: it.systolic,
      date: it.date,
    });
    patient.pulmonary_arterial_pressure_dia.measurements.push({
      value: it.diastolic,
      date: it.date,
    });
  });

  const data = vitals.map((vital) => {
    const measurements = patient[vital]?.measurements;
    if (!measurements) return { name: vital };
    const average =
      measurements.reduce((acc, it) => acc + it.value, 0) / measurements.length;
    return {
      name: vital,
      latest: measurements?.at(-1),
      min: Math.min(...measurements.map((it) => it.value)),
      max: Math.max(...measurements.map((it) => it.value)),
      average: average.toFixed(2),
      occurrences: measurements.length,
    };
  });

  useEffect(() => {
    if (patient) {
      setClinicalNoteData(patient.clinical_notes || []);
    } else {
      fetchClinicalNotes();
    }
  }, [patient]);

  const clinicalNoteColumns = useMemo(
    () => [
      { accessorKey: "description", header: "Description", size: "150" },
      { accessorKey: "status", header: "Status", size: "150" },
      { accessorKey: "registered", header: "Registered", size: "150" },
      { accessorKey: "initiated_date", header: "Initiated Date", size: "150" },
      { accessorKey: "last_update", header: "Last Update", size: "150" },
    ],
    []
  );
  return (
    <div ref={ref}>
      <Container fluid style={{ width: 800 }}>
        <Row>
          <Col xs={12}>
            <Card className="border-0">
              <CardHeader className="d-flex justify-content-between">
                <div>
                  <h1>{title}</h1>
                </div>
                {
                  <FaFilePdf
                    className="d-print-none"
                    style={{ cursor: "pointer", fontSize: "30px" }}
                    onClick={handlePrint}
                  />
                }
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <b>{t("birthday")}: </b> {patient.birthday}
                  </Col>
                  <Col>
                    <b>{t("telephone")}: </b> {patient.telephone}
                  </Col>
                  <Col>
                    <b>{t("fax")}: </b> {patient.fax}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <b>{t("email")}: </b> {patient.email}
                  </Col>
                  <Col>
                    <b>{t("Health Insurance Provider")}: </b>{" "}
                    {patient.health_insurance_provider}
                  </Col>
                  <Col>
                    <b>{t("Health Insurance ID")}: </b>{" "}
                    {patient.health_insurance_id}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <b>{t("Address")}: </b>{" "}
                    {patient.address + ", " + patient.house_number}
                  </Col>
                  <Col>
                    <b>{t("City")}: </b> {patient.city}
                  </Col>
                  <Col>
                    <b>{t("PostCode")}: </b> {patient.post_code}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h5 style={{ marginBottom: "-1px", color: "black" }}>
                  Vitals{" "}
                </h5>
              </CardHeader>
              <CardBody>
                <Grid
                  columns={columns}
                  data={data}
                  style={{
                    td: {
                      "text-align": "center",
                    },
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <LaboratoryFindingsCard isReadOnly={true} />
        </Row>
        <Row>
          <TreatmentPlansCard isReadOnly={true} />
        </Row>
        <Row>
          <ClinicalNoteCard
            isReadOnly={true}
            columns={clinicalNoteColumns}
            data={clinicalNoteData}
          />
        </Row>
        {chartProps.map((props) => (
          <Row key={props.name}>
            <Col>
              <ChartDataChecker {...props} />
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
});

export default PdfReports;
