import React,{useEffect,useRef,useState} from "react";
import { CardHeader,Card, Alert } from "reactstrap";
import ChartApex3 from "./ChartApexThreeSeries";
import ChartApex2 from "./ChartApexTwoSeries";
import ChartApex1 from "./ChartApexOneSeries";
import { useTranslation } from "react-i18next";

const CardBodyWithRef = React.forwardRef(({ children }, ref) => (
  <div style={{ marginTop: "-20px" }} ref={ref}>
    {children}
  </div>
));
  
const ChartDataChecker = ({ patientData, name, apex1, apex3, height }) => {
  const{t}=useTranslation();
  const cardBodyRef = useRef(null);
  const [cardBodyWidth, setCardBodyWidth] = useState(null);

  const handleWindowResize = () => {
    if (cardBodyRef.current) {
      const width = cardBodyRef.current.offsetWidth;
      setCardBodyWidth(width);
    }
  };

  useEffect(() => {
    handleWindowResize(); // Initial width calculation

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
 
  //current solution is not good for density of data
  // setCardBodyWidth(null);
  return (
    <Card>
      <CardHeader >
      <h5 style={{ marginBottom: "-1px", color: "black" }}>
      {name}
        </h5>
      </CardHeader>
      <CardBodyWithRef style={{ marginTop: "-20px" }} ref={cardBodyRef}>
        {patientData ? (
          apex1 ? (
            <ChartApex1
              id="line_chart_datalabel"
              dir="ltr"
              inputData={patientData}
              name={name}
              height={height ?? null}
              width={cardBodyWidth/60 ?? 10}
            />
          ) : apex3 ? (
            <ChartApex3
              id="line_chart_datalabel"
              dir="ltr"
              inputData={patientData}
              name={name}
              height={height ?? null}
              width={cardBodyWidth/60 ?? 10}
            />
          ) : (
            <ChartApex2
              id="line_chart_datalabel"
              dir="ltr"
              inputData={patientData}
              name={name}
              height={height ?? null}
              width={cardBodyWidth/60 ?? 10}
            />
          )
        ) : (
          <Alert
            style={{
              height: height ?? "460px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px"
            }}
            color="info"
            className="text-center">
            <i className="mdi mdi-alert-circle-outline d-block display-4 mt-2 mb-2 text-info"></i>
            <h5 className="text-info h4">{t("attention")}</h5>
            <p className="mb-0 fs-5"> {t("No")} <span style={{margin: '0 5px',fontWeight: 'bold'}}>{name}</span> {t("DataIsProvided")}</p>
          </Alert>
        )}
      </CardBodyWithRef>
    </Card>
  );
};

export default ChartDataChecker;
