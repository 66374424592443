import {
  getDatabase,
  ref,
  onValue,
  get,
  set
} from "firebase/database";
import axios from "axios";

import { v4 as uuidv4 } from 'uuid';
import db from "../helpers/db.json"

const baseUrl = "https://d4dsanajsonserver.onrender.com/patients";

const isFirebase = process.env.REACT_APP_DEFAULTAUTH === "firebase";



//fhir patients
// export const getPatients = () => {
//   console.log("getting")
//   return axios.get(proxyUrl + "/patients")
//     .then(({ entry }) => entry.map(({ resource }) => (
//       {
//         uuid: resource.id,
//         first_name: resource.name[0].given[0],
//         last_name: resource.name[0].family,
//         birthday: resource.birthDate,
//         sex: resource.gender,
//         address: resource.address?.[0].line?.at(-1),
//         city: resource.address?.city,
//         postcode: resource.postalCode,
//         devices: resource.identifier.map(id => id.value)
//       }
//     )))
// }


const getAll = (onUpdate) => {
  if (isFirebase) {
    const database = getDatabase();
    const patientsRef = ref(database, "/patients");
    return new Promise((resolve, reject) => {
      // Attach a listener to get the data
      const onDataCallback = (snapshot) => {
        const data = snapshot.val();
        const patientsArray = Object.values(data); // Convert object to array
        resolve(patientsArray);
        onUpdate?.(patientsArray)
      };
      const errorCallback = (error) => {
        reject(error);
      };
      onValue(patientsRef, onDataCallback, errorCallback);
    });
  } else {
    const request = axios.get(baseUrl);
    return request.then((response) => response.data);
  }
};

const create = (newObject) => {
  if (isFirebase) {
    const db = getDatabase();
    const patientsRef = ref(db, 'patients');
    return get(patientsRef)
      .then((snapshot) => {
        const patients = snapshot.val();
        if (!patients) {
          console.error('Error: Patients data not found');
          return Promise.reject('Patients data not found');
        }

        let newUuid;
        for (let i = 0; i < 100; i++) {
          newUuid = uuidv4()
          if (patients.every(p => p.uuid !== newUuid)) break;
        }

        const updatedPatients = [...patients, { uuid: newUuid, ...newObject }]

        // Update the entire patients array
        return set(patientsRef, updatedPatients)
          .then(() => newObject)
          .catch((error) => {
            console.error('Error updating patient:', error);
            throw error;
          });
      })
      .catch((error) => {
        console.error('Error retrieving patients:', error);
        throw error;
      });
  } else {
    const request = axios.post(baseUrl, newObject);
    return request.then((response) => response.data);
  }
};

const updatePatient = (id, newObject) => {
  if (isFirebase) {
    const uuid = id;
    const database = getDatabase();
    const patientsRef = ref(database, '/patients');

    return get(patientsRef)
      .then((snapshot) => {
        const patients = snapshot.val();
        if (!patients) {
          console.error('Error: Patients data not found');
          return Promise.reject('Patients data not found');
        }
        // Find the index of the patient with the given UUID
        const index = patients.findIndex((patient) => patient.uuid === uuid);
        if (index === -1) {
          console.error('Error: Patient not found');
          return Promise.reject('Patient not found');
        }

        // Update the patient object at the found index
        patients[index] = { ...patients[index], ...newObject };

        // Update the entire patients array
        return set(patientsRef, patients)
          .then(() => uuid)
          .catch((error) => {
            console.error('Error updating patient:', error);
            throw error;
          });
      })
      .catch((error) => {
        console.error('Error retrieving patients:', error);
        throw error;
      });
  } else {
    const request = axios.put(`${baseUrl}/${id}`, newObject);
    return request.then((response) => response.data);
  }
};

const resetPatients = () => {
  const database = getDatabase();
  const patientsRef = ref(database, '/patients');
  set(patientsRef, db.patients)
}

const removePatient = (id) => {
  if (isFirebase) {
    const database = getDatabase();
    const patientsRef = ref(database, '/patients');
    const uuid = id;
    return get(patientsRef)
      .then((snapshot) => {
        const patients = snapshot.val();
        if (!patients) {
          console.error('Error: Patients data not found');
          return Promise.reject('Patients data not found');
        }

        const updatedPatients = patients.filter(p => p.uuid !== uuid)

        // Update the entire patients array
        return set(patientsRef, updatedPatients)
          .then(() => id)
          .catch((error) => {
            console.error('Error removing patient:', error);
            throw error;
          });
      })
      .catch((error) => {
        console.error('Error retrieving patients:', error);
        throw error;
      });
  } else {
    return axios.delete(`${baseUrl}/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error removing patient:', error);
        throw error;
      });
  }
};

export default {
  getAll,
  create,
  update: updatePatient,
  remove: removePatient,
  resetPatients,
};
