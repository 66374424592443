import React from "react";
import { Container, Row } from "reactstrap";
import MedicalForm from "./MedicalForm";
import patientService from "../../services/patients"

const MedicalTeam = () => {
  document.title = "Device Dashboard";
  const patient = JSON.parse(localStorage.getItem("patient"));
  
  const setPatient = (values) => {
    const updatedPatient = { ...patient, ...values }
    patientService
      .update(updatedPatient.uuid, updatedPatient).then(returnedPatient => {
        localStorage.setItem("patient", JSON.stringify(updatedPatient));
        console.log("returned", returnedPatient)
      })
      .catch(error => {
        console.log("Updating patient failed")
      })
  }
 
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}  >
          <Row >
            <MedicalForm title={"Home Doctor"} patient={patient} onSubmit={setPatient} />
          </Row>
          <Row >
            <MedicalForm title={"Cardiology Doctor"} patient={patient} onSubmit={setPatient} />
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};
export default MedicalTeam;
