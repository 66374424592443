import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import { PatientForm } from "../Information/PatientFormCard";
import patientService from "../../services/patients"


const CreatePatientButton = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal)
  }

  const createPatient = (newPatient) => {
    patientService.create(newPatient)
      .then(() => {
        toggle()
      })
      .catch(e => console.log("could not create patient", e))
  }

  return (
    <>
      <Button color="primary" onClick={toggle}
        style={{
          outline: "none",
          border: "none",
        }}
      >
        <i
          className={`mdi mdi-account-plus`}
          style={{ marginRight: "5px" }} // Adjust the spacing as needed
        />
        {t("New Patient")}
      </Button>
      <Modal isOpen={modal} size="lg">
        <ModalHeader>{t('CreatingNewPatient')}</ModalHeader>
        <ModalBody>
          <PatientForm onSubmit={createPatient} onCancel={toggle} hideDeleteButton hideResetButton />
        </ModalBody>
      </Modal>
    </>
  )
}


export default CreatePatientButton