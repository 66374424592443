import React, { useRef } from "react";
import { Button, Container, Row } from "reactstrap";
import { useReactToPrint } from 'react-to-print';

import PdfReports from "./PdfReports";


const Reports = () => {
  document.title = "Report ";
  const refPdf = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => refPdf?.current,
  });

  const patient = JSON.parse(localStorage.getItem("patient"))

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}  >
          <Row>
            <PdfReports patient={patient} ref={refPdf} handlePrint={handlePrint} />
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default Reports;
