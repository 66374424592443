import React, { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { Container, Row, Col } from "reactstrap";
import ChartDataChecker from "../../components/Charts/ChartDataChecker";
import { useTranslation } from "react-i18next";
import dateTimeUtils from "../../components/Charts/DateTimeUtils";

const Weight = () => {
  const { t } = useTranslation();
  document.title = t("weight");
  const patient = JSON.parse(localStorage.getItem("patient"));

  let data = null;
  if (!patient.weight || patient.weight.measurements.length === 0) {
    patient.weight = null;
  } else {
    data = patient.weight.measurements.map(({ date, value }) => ({
      date: dateTimeUtils.formatDateTime(date),
      value,
      source: patient.weight.source ?? "---",
    }));
  }
  const columns = useMemo(
    () => [
      { accessorKey: "date", header: "Date", size: "150" },
      { accessorKey: "value", header: "Value", size: "150" },
      { accessorKey: "source", header: "Source", size: "150" },
    ],
    []
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
              <ChartDataChecker
                patientData={patient.weight}
                name={t("weight")}
                apex1={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
              {data != null ? (
                <MaterialReactTable
                  columns={columns}
                  data={data}
                  enableColumnDragging={false}
                  initialState={{
                    sorting: [
                      {
                        id: "date",
                        desc: true,
                      },
                    ],
                  }}
                />
              ) : (
                <div></div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Weight;
