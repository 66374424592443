import React, { useMemo, useState } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import { useTranslation } from "react-i18next";
import { Box, IconButton } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";

const TreatmentPlansCard = ({
  isReadOnly,
  handleRowSelection,
  renderDetailPanel,
}) => {
  const { t } = useTranslation();
  const patient = JSON.parse(localStorage.getItem("patient"));

  const columns = useMemo(() => {
    // Define the common columns that are always shown
    const commonColumns = [
      { accessorKey: "egm", header: "Medication", size: "100" },
      { accessorKey: "type", header: "Dosis", size: "60" },
      { accessorKey: "reviewer", header: "Angeordnet", size: "100" },
      {
        accessorKey: "devicetype",
        header: "Posologie",
        size: "80",
      },
      { accessorKey: "date", header: "Anfangs-Datum", size: "120" }, //MDC_IDC_EPISODE_DTM
      { accessorKey: "duration", header: "Dauer", size: "100" },
      { accessorKey: "Therapie", header: "Therapie", size: "100" },
    ];

    // Check if the ID column should be included
    const idColumn = !isReadOnly
      ? { accessorKey: "ID", header: "ID", size: "10" }
      : undefined;

    // Concatenate the common columns and the ID column (if not undefined)
    const mergedColumns = idColumn
      ? [idColumn, ...commonColumns]
      : commonColumns;

    return mergedColumns;
  }, [isReadOnly]);

  const [data, setData] = useState([
    {
      egm: "Captopril",
      ID: 1,
      type: "100mg",
      reviewer: "Dr. A. Nowik",
      devicetype: "1-0-0-0",
      date: "2023-05-13",
      duration: "Lebenslang",
      Therapie: "initiiert",
      result: "Successful",
    },
    {
      egm: "ASS",
      ID: 2,
      type: "100mg",
      reviewer: "Dr. E. Siutsi",
      devicetype: "0-1-0-0",
      date: "2023-05-28",
      duration: "Lebenslang",
      Therapie: "vorhanden",
      result: "Successful",
    },
    {
      egm: "Simvastatin",
      ID: 4,
      type: "20mg",
      reviewer: "Dr. F. Scheubel",
      devicetype: "0-0-0-1",
      date: "2023-06-02",
      duration: "Lebenslang",
      Therapie: "intiiert",
      result: "Successful",
    },
    {
      egm: "Beloc Zok Mitte",
      ID: 3,
      type: "47,5mg",
      reviewer: "Dr. E. Ricardo",
      devicetype: "1-0-0-1",
      date: "2023-06-18",
      duration: "Unitl new Rec.",
      Therapie: "vorhanden",
      result: "Successful",
    },
    {
      egm: "Mirtazapin",
      ID: 5,
      type: "15mg",
      reviewer: "Dr. E. Ricardo",
      devicetype: "0-0-0-1",
      date: "2023-06-18",
      duration: "Unitl new Rec.",
      Therapie: "intiiert",
      result: "Successful",
    },
    {
      egm: "Marcumar",
      ID: 6,
      type: "3mg",
      reviewer: "Dr. E. Mattheus",
      devicetype: "1/2-0-0-0",
      date: "2023-06-18",
      duration: "Unitl new Rec.",
      Therapie: "vorhanden",
      result: "Successful",
    },
    // Add more objects as needed
  ]);

  return (
    <Card>
      { isReadOnly?(
      <CardHeader>
        <h5 style={{ marginBottom: "-1px", color: "black" }}>
          Treatment Plans</h5>
      </CardHeader>
      ):(
      <CardTitle>
        <h4>Treatment Plans</h4>
      </CardTitle>
      )}
      <CardBody>
        <Row>
          <MaterialReactTable
            columns={columns}
            data={data}
            onRowSelection={handleRowSelection}
            renderDetailPanel={renderDetailPanel}
            isRowSelectable={() => !isReadOnly} // Enable row selection for all rows
            isRowSelected={
              !isReadOnly ? (rowData) => rowData.ID === selectedRow : undefined
            }
            enableColumnVirtualization={!isReadOnly}
            renderRowActions={
              !isReadOnly
                ? ({ row, table }) => (
                    <Box
                      sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}
                    >
                      <IconButton
                        color="secondary"
                        onClick={() => {
                          table.setEditingRow(row);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => {
                          data.splice(row.index, 1); //assuming simple data table
                          setData([...data]);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )
                : undefined // Set to undefined if isReadOnly is true
            }
            enableColumnDragging={!isReadOnly}
            enableColumnActions={!isReadOnly}
            enableColumnFilters={!isReadOnly}
            enablePagination={!isReadOnly}
            enableSorting={!isReadOnly}
            enableBottomToolbar={!isReadOnly}
            enableTopToolbar={!isReadOnly}
          />
        </Row>
      </CardBody>
    </Card>
  );
};

export default TreatmentPlansCard;
